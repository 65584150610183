

.new-terms {
  padding: 5% 4%;
  font-family: "Assistant";
  font-style: normal;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.01em;
  background-color: white; 
}

.new-terms-heading {
  color: black; 
  font-size: 64px;
  font-weight: 600;
  text-align: center;
  padding: 23px;
}

.new-terms-content {
  margin-top: 3rem;
  font-family: "Assistant";
  font-style: normal;
  color: #333; 
}

.new-terms-content h5 {
  font-family: "Assistant";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.01em;
  font: "Roboto"; 
}

.new-terms-link {
  margin-top: 1.5rem;
}

.new-terms-link a {
  text-decoration: none;
  color: #0078d4; 
}

.new-rating-h5 {
  margin-top: 2rem;
}

.new-rating-h3 {
  font-family: "Assistant";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 42px;
  letter-spacing: 0.01em;
  margin-bottom: 1rem;
}

.new-collapsible {
  background-color: #f5f5f5; 
  color: #333; 
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  outline: none;
  font-size: 15px;
  border: 1px solid #ddd; 

  font-family: "Assistant";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #333; 
}

.new-content {
  padding: 15px 25px;
  display: none;
  overflow: hidden;
  background-color: #f9f9f9; 

  font-family: "Assistant";
  font-style: normal;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #333; 
}

.new-collapse-container {
  border: 1px solid #ddd; 
  border-radius: 4px;
}

@media (max-width: 800px) {
  .terms-heading {
    font-size: 50px;
  }
}

@media (max-width: 480px) {
  .terms-heading {
    padding-top: 2rem;
    font-size: 40px;
    line-height: 40px;
  }
}

.flx{
    display: flex;
    justify-content: center;
    /* column-gap: 30px; */
    color: var(--light-10)
}

.headline{
    color: var(--primary);
    font-weight:bold;
    margin-bottom: 8px;
    padding-top: 6rem;
}

.client{
    width: 1643px;
    margin-left: 122px;
    padding: 2em;
}


.hiden {
    background: var(--dark-20);
    padding: 30px;
    border-radius: 4px;
    height: fit-content;
}

.video-container {
    display: flex;
    gap: 3%;
    padding: 2rem 8rem;
    align-items: center;
}

.video-frame {
    width: 540px;
    height: 385px;
}

@media (max-width: 1200px) {
    .video-frame {
        width: 400px;
    }
}

@media(max-width: 800px){
   

    .video-container {
        flex-direction: column;
    }

    .hiden {
        margin-top: 3rem;
    }

    .sectn{
        padding-left: -100px;
    }
    
}

@media (max-width: 480px) {
    .video-frame {
        width: 80vw;
    }

    .client {
        flex-direction: column;
        align-items: center;
    }
}

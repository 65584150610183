.dealership-product-card-container {
    max-width: 340px;
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 1.5em;
    box-shadow: 7px 6px 9px 0px #2c2c2c25;
    border: 1px solid #959596;
    border-radius: 4px;
  }
.dealership-product-card-img{

    max-height: 300px;
    max-width: 630px;
    border-radius: 4px;
}
.dealership-body-product-card-container{
    display: flex;
    flex-direction: column;
    gap: 2.5em;
    justify-content: center;
}

  .dealership-text {
    color: #000;
    font-family: Assistant;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.24px;
  }
  .dealership-text1 {
    color: #000;
    font-family: Assistant;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.24px;
  }
  .dealership-text2 {

    /* color: #F79C00; */
    font-family: Assistant;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.24px;
  }
  .dealership-text3 {
    color: #000;
    font-family: Assistant;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.24px;
  }
  .materials-product-promoted-text{
    color: #000;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.24px;
  }

  
@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400;500;600;700;800&display=swap');

:root{
  --primary: #F79C00;
  --secondary: #0068F7;
  --tertiary: #FED401;
  --success: #319F00;
  --danger: #D60000;
  --light-10: #FBFBFC;
  --light-20: #F9FAFB;
  --light-30: #F8F9FA;
  --light-40: #C6C7C8;
  --light-50: #959596;
  --dark-10:#7A7C7F;  
  --dark-20:#4D5154;  
  --dark-30:#212F29;  
  --dark-40:#1A1E21;  
  --dark-50:#141619;  
  --equip9-gradient: linear-gradient(90deg, var(--primary), var(--tertiary))
  
}





.add-storelist-layout{
   display: flex;
   flex-direction: row;  
   justify-content: space-between;
}

.add-storelist-seprator{
    height: 3px;
    width: "45%";
    background: blue;

}

.Material-store-headline{
    color: var(--Dark-90, #141619);
    font-family: Assistant;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.Material-store-subheading{
    color: var(--Dark-10, #767676);
/* Body 1/Regular */
font-family: Assistant;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.Material-store-layout{
    margin: 22px 32px;
}

.Add-button{
display: flex;
width: 144px;
height: 46px;
padding: 10px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 4px;
border: 1px solid #F79C00;
margin-right: 16px;
margin-top: -20px;
cursor: pointer;
}
.Add-text{
    color: #F79C00;
font-family: Assistant;
font-size: 28px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 0.28px;
}
.dealership-card{
    min-width: 250px;
    max-width: 550px;
    min-height: 120px;
    box-shadow: 7px 6px 9px 0px #2c2c2c25;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0.75em 0.5em;
    justify-content: space-around;
    border: 1px solid #959596;
    border-radius: 4px;
    position: relative;
  }
  .dealership-tag-container{
 position: absolute;
 display: flex;
 width: 40%;
 justify-content: space-between; 
 align-items: center;  
  }
  .dealership-tag{
    position: absolute;
  top: 10px; /* Adjust as needed */
  left: 10px;
    color: #0B0368;
    font-family: Assistant;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    /* letter-spacing: 0.197px; */
    padding: 1em 1em;
    z-index: 10; 
    border-radius: 10px;
background: #FFF;
  }
  
  .dealership-card__heading{
      font-size: 23px;
       font-weight: 700;
       color: #F79C00;
  
  }
  .dealership-card__description{
      font-size : 18px;
       color: #011F6b;
      
  }
  
  .dealership-cursor--pointer{
    cursor: pointer;
  }
  .dealership-cursor--pointer:hover{
    text-decoration: underline;
  }
  
  .dealership-card__description--not-available{
    color : #D60000;
    font-weight: 600;
  }
  
  .dealership-card__description--not-available:hover{
    text-decoration: none;
  }

  .dealership-description-card-video{
    display: flex;
    justify-content: center;
    max-height: 250px;
    max-width: 250px;
    width: 250px;
  }

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup-content {
  max-width: 624px;
  max-height: 600px;
}

.popup-content video {
  max-width: 600px;
  max-height: 620px;
}
.dealership-text-name{
    color: #000;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.24px;
}
.dealership-description-card-video-element{
  max-width: 250px;
  width: 100%;
}
  
  
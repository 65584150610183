.verified{text-align: right;
font: normal normal 600 12px Assistant;
letter-spacing: -0.54px;
color: #319F00;
opacity: 1;
}
.Not-Verfied-tag {
  justify-content: center;
  align-self: center;
  display: flex;
  heig  
  .Verfied-tag.verified {
    background-color: green
  }
  
}

.Verfied-tag {
  justify-content: center;
  align-self: center;
  display: flex;
  height: 45px;
  width: 100%;
  margin-left: 1px;
  background-color: green;

  .Verifi-tex {
    color: white;
    font-family: "Assistant";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    margin-top: 10px;
  }
  .Verfied-tag.not-verified {
    background-color: #D60000;
  }
  
  .Verfied-tag.verified {
    background-color: green
  }
  
}

.form-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 2%;
  overflow-y: scroll;

 
    box-shadow: 0px 10px 30px #2C2C2C14;
    border: 1px solid #2C2C2C1A;
    opacity: 1;
    margin: 3%;
}
  .form-container .profileForm:last-child {
    display: flex;
    justify-content: space-between;
    flex-basis: calc(100% - 10px);
  }

.form-column {
  flex: 1;
  margin-right: 20px; 
}

.profileForm{
  flex-basis: calc(50% - 10px);
}

.profileField{
  width: 100%;
  width: 100%;
  padding: 7px;
  margin-bottom: 12px;
  border: 1px solid #2c2c2c14;
  border-radius: 4px 4px 0px 0px;
  background: #2C2C2C05 0% 0% no-repeat padding-box;
}
.profileDiv:hover,.profileDiv:focus , :visited{
  
  transform: scale(1.02);

  .profileLabel{
    /* border: 1px solid #f79c00; */
    color : #f79c00  ;
    font: normal normal 600 18px/23px Assistant; 
  letter-spacing: -0.6px;

  
  }

  .profileField{
    border: 1px solid #f79c00;
  }

}

.profileLabel{
  /* font-size: 18px; */
  text-align: left;
  font: normal normal 600 18px/23px Assistant; 
  letter-spacing: -0.6px;
  color: #2C2C2C;
  opacity: 1;
}
.asterisk{
  color: #D60000;
  font-size: 18px;
}
.profileFormCheck{
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
}
.profileFormCheckBox{
  margin-top: 13px;
  width: 10%;
}
.profileButtonClass{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 1%;
}
.profileButton{

  background-image: linear-gradient(to right, #f67c00, #FED401);
    color: #fff;
    padding-inline-start: 1rem;
    padding-inline-end: 1rem;
    display: inline-flex;
    appearance: none;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    vertical-align: middle;
    outline: 2px solid transparent;
    outline-offset: 2px;
    line-height: 1.2;
    border-radius: 0.375rem;
    font-weight: 600;
    transition: box-shadow 0.2s;
    height: 2.5rem;
    width: 30%;
    min-width: 2.5rem;
    font-size: 1rem;
    margin-top:16px
}





.popup-card-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 101;
    overflow-y: auto;
    padding: 20px;
}

.popup-card-workpopup {
    background-color: white;
    /* padding-top: 23px; */
    border-radius: 15px;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
    /* max-width: 700px; */
    width: 732px;
    overflow-y: auto;
    height: 611px;
    overflow: scroll;
    padding: 23px 23px;
   
}

.popup-card-close {
    text-align: right;
    margin-bottom: 20px;
}

.popup-card-workpopup h1 {
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: bold;
    color: #1E3264;
}

.popup-card-workpopup .form-group, .OptionField div {
    margin-bottom: 25px;
}

.popup-card-workpopup label {
    display: block;
    font-weight: 600;
    margin-bottom: 8px;
    color: #2C2C2C;
    text-transform: capitalize;
}

.popup-card-workpopup .required {
    color: red;
    margin-left: 5px;
}

.file-input-container {
    position: relative;
    width: 100%;
    height: auto;
    text-align: center;
    margin-top: 20px;
}

.custom-file-button {
    padding: 10px 20px;
    cursor: pointer;
    display: inline-block;
    background: #f2f2f2;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-weight: 600;
    color: #1E3264;
}

.custom-file-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.popup-card-workpopup input[type="text"],
.popup-card-workpopup select {
    width: 100%;
    padding: 10px 15px;
    background: #f2f2f2;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 14px;
}

.popup-card-workpopup button[type="submit"] {
    width: 100%;
    padding: 10px 0;
    background: linear-gradient(274deg, #F79C00 0%, #FED401 100%);
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: 0.3s ease;
}

.popup-card-workpopup button[type="submit"]:hover {
    background: linear-gradient(274deg, #FED401 0%, #F79C00 100%);
}

.popup-card-workpopup button[type="submit"]:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.error-message {
    color: red;
    font-size: 12px;
}

.OptionField {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.OptionField div {
    flex: 1;
    padding: 0 10px;
}

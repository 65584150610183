.verification-badge{
    border-radius: 1em;
    background-color: rgba(0,0,0,0.5);
    text-align: center;
    padding: 0.15em 0.5em;
    min-width: 100px;
    max-height: 28px;
    min-height: 28px;
}

.verification-badge__success{
    background-color: #319F0060;
    width: auto;
}

.verification-badge__failture{
    background-color: #D6000060;
}

.verification-badge__text{
    color: #fff;
     font-size: 14px;
}
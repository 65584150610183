.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color:rgba(0, 0, 0, 0.5);
    z-index: 1000;   /* Ensure it's above everything else */
    display: flex;   /* Centering the content */
    justify-content: center;
    align-items: center;
    margin: 0;       /* Reset margin */
    padding: 0;
    
}

.modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
}

.confromation-button{
   display: flex;
   flex-direction: row;
   justify-content: space-around;
   margin: 22px 2px;  
}
.yesButton{
background-color: yellow;
width: 86px;margin: 0 10px;
padding: 10px 20px;
border: none;
cursor: pointer;
font-size: 16px;
background:rgb(198, 199, 200);
color: #fff;
border-radius: 5px;
outline: none;
}

.yesButton:hover{
    width: 86px;margin: 0 10px;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    background:linear-gradient(90deg, var(--primary), var(--tertiary));
    color: #fff;
    border-radius: 5px;
    outline: none;
    }

.dilogue-text{
    text-align: left;
    font: normal normal 18px/20px  Assistant;
    letter-spacing: -0.96px;
    color: #2C2C2C;
    opacity: 1
    
}


.spare-list-layout {
  margin: 22px 33px;
}
.spare-list-heading {
  color: var(--Dark-90, #141619);
  font-family: Assistant;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.24px;
}

.subheading-layout {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.subheading-text {
  color: var(--Dark-10, #767676);
  font-family: Assistant;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.16px;
  margin-top: 12px;
}
.add-button-marketplace {
  display: flex;
  width: 144px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid #f79c00;
  margin-top: -20px;
}

.add-store-text {
  color: #f79c00;
  font-family: Assistant;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.28px;
}

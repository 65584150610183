.Material-pop-header-layout {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Material-pop-header-heading {
  color: var(--Dark-70, #1a1e21);
  font-family: Assistant;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.24px;
  margin-top: 12px;
}

.Material-pop-header-subheading {
  color: var(--Dark-50, #2c2c2c);
  font-family: Assistant;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.18px;
  margin-top: 12px;
}

.material-pop-up-close {
  margin-left: 94%;
  margin-top: -15px;
}

.options-layout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
  margin-top: 30px;
  row-gap: 18px;
}
.store-options-list {
  width: 338px;
  height: 60px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  padding-left: 12px;
  gap: 18px;
  margin-top: 25px;
  margin-bottom: 49px;
  border-radius: 4px;
  border: 1px solid var(--Light-50, #e3e5e8);
  background: var(--Light-10, #fefefe);
  cursor: pointer;
}
.store-options-list:hover{
    width: 338px;
  height: 60px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  padding-left: 12px;
  gap: 18px;
  margin-top: 25px;
  margin-bottom: 49px;
  border-radius: 4px;
border: 1px solid var(--Primary-50, #F79C00);
background: var(--Light-Orange, #FEF8EC);
cursor: pointer;

}


.list-icon-image {
  display: flex;
  width: 56px;
  height: 58px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid var(--Light-50, #e3e5e8);
  background: var(--Light-10, #fefefe);
  margin-top: -24px;
  cursor: pointer;
}

.list-icon-image:hover{
  display: flex;
  width: 56px;
  height: 58px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  margin-top: -24px;
  border-radius: 4px;
border: 1px solid var(--Primary-50, #F79C00);
background: var(--Light-Orange, #FEF8EC);
cursor: pointer;
}

.list-icon-image.selected {
    display: flex;
    width: 56px;
    height: 58px;
    padding: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 4px;
    border: 1px solid var(--Primary-50, #F79C00);
    background: var(--Light-Orange, #FEF8EC);
    margin-top: -24px;
    cursor: pointer;
  }


.spare-parts-heading {
  color: #333;
  font-family: Assistant;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.18px;
  margin-top: 18px;
}

.scrollable-container {
  max-height: 300px; /* Adjust the height as needed */
  overflow-y: auto; /* Enables vertical scrolling */
  row-gap: 24px;
}

.pop-up-footer {
  display: flex;
  width: 448px;
  height: 75px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 0px 0px 12px 12px;
  background: var(--Light-10, #fefefe);
  box-shadow: 0px -10px 15px 0px rgba(44, 44, 44, 0.16);
}

.Next-button {
  display: flex;
  width: 128px;
  height: 44.841px;
  padding: 16px 36px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 6px;
  background: rgb(198, 199, 200)
}

.Next-button:hover {
    display: flex;
    width: 128px;
    height: 44.841px;
    padding: 16px 36px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 6px;
    background: var(
      --E9-Gradient,
      linear-gradient(296deg, #f79c00 23.18%, #fed401 94.52%)
    );
  }

.next-button-text {
  color: var(--Light-10, #fefefe);
  font-family: Assistant;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.18px;
}

.store-options-list.selected {
    border: 1px solid var(--Primary-50, #F79C00);
    background: var(--Light-Orange, #FEF8EC);
  }

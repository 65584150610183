.dealership-connnect-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1em;
    border-radius: 12.265px;
    background: #FFF;
}

.connnect-header {
    max-height: 50px;
    max-width: 50px;
}

.connect-body-header {
    color: #0B0368;

    font-family: Assistant;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.25px;
}

.connect-body-text {
    color: #888;

    font-family: Assistant;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.2px;
}

.connect-body {
    text-align: center;
}

.conncet-footer {
    display: flex;
    justify-content: space-between;
}

.conncet-footer-btn {}

.dealership-blur-pointer{
    filter: blur(10px);
}

.dealership-contact-description-text {
    font-size: 23px;
    font-weight: 500;
    width: 100%;
}

.dealership-contact-header {
    font-size: 23px;
    font-weight: 700;
    color: #F79C00;
    width: 15%;
}

.dealership-contact-container {
    display: flex;
    gap: 0.5em;
}

.dealership-not-available {
    color: #888;
    font-size: 26px;
    font-weight: 400;
    letter-spacing: 0.26px;
}
.dealership-main-container{
    /* max-height: 230px;
    height: 200px; */
    margin: 1em;
}
.dealership-divider{
    height: 70%;
    background-color: #011f6b;
    width: 2px;
    margin-top: 50px;
}


.FooterHeader{
    width: 100%;
    height: 100%;
    background: var(--dark-40, #2c2c2c);
}

.EquipmentHeadingdivision {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 23px;
    color: aliceblue;
  }

  .FooterHeading{
    font-size: 1.2rem;
    color: #FECA00;
    font-weight: 600;
    line-height: 1.2;
    display: flex;
    justify-content: center;
    align-items: center; 
    margin: 23px 73px; 
  }

  .digitalPlateForm{
text-align: center;
word-spacing: normal;
text-transform: capitalize;
  }

  .maps{
    width: 80%;
    margin-bottom: 23px;
  }


  .Addresshero{
    text-align: center;
    font-size: 0.9rem;
    color: #ffffff;
    font-weight: 500;
    padding-top: 5px;
    border-radius: 6.727px;
  }

  .pages-suppport{
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    align-items: center; 
    gap: 6%;
    margin-bottom: 3%;
  }

  .footer-link{
    font-size: 0.8rem;

  }

  .Home{
width: 37%;
  }

  .socialMediaIcons{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 14px;
  }

  .seprator{
    align-items: center;
    color: var(--light-10);
    display: flex;
    justify-content: center;
    width: 100%;
  }


  .copyRight{
    text-align: center;
    font-size: 0.8rem;
    margin: 5px 43px;
    margin-bottom: 12px;
  }

  .footerText{
    color: #FED401;
    font-size: 0.8rem;
    
  }




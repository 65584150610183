.dealership-header-card-container {
    width: 75%;
    display: flex;
    gap: 1em;
    padding: 1.5em;
     box-shadow: 3px 7px 23px 1px #2c2c2c25;
    /* border: 1px solid #959596;
    /* border: 1px solid #959596; */
    border-radius: 4px;
    border-radius: 0px 10px 10px 0px;
background: #FFF;
margin-left: 120px;
  }
.dealership-header-card-img{

    max-height: 300px;
    max-width: 630px;
    border-radius: 4px;
    flex: 0 0 60%;
}
.dealership-body-card-container{
    display: flex;
    flex-direction: column;
    gap: 2.5em;
    justify-content: center;
    flex: 0 0 30%;
}

  .dealership-text {
    color: #000; 
    font-family: Assistant;
    font-size: 16px;
    /* font-style: normal; */
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.24px;
  }
  .dealership-text1 {
    color: #000;
    font-family: Assistant;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.24px;
  }
  .dealership-text2-new {

    /* color: #F79C00; */
    font-family: Assistant;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.24px;
  }
  .dealership-text3-new {
    color: #000;
    font-family: Assistant;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.24px;
  }

  
.non-verify-layout-new {
  display: flex;
  flex-direction: row;
  /* background-color: red; */
  padding: 2px 22px;
  height: 33px;
  border-radius: 4px;
  border: 2px solid #d60000;
  background: #fff;
  gap: 5px;
  color: #d60000;
}

.verify-layout-new{
    display: flex;
    flex-direction: row;
    /* background-color: red; */
    padding: 2px 22px;
    height: 33px;
    border-radius: 4px;
    border-radius: 4px;
    border: 2px solid #319F00;
    background: #FFF;
    color: #319F00;
}

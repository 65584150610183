.my-leads-tab-card-container{
    max-width: 540px;
    width: 100%;
    max-height: 290px;
    min-height: 230px;
    padding: 0em 1em;
    box-shadow: rgb(149, 149, 150) 0px 2px 4px 1px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0em 2em;
    gap: 1em;
}
.my-leads-tab-card-container-received{
    max-width: 540px;
    width: 100%;
    max-height: 180px;
    min-height: 170px;
    padding: 0em 1em;
    box-shadow: rgb(149, 149, 150) 0px 2px 4px 1px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* margin: 0em 2em;  */
}
.my-leads-tab-card-status{
    display: flex;
    justify-content: space-between;
}
.my-leads-tab-card-body{
    display: flex;
    align-items: center;
    gap: 1em;
}
.my-leads-tab-card-img-container{
    max-height: 123px;
    max-width: 125px;
    border: 2px solid #F19020;
    width: 100%;
    height: 123px;
    border-radius: 6px;
}

.my-leads-tab-card__img-container__img{
 width: 100%;
 height: 120px;
}
.my-leads-tab-card-tab-details{
    width: 100%;
    display: flex;
    /* align-items: center; */
    flex-direction: column;
    gap: 5px;
}
.my-leads-tab-card-contact{
    display: flex;
    justify-content: space-between;
}
.my-leads-header{
    font-size: 18px;
    font-weight: 700;
    color: "#000000";
    line-height: 23.54px;
    letter-spacing: 0.01em;
}
.my-leads-location{
    color: #767676;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.21px;
}
.my-leads-price{
    color: #2c2c2c;
    text-align: left;
    font: normal normal bold 16px/20px Assistant;
    letter-spacing: -0.48px;
    color: #002961;
    text-transform: capitalize;
}
.my-leads-contact{
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #002961;
}
.my-leads-action-btn{
    border-radius: 6px;
    border: 0.817px solid #F19020;
    display: flex;
    max-width: 175px;
    height: 35px;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.my-leads-button{
    color: #F19020;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.2px;
    display: flex;
    align-items: center;
    gap: 5px;
}
.my-leads-like-button{
    border-radius: 6px;
    border: 1px solid #D6000080;
    display: flex;
    max-width: 175px;
    height: 35px;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 5px;
    color: #2C2C2C;
    font: normal normal 600 14px/18px Assistant;
}
.my-leads-request-button{
    border-radius: 6px;
    border: 0.817px solid #1E326480;
    display: flex;
    max-width: 175px;
    height: 35px;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 5px;
    color: #1E3264;
    font: normal normal 600 14px/18px Assistant;

}
.my-leads-status-badge{
    border-radius: 1em;
    background: rgba(214, 0, 0, 0.376);
    text-align: center;
    min-width: 100px;
    max-width: 135px;
    max-height: 28px;
    min-height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.my-leads-request-reject-button{
    border-radius: 4px;
    border: 1.5px solid #d60000;
    display: flex;
    max-width: 175px;
    height: 35px;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 5px;
    color: #d60000;
    font: normal normal 600 14px/18px Assistant;

}

.my-leads-request-accept-button{
    border-radius: 4px;
    border: 1.5px solid green;
    display: flex;
    max-width: 175px;
    height: 35px;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 5px;
    color: #319f00;
    font: normal normal 600 14px/18px Assistant;
}
@keyframes tonext {
    75% {
      left: 0;
    }
    95% {
      left: 100%;
    }
    98% {
      left: 100%;
    }
    99% {
      left: 0;
    }
  }
  
  @keyframes tostart {
    75% {
      left: 0;
    }
    95% {
      left: -300%;
    }
    98% {
      left: -300%;
    }
    99% {
      left: 0;
    }
  }
  
  @keyframes snap {
    96% {
      scroll-snap-align: center;
    }
    97% {
      scroll-snap-align: none;
    }
    99% {
      scroll-snap-align: none;
    }
    100% {
      scroll-snap-align: center;
    }
  }
  
 
  *::-webkit-scrollbar {
    width: 0;
  }
  
  *::-webkit-scrollbar-track {
    background: transparent;
  }
  
  *::-webkit-scrollbar-thumb {
    background: transparent;
    border: none;
  }
  
  * {
    -ms-overflow-style: none;
  }
  
  ol, li{
    list-style: none;
    
  }
  
  .carousel-1 {
    /* background-color: red; */
    position: relative;
    /* padding-top: 75%; */
    filter: drop-shadow(0 0 10px #0003);
    perspective: 100px;
    height: 550px;
  }
  
  .carousel__viewport-1 {
    /* background-color: blue; */
    height: 570px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    counter-reset: item;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
  }
  
  .carousel__slide-1 {
    position: relative;
    flex: 0 0 100%;
    width: 100%;
    /* height: 550px; */
    background-color: var(--dark-40);
    counter-increment: item;
  }
  
  .carousel__slide-1:nth-child(even) {
    background-color: var(--dark-40);
  }
  
  .carousel__slide-1:before {
    content: counter(item);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%,-40%,70px);
    color: #fff;
    font-size: 2em;
  }
  
  .carousel__snapper-1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    scroll-snap-align: center;
  }
  
  @media (hover: hover) {
    .carousel__snapper-1 {
      animation-name: tonext, snap;
      animation-timing-function: ease;
      animation-duration: 4s;
      animation-iteration-count: infinite;
    }
  
    .carousel__slide-1:last-child .carousel__snapper-1 {
      animation-name: tostart, snap;
    }
  }
  
  @media (prefers-reduced-motion: reduce) {
    .carousel__snapper-1 {
      animation-name: none;
    }
  }
  
  .carousel-1:hover .carousel__snapper-1,
  .carousel-1:focus-within .carousel__snapper-1 {
    animation-name: none;
  }
  
  .carousel__navigation-1 {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: center;
  }
  
  .carousel__navigation-list-1,
  .carousel__navigation-item-1 {
    display: inline-block;
  }
  
  .carousel__navigation-button-1 {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    background-color: #333;
    background-clip: content-box;
    border: 0.25rem solid transparent;
    border-radius: 50%;
    font-size: 0;
    transition: transform 0.1s;
  }
  
  /* .carousel::before,
  .carousel::after,
  .carousel__prev,
  .carousel__next {
    position: absolute;
    top: 0;
    margin-top: 37.5%;
    width: 4rem;
    height: 4rem;
    transform: translateY(-50%);
    border-radius: 50%;
    font-size: 0;
    outline: 0;
  } */
  
  .carousel-1::before,
  .carousel__prev-1 {
    left: -1rem;
  }
  
  .carousel-1::after,
  .carousel__next-1 {
    right: -1rem;
  }
  
  .carousel-1::before,
  .carousel-1::after {
    content: '';
    z-index: 1;
    background-color: #333;
    background-size: 1.5rem 1.5rem;
    background-repeat: no-repeat;
    background-position: center center;
    color: #fff;
    font-size: 2.5rem;
    line-height: 4rem;
    text-align: center;
    pointer-events: none;
  }
  
  .carousel-1::before {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolygon points='0,50 80,100 80,0' fill='%23fff'/%3E%3C/svg%3E");
  }
  
  .carousel-1::after {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolygon points='100,50 20,100 20,0' fill='%23fff'/%3E%3C/svg%3E");
  }



  
  /* .first-carousel-img {
    object-fit: cover;
  }
  
  img {
    object-fit: contain;
  } */
  
/* #carousel__slide1 {
  background: url("../../../assets/carousel1_images/Container.png") no-repeat;
  background-size: cover;
}

#carousel__slide2 {
  background: url("../../../assets/carousel1_images/Container (2).png") no-repeat;
  background-size: cover;
}

#carousel__slide3 {
  background: url("../../../assets/carousel1_images/Container (3).png") no-repeat;
  background-size: cover;
} */

.img-container {
  display: grid;
  grid-template-areas: 'img1 img2'
                        'img3 img3';
  grid-gap: 10px;
  padding: 10px;
}

.second-carousel-img1 {
  z-index: 5;
  grid-area: img1;
  width:100%;
  height: 35vh;
  object-fit:cover;
}

.second-carousel-img2 {
    z-index: 5;
    grid-area: img2;
    width:100%;
    height: 35vh;
    object-fit:cover;
}

.second-carousel-img3 {
    z-index: 5;
    grid-area: img3;
    width:100%;
    height: 30vh;
    object-fit:cover;
}

.second-carousel-img4 {
    z-index: 5;
    grid-area: img1;
    width:100%;
    height: 35vh;
    object-fit:cover;
}

.second-carousel-img5 {
    z-index: 5;
    grid-area: img2;
    width:100%;
    height: 35vh;
    object-fit:cover;
}

.second-carousel-img6 {
    z-index: 5;
    grid-area: img3;
    width:100%;
    height: 30vh;
    object-fit:cover;
}

.sectn{
  background: var(--dark-40);
  padding: 2% 0%;
}

.who-we-are-div {
  color: var(--primary);
  font-weight: bold;
  /* font-size: 2.8em; */
  margin-bottom: 8px;
  padding-top: 3rem;
  text-align: center;
}
.materials-card {
  max-width: 550px;
  box-shadow: 0px 0px 4px 0px #00000040;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 25px 15px;
  justify-content: center;
  border-radius: 4px;
  gap: 0.25em;
}

.materials-card__heading {
  font-size: 23px;
  font-weight: 700;
  color: #F79C00;

}

.materials-card__description {
  font-size: 18px;
  color: #011F6b;

}

.materials-cursor--pointer {
  cursor: pointer;
}

.materials-cursor--pointer:hover {
  /* text-decoration: underline; */
}

.materials-card__description--not-available {
  color: #D60000;
  font-weight: 600;
}

.materials-card__description--not-available:hover {
  text-decoration: none;
}

.materials-description-card__description {
  color: #333;
  font-size: 18px;
  font-weight: 600;
  
  letter-spacing: 0.18px;
}
.materials-description-card-video{
  min-width: 250px;
  max-width: 400px;
  max-height: 250px;
  width: 300px;
}

.material-main-container{
  width: 100%;
}




.membr{
   
    font-family: 'Assistant';
    font-style: normal;
    font-weight: 700;
    font-size: 64px;
    line-height: 84px;
    
    
    text-align: center;
    letter-spacing: 0.01em;
    color: #F79C00;
    padding-top: 2rem;
}

.tem{
    border-radius: 50%;
}

.mem{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 3rem;
    padding: 3rem;
} 

.tagline{
    
    
    font-family: 'Assistant';
    font-style: normal;
    font-weight: 600;
    /* font-size: 16px; */
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #FEFEFE;
}
.v5-carousel-main-container {
    /* position: relative; */
    /* overflow: hidden; */
    height: 100vh;
    width: 100vw;
    /* max-width: 500px;
    max-height: 500px;  */
  }
  
  .v5-carousel-container {
    position: relative; 
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
  
  .v5-carousel-image-container {
    position: absolute; 
    top: 0; 
    left: 0; 
    width: 100%; 
    height: 100%;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }
  
  .v5-carousel-image-container.active {
    opacity: 1; 
  }
  
  .v5-carousel-image {
    width: 100%; 
    height: 100%; 
  }
  
.new-description-business-card {
  min-width: 190px;
  width: 190px;
  min-height: 248.733px;
  box-shadow: 7px 6px 9px 0px #2c2c2c25;
  display: flex;
  flex-direction: column;
  padding: 0.75em 0.5em;
  justify-content: center;
  border: 1px solid #959596;
  border-radius: 4px;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--Light-50, #E3E5E8);
  background: var(--Light-10, #FEFEFE);
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.06),
    0px 4px 6px -1px rgba(0, 0, 0, 0.1);
  gap: 1.5em;
}

.new-description-business-card:hover {
  border-radius: 8px;
  border: 1px solid var(--Light-50, #F79C00);
  background: var(--Light-10, #FEFEFE);
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.06),
    0px 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.new-description-card__heading {
  font-size: 23px;
  font-weight: 700;
  color: #F79C00;

}

.new-description-card__description {
  font-size: 18px;
  color: #011F6b;

}

.new-description-business-card-img {
  max-height: 71.105px;
  max-width: 71.105px;
  width: 100%;
  height: 100%;
}

.new-cursor--pointer {
  cursor: pointer;
}

.new-cursor--pointer:hover {
  text-decoration: underline;
}

.new-description-card__description--not-available {
  color: #D60000;
  font-weight: 600;
}

.new-description-card__description--not-available:hover {
  text-decoration: none;
}

.my-Business-card-view-description {
  color: #333;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.24px;
  text-align: center;
}

.my-business-text-description {
  color: #333;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.24px;
}

.my-business-store-headline {
  color: #333;
  font-size: 28px;
  font-weight: 700;
}

.my-business-store-subheading {
  color: #333;
  font-size: 24px;
  font-weight: 400;
}

/* Store  */
.my-business-custom-store-container {
  display: flex;
  width: 100%;
  gap: 55px;
  flex-wrap: wrap;
}

.custom-store-element {
  display: flex;
  max-height: 120px;
  width: 100%;
  height: 100%;
  min-height: 100px;
  align-items: center;
  border: 0.2px solid #888;
  background: #FFF;
  gap: 2em;
  max-width: 490px;
  border-radius: 8px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.custom-store-logo {
  display: flex;
  max-width: 100px;
  max-height: 120px;
  min-height: 120px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  border: 2px solid #F79C00;
}

.custom-store-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  max-width: 270px;
  min-width: 170px;
  width: 100%;
  gap: 5px;
  height: 100%
}

.custom-store-heading {
  color: #333;
  font-size: 28px;
  font-weight: 700;
  letter-spacing: 0.28px;
}

.custom-store-sub-heading {
  color: #333;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.24px;
}

.custom-store-button {
  display: flex;
  /* align-self: flex-start;
  align-items: flex-end; */
  justify-content: center;
  width: 100%;
}
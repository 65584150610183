
.side-menu-profile-container{
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-evenly;
}
.side-menu-profile-left{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 40%;
    gap: 5px;
    width: 100%;
    height: 100%;

}
.side-menu-profile-left-img{
    height: 60px;
    width: 60px;
    border-radius: 50%;
}
.side-menu-profile-left-rating{
    width: 52px;
    height: 21px;
    background: var(--unnamed-color-1e3264) 0% 0% no-repeat padding-box;
    background: #1E3264 0% 0% no-repeat padding-box;
    border-radius: 2px;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
}

.side-menu-profile-right{

    flex: 1 1 60%;
    display: flex;
    flex-direction: column;
    gap: 5px;
        

}

.side-menu-profile-name{
    font: normal normal bold 18px Assistant;
    letter-spacing: 0px;
    color: #2C2C2C;
    opacity: 1;
}

.side-menu-profile-contact{
    text-align: left;
    font: normal normal  16px Assistant;
    color: #2C2C2C;
    text-transform: capitalize;
    opacity: 1;
}
.side-menu-profile-type{
    text-align: left;
    font: normal normal 600 16px Assistant;
    color: #F79C00;
    text-transform: uppercase;
    opacity: 1;
}
.side-menu-profile-role{
    font: normal normal bold 11px Assistant;
    color: #484848;
    text-transform: uppercase;
    opacity: 1;
}

.user-rating{
    width: 52px;
    height: 21px;
    background: var(--unnamed-color-1e3264) 0% 0% no-repeat padding-box;
    background: #1E3264 0% 0% no-repeat padding-box;
    border-radius: 2px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 12px;
    gap: 4px;
    margin-top: 5px;
}

.user-rating-text{
    top: 174px;
    left: 50px;
    color: var(--unnamed-color-ffffff);
    font: normal normal 600 10px/34px Assistant;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-transform: uppercase;
    opacity: 1;
}

.user-rating-icon{
    /* margin-top: -1px; */
}


.assets-header-card-container{
    width: 100%;
    height: 200px;
    display: flex;
    overflow: hidden;
    border-radius: 10px ;
    background: #FFF;
    align-items: center;
    margin-bottom: 1em;
    box-shadow: 0px 0px 4px 0px #00000040;

}

.assets-header-card-img{
    max-height: 300px;
    max-width: 630px;
    border-radius: 4px;
}

.assets-body-card-container{
    display: flex;
    padding: 30px 60px;
    width: 100%;
    height: 100%;
}

.assets-body-card-container-left{
    display: flex;
    flex-direction: column;
    gap:0.5em;
    width: 100%;
  }

  .assets-body-card-container-right{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1em;
  }
  .assets-card-text{
    color: #002961;
    font-weight: 700;
    font-size: 28px;
  }

  .assets-number-field{      
    padding:10px;
    font-weight: 600;
    border-radius:4px;
    border:1.07px solid #002961;
    background-color:white;
  }

  .assets-btn-my-store{
    padding:10.69px;
    border-radius:4px;
    border:1.07px solid #002961;
    background-color:white;
  }
  /* .assets-btn-contact-seller{
    font-weight: 600;
    padding:10.69px;
    border-radius:4px;
    border:1.07px solid #F79C00;
    color:#F79C00 !important;
    background-color:white;
  } */
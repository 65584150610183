.Verfied-tag {
    justify-content: center;
    background-color: #D60000;
    align-self: center;
    display: flex;
    height: 45px;
    width: 100%;
    margin-left: 1px;
  
    .Verifi-text {
      color: #ffffff;
      font-family: "Assistant";
      font-style: normal;
      font-weight: 500;
      font-display: swap;
      margin-top: 10px;
    }
  }

  

  .business-expertise-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 23px;
    cursor: pointer;
    .business-expertise-card {
      display: flex;
      flex-direction: column;
      position: relative;
      width: 396px;/* 50% width with some margin in between */
      padding: 16px;
      border-radius: 8px;
      margin: 0 0 23px 0; /* Margin at the bottom of each card */
      background: var(--light-10, #FEFEFE);
      box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10);
      .userRole-type-name {
        color: var(--dark-90, #141619);
        /* H2/Semibold */
        font-family: Assistant;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.24px;
        /* background-color: #D60000; */
        width: 321px;
        /* height: 62px; */
      }
      .userRole-type-description {
        color: var(--light-90, #959596);
        /* H4/Semibold */
        font-family: Assistant;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.16px;
      }
        
    }
  }

  .business-expertise-card:hover {
    transform: translateY(-5px); /* Move the card up slightly on hover */
    border: 1px solid var(--E9-Gradient, #F79C00);
    box-shadow: 0px 8px 12px -4px rgba(0, 0, 0, 0.1), 0px 16px 24px -6px rgba(0, 0, 0, 0.12); /* Change box-shadow on hover */
  }

  .business-expertise-card.red{
 /* background-color: #F79C00; */
  }

  .business-expertise-card-section{

    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .icon-selected{
   
    display: flex;
    align-items: center;
    height: 34px;
    width: 34px;
    
   
  }
  

  /* BusinessDetail.css */
.custom-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 1000;
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Center the modal in the viewport */
}

.modal-content {
  background-color: #fff; /* White background for the modal */
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); /* Drop shadow effect */
  max-width: 400px;
  text-align: center;
}

.modal-button {
  margin: 10px;
  padding: 10px 20px;
  color: #fff; /* White text color */
  border: none;
  cursor: pointer;
  /* transition: background-color 0.3s; */
  border-radius: 6px;
  background: #C6C7C8;
}

.modal-button:hover {
  background: var(--E9-Gradient, linear-gradient(296deg, #F79C00 23.18%, #FED401 94.52%));
  border: 2px solid #E3E5E8;
  /* transform: translateY(-1px); */
}

/* You can add more CSS styles to further customize the appearance of your modal */

.userRole-type-descrption{
  color: var(--dark-90, #141619);
    font-family: Assistant;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    /* background-color: #D60000; */
    /* width: 308px;
    height: 23px; */
}

  

  

.popup-card-over-Ticket {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 101;
}

.popup-cardd-Ticket {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  gap: 10px;
  max-width: 400px;
  width: 100%;
  /* height: 600px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.onClose {
  margin-left: 95%;
  margin-top: 0%;
}

.headoPhone{
display: flex;
justify-content: center;
align-items: center;
}


.hero-heading {
text-align: center;
color: var(--secondary-90, #002961);
/* H3/Bold */
font-family: Assistant;
font-size: 18px;
font-style: normal;
font-weight: 700;
}

.label-heading{
    color: var(--dark-70, #1A1E21);
    font-family: Assistant;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
}

.select-option{
    width: 95%;
    height: 33px;
    background-color: #F5F5F5;
    border: 1px solid #C6C7C8;
    border-radius: 5px;
}

.add-subject{
    width: 95%;
    height: 33px;
    background-color: #F5F5F5;
    border: 1px solid #C6C7C8;
    padding: 3px;
    border-radius: 5px;
}

.add-description{
    width: 95%;
    height: 150px;
    background-color: #F5F5F5;
    border: 1px solid #C6C7C8;
    padding: 3px;
    border-radius: 5px;
    margin-top: -6px;
}

.submit-complaintent{
    border-radius: 6px;
    background: var(--E9-Gradient, linear-gradient(296deg, #F79C00 23.18%, #FED401 94.52%));
     height: 44px;

}

.submit-text{
color: var(--light-10, #FEFEFE);
font-family: Assistant;
font-size: 18px;
font-style: normal;
font-weight: 600;
}

/* .information-icon{
color: #0F52BA;
height: 3px;
width :3px;
margin-top: 5px;

} */
.order-id-informtion{
    color: #000000;
    font-family: Assistant;
    font-size: 9px;
    font-style: normal;
    font-weight: 600;
    margin-left: 5px;
    margin-top: 5px;
   
}

.information-class{
    margin-top: -12px;
   
}

.information-icon{
    padding: 6px;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    margin-bottom: -40px;
    color: #F5F5F5;
}

.information-background{
    height: 8px;
    width: 22px;
    background-color: #0F52BA;
    border-radius: 90%;
}

.badge-layout-cancelled{
    height: auto;
    /* width: 40%; */
    border-radius: 34px;
    border: 1px solid #D60000;
    background: rgba(214, 0, 0, 0.13);
}

.userResposeTypeValue-cancelled{
    font-family: Assistant;
    font-weight: 400;
    padding: 2px 5px;
    font-size:13px;
    color: #D60000;
     text-align: center;
}
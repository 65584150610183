.new-sidebar-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.new-scrollable-sidebar {
  flex-grow: 1;
  overflow-y: auto;
  padding: 1em 0.75em;
}

.new-version {
  text-align: center;
  padding: 1em 0.75em;
  color: #2c2c2c95;
  font-weight: 600;
  background-color: #f7f7f7;
  border-top: 1px solid #e0e0e0;
}

.new-listparent {
  display: flex;
  flex-direction: row;
  overflow-y: auto;
  gap: 12px;
  align-items: center;
  height: 60px;
  border-top-right-radius: 1em;
  border-bottom-right-radius: 1em;
}

.new-icon-name {
  color: #000000;
  font-family: "Assistant";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  width: 180px;
}

.new-listparent:hover .new-icon-name{
  color: #f79c00;
  font-weight: 600;
}


.new-listparent:hover .new-list-icon-hover  {
  display: none;
}

.new-listparent:hover .new-list-icon {
  display: block;
}

.new-parent {
  cursor: pointer;
  display: flex;
  padding: 0 1em;
  border-top-right-radius: 1em;
  border-bottom-right-radius: 1em;
}

.new-parent.new-expanded {
  display: flex;
  flex-direction: column;
}

.new-parent:hover{
  background-color: #fffbf5;

}
.new-parent:focus {
  background-color: #fffbf5;
}

.new-expanded:hover{
  background-color: initial;
}

.new-additional-options {
  display: flex;
  gap: 15px;
  flex-direction: column;
  font-weight: 600;
}
   .new-additional-options li:hover {
    background-color: #fffbf5;
    color: #f79c00;
}
.new-options {
  height: 40px;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 1.8em;
  border-top-right-radius: 1em;
  border-bottom-right-radius: 1em;
}

.card-layout-opedu{
  display: flex;
  flex-wrap: wrap;
    /* justify-content: space-between; */
  .get-verify-card{
    display: flex;
    flex-basis: calc(33.33% - 20px);
    flex-direction: row;
    height: 188px;
    width: 335px;
    /* border: 1px solid #2C2C2C1A; */
    border-radius: 4px;
    opacity: 1;
    margin-top: 36px;
    padding-top: 36px;
    padding-left: 36px;
    margin: 23px 28px;



    border-radius: 10px;
/* border: 1px solid var(--light-50, #E3E5E8); */
box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10);
.get-document-name{
    color: var(--unnamed-color-2c2c2c);
    text-align: left;
    font: normal normal bold 18px/22px Assistant;
    letter-spacing: -0.96px;
    color: #2C2C2C;
    opacity: 1;
}
.get-document-number{
    color: var(--unnamed-color-2c2c2c);
    text-align: left;
    font: normal normal bold 14px/22px Assistant;
    letter-spacing: -0.96px;
    color: #2C2C2C;
    opacity: 0.6;
    padding-top: 12px
    
}
}
}


.get-delete-doc{
    /* background-color: yellow; */
    cursor: pointer;
    margin-top: 11px;
    margin-left: 153px;
}

.Doc-support-block{
    width: 95%; 
    margin-left:18%;
}

.custom-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 1000;
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Center the modal in the viewport */
}


/* Add this CSS to your GetVerifyDetails.css file or equivalent */

.modal-backdrop{
  position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* This will make the background dark with 70% opacity */
    z-index: 998;
}
.confirmation-dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: var(--light-10, #FEFEFE);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    padding: 20px;
    border-radius: 5px;
    z-index: 999;
    text-align: center;
  }
  
  .confirmation-dialog p {
    margin: 0 0 20px;
  }
  
  .confirmation-dialog button {
    margin: 0 10px;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    background: var(--E9-Gradient, linear-gradient(296deg, #F79C00 23.18%, #FED401 94.52%));
    color: #fff;
    border-radius: 5px;
    outline: none;
  }
  
  .confirmation-dialog button:hover {
    background: var(--E9-Gradient, linear-gradient(296deg, #F79C00 23.18%, #FED401 94.52%));
    transform: translate(2px);
  }
  
  .confirmation-dialog button:last-child {
    background-color: #ccc;
  }
  
  .confirmation-dialog button:last-child:hover {
    background-color: #999;
  }

  /* Add this CSS in your GetVerifyDetails.css file or a separate CSS file */

.document-popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .document-popup {
    background-color: #fff;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    max-width: 80%;
    max-height: 80%;
    overflow: auto;
    position: relative;
  }
  
  .document-popup img {
    max-width: 100%; /* Adjust this to control the maximum width of the image */
    max-height: 100%; /* Adjust this to control the maximum height of the image */
    display: block;
    margin: 0 auto;
  }
  
  .close-button {
    /* background-color: #f44336; */
    color: #000000;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 10px 0;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
  }


  .get-document-viewDetail{
    display: flex;
    flex-direction: row;
    gap: 31px;
    margin-top: -62px;
    margin-left: 55px;
  }
  .view-document-icon{
    margin-top: 12px;
  }
  .view-document-detai{
    color: var(--secondary-70, #004BB2);
    font-family: Assistant;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 7px;

  }

  .conformation-text{
    text-align: left;
    font: normal normal 18px/20px Assistant;
    letter-spacing: -0.96px;
    color: #2C2C2C;
    opacity: 1
  }

  .hero-document{
    color: var(--dark-70, #1A1E21);
    /* Body 3/Regular */
    font-family: Assistant;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.6px;
  }

  .value-document-name{
    color: var(--dark-70, #1A1E21);
    /* H4/Bold */
    font-family: Assistant;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.16px;
  }
.education-support-block{
  margin-top:12px;
  margin-right: -41px;
}

.added-card-informatio{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 12px 11px;
}


.get-document-country-name{
  margin-top: 14px;
}

.delete-upload-education-doc{
  display: flex;
    flex-direction: row;
    /* background-color: red; */
    justify-content: space-between;
    margin: 12px 27px;
}

.no-education-background{
  display: flex;
  justify-content: center;
  margin-top: 64px;
  color: #999;
  font-family: Assistant;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}


  
  
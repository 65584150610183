.highlighted-element__wrapper{
  width: 240px;
  height: 225px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFB433;
  }
  
.highlighted-element{
    background: var(--dark-50);
    width: 234px;
    height: 219px;
    display:flex;
    padding:20px;
    flex-direction: column;
    border-radius: 9px;
    color:  #FFB433;
    align-items: flex-start;

  }

  .highlighted-element__heading{
    font-size: 56px;
    font-weight: 700;
  }

  .highlighted-element__para{
    font-size: 18px;
    color: var(--light-10);
    letter-spacing: 1%;
    font-weight: 400;
  }

/* styles.css */

.new-menu-layout-footer{
  flex: 0 0 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-top: 1px solid rgb(224, 224, 224);
    max-height: 37px;
    justify-content: center;
    align-items: center;
    background: rgb(247, 247, 247);
    height: 37px;
    font-style: normal;
    font-family: Assistant;
    font-weight: 800;
    margin-top: 2px;
}
.side-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 240px;
  width: 100%;
  height: 100%;
  border-right: 1px solid #2c2c2c40;
}

.side-menu-wrapper {
  max-width: 240px;
  max-height: 200px;
  flex: 0 0 30%; 
  border-bottom: 1px solid #2c2c2c40;
}
.side-menu-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.side-menu-container-logo {
  height: 100%;
  width: 100%; 
  display: flex;
  align-items: center;
  justify-content: center; 
  flex: 0 0 40%;
}
.side-menu-container-info {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 60%;

}

.side-menu-body-wrapper{
  height: 100%;
  overflow: scroll;
}
.side-menu-body-container{
  height: 100%;
  width: 100%;
  background-color: beige;
}
.side-menu-item{
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* gap: 5px; */
}
.menu-detail{
  display: flex;
  align-items: center;
}

.menu-icon{
  height: 50px;
  width: 50px;
}
.menu-name{

}
.menu-expand{

}

.supprt-outline{
    margin-left: 2%;
    margin-top: 19px;
}


.hero-raiseticket{
    margin-top: 23px;
}

.heroheadline-raise{
    top: 154px;
    left: 280px;
    width: 506px;
    height: 34px;
    color: var(--unnamed-color-2c2c2c);
    text-align: left;
    font: normal normal bold 26px/34px Assistant;
    letter-spacing: 0px;
    color: #2C2C2C;
    opacity: 1;
}


.heroSubheadline-raise{
    top: 196px;
    left: 280px;
    width: 374px;
    height: 23px;
    margin-top: 14px;
    color: var(--unnamed-color-2c2c2ccc);
    text-align: left;
    font: normal normal normal 18px/28px Assistant;
    letter-spacing: 0px;
    color: #2C2C2CCC;
    opacity: 1;
}

.RaiseComplaint{
    display: flex;
    flex-direction: row;
}

.seprator-hr-ticket{
    height: 60%;
    margin-top: 27px;
    /* background-color: red; */
    border-top: 3px solid #bbb;
    width: 65%;
    margin-left: -139px;
/* margin-left: 23px; */
}

.add-verificat{
    width: 120px;
    height: 34px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 30px var(--unnamed-color-2c2c2c14);
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 30px #2C2C2C14;
    border: 1px solid #1E326480;
    border-radius: 4px;
    opacity: 1;
    margin-left: 23px;
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer; 


    .add-verification-detail{
color: var(--unnamed-color-1e3264);
/* text-align: center; */
font: normal normal 600 12px/12px Assistant;
font-weight: 700;
/* letter-spacing: -0.96px; */
color: #1E3264;
/* opacity: 1; */
    }
}
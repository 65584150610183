.upcoming-job-title-layout{

}

.Job-title-upcomming{
color: #141619;
font-size: 32px;
font-weight: 700;
}

.userImageProfile{
    border: 1px #606070 solid;
    border-radius: 50%;
    object-fit: fill;
    background-color: #a7a7a7;
    width: 100%;
    height: 100%;
}

.imageContainer{
    max-width: 66px;
    max-height: 66px;
    min-width: 66px;
    min-height: 66px;
    border-radius: 50%;

}


.owner-contact-details{
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding: 0em 3em; */
}

.upcoming-seprator{
    height: 2px;
    width: 80%;
    background-color: #0F3274;
}

.upcoming-seprator-upload-daily-status{
    height: 2px;
    width:100%;
    background-color: #0F3274;
    margin-top: 12px;
}

.OwnerDetailsText{
    font-size: 18px;
    font-weight: 600;
    color: #002961;
}

.Rating-layout{
    display: flex;
    align-items: center;
/* width: 490px;
height: 150px; */
padding: 12px 14px;
gap: 13.955px;
border-radius: 8px;
background: #FFF;
/* box-shadow: 0px 0px 5.582px 0px rgba(0, 0, 0, 0.25); */
cursor: pointer;
border: 1px solid #E3E5E8;
}

Rating-layout:hover{
    box-shadow:transparent linear-gradient(273deg, var(--unnamed-color-f79c00) 0%, #FED401 100%) 0% 0% no-repeat padding-box;
    border:2px solid #FED401
}


.rating-details{
    text-align: center;
    color: #000;
    font-family: Assistant;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
}

.rating-star{
 /* height: 23px;
 width: 38px; */
 background-color: #FED401;
 border-radius: 3px;
 display: flex;
 justify-content: center;
 align-items: center;
 gap: 3px;
 padding: 0 6px;
}

.rating-seprator{
    /* background-color: border: 1px solid #E3E5E8;
    height: 2px;
    width: 100%; */
    border:1px solid #E3E5E8
}


.Equipment-details-type-card{
display: flex;
width: 170px;
height: 100px;
flex-direction: column;
align-items: center;
justify-content: space-between;
gap: 13.955px;
border-radius: 8.373px;
background: #FFF;
box-shadow: 0px 0px 5.582px 0px rgba(0, 0, 0, 0.25);
padding: 0.5em 0.5em;
}


.Equipment-details-type-card-salary{
    display: flex;
    width: 200px;
    height: 100px;
    padding: 0.5em 0.5em;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 8.373px;
    background: #FFF;
    box-shadow: 0px 0px 5.582px 0px rgba(0, 0, 0, 0.25);
    }

.resultDetails{
    color:#FAA300;
    font-family: Assistant;
font-size: 18px;
font-style: normal;
font-weight: 600;  
/* height: 79px;   */
}


.Equipment-details-card-layout{
    display: flex;
    flex-direction: row;
    row-gap: 12px;
    
}

.EquipmentTypeText{
    color:#000000;
    font-family: Assistant;
font-size: 16px;
font-style: normal;
font-weight: 600; 
/* margin-top: 22px;  */
}

.job-layout{
display: flex;
width: 430px;
height: 100px;
padding: 0.5em 0.5em;
flex-direction: column;
justify-content: space-between;
align-items: center;
border-radius: 8.373px;
background: #FFF;
box-shadow: 0px 0px 5.582px 0px rgba(0, 0, 0, 0.25);
}

.OwnerName{
    color:#141619;
    font-family: Assistant;
font-size: 18px;
font-style: normal;
font-weight: 700;   
}

.companyName{
    color: #959596;
    font-family: Assistant;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
}

.phoneNumber{
color: #606070;
font-family: Assistant;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}


.giveRatings{
   color: #001F7B; 
   font-family: Assistant;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 3px;
}

.rateOwnerText{
    color: #001F7B; 
    font-family: Assistant;
     font-size: 22px;
     font-style: normal;
     font-weight: 700;
     margin-top: 12px;
}

.Rating-layout-owner{
    display: flex;
    flex-direction:row;
     gap: 45px;
    margin-top: 22px;

}


.star-rating {
    cursor: pointer;
    font-size: 24px;
  }
  
  .star {
    color: gray;
    margin-right: 5px;
    transition: color 0.3s ease; /* Add a smooth color transition */
  }
  
  .star.active {
    color: #fba301;
  }

.ratingText{
    width: auto;
    height: 23px;
     color: #FED401;
    font-family: Assistant;
     font-size: 14px;
     font-style: normal;
     font-weight: 400;
     text-align: center;
}


.Condition-heading{
    width: 150px;
    color: #1e3264
}

.button-layout{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px 62px;
}

.submit-button{
    width: 120px;
    height: 40px;
    border-radius: 6px;
    background: linear-gradient(296deg, #F79C00 23.18%, #FED401 94.52%);
}

.button-text{
    color: #fff;
    font-family: Assistant;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    text-align: center;
}


.rating-section{
color: #fba301;
}

.outofrating{
    color: #000;
    font-family: Assistant;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    text-align: center;
}

.WorkStatus-button{
  margin: 12px 53px;  
  margin-bottom: 32px;
}

.submit-button-report{
    height: 47px;
    width: 390px;
    border: 1.5px #F79C00 solid ;
    border-radius: 5px;
}

.submit-daily-report{
 color:#F79C00 ;
 font-family: Assistant;
 font-size: 14px;
 font-style: normal;
 font-weight: 600;
 text-align: center;
}

.Cancel-job{
      display: flex;
     justify-content: center;
     align-items: center;
     margin-bottom: 23px;
     margin-top: 13px;
}
.cancel-button{
    height: 40px;
    width: 300px;
    background-color:#ff0000 ;
    border-radius: 5px;
}

.cancel-text{
    color: #fff;
    font-family: Assistant;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    text-align: center;
}

.raise-complaint-text{
    display: flex;
     justify-content: center;
    align-items: center;
    font-family: Assistant;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    text-align: center;
    color: #000
}


.otpinputLayout-ongoing{
    /* height: auto; */
    width: auto;
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    /* align-self:center; */
    /* margin: 0px 23px; */
    gap: 17px;
}

.otpinputerror-layout-ongoing{
    display: flex;
    flex-direction: column; 
}

.input-layout{
    width: auto; 
    display: flex;
    margin: 0px 53px;
    flex-direction: column; 
}

.otp-text{
    color: #000;
    font-family: Assistant;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    padding-bottom: 12px;
}

.resonforcancellation{
    border:1px solid var(--Light-50, #E3E5E8);
    background: var(--Light-30, #F5F5F5);
    width: 391px;
height: 80px;
    padding: 3px 18px;
    margin: 12px 23px
    
}

.submit-button-ongoing{
    width: 120px;
    height: 40px;
    border-radius: 6px;
    background: linear-gradient(296deg, #F79C00 23.18%, #FED401 94.52%)
}

.button-layout-ongoing{
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    margin: 22px 82px;
    gap: 20px;
}


.upload-document-text{
    color: #0F3274;
    font-family: Assistant;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    padding-bottom: 12px; 
    text-align: center;
}


.form-submit-button{
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-items: center;
}

.submit-report-layout{
    display: flex;
    align-items: center;
    justify-content: center;
}


.docbutton-ongoing{
    display: flex;
width: 106px;
height: 34px;
padding: 10px;
justify-content: center;
align-items: center;
gap: 10px;
flex-shrink: 0;
border-radius: 6px;
border: 1px solid #888;
background: #FFF;
}

.upload-text{
    color: #888;
font-family: Assistant;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.18px;
}


.work-description-layout{
    margin: 12px 0px;
}

.error-text-ongoing{
    color: red;
    font-family: Assistant;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.18px;
margin-bottom: 22px;
margin-left: 28px;
margin-top: 8px;
/* text-align: center; */
}


.error-text-ongoing-cancel{
    color: red;
    font-family: Assistant;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.18px;
margin-bottom: 22px;
margin-left: 4px;
margin-top: 8px;
/* text-align: center; */
}

.work-description-text{
    color: #333;
font-family: Assistant;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 0.18px;
margin-bottom: 22px;
margin-left: 23px;
}

.submit-reject{
    display: flex;
width: 448px;
height: 89px;
padding: 8px 0px;
justify-content:space-around;
align-items: center;
border-radius: 0px 0px 12px 12px;
background: var(--Light-10, #FEFEFE);
box-shadow: 0px -10px 15px 0px rgba(44, 44, 44, 0.16);
}

.reject-button-ongoing{
    display: flex;
width: 164px;
height: 50px;
padding: 16px 36px;
justify-content: center;
align-items: center;
gap: 10px;
/* flex-shrink: 0; */
border-radius: 6px;
background: #FFF;
box-shadow: 0px 1px 5.3px 0px rgba(0, 0, 0, 0.25);
color: #000;
}

.accept-button-ongoing{
    display: flex;
width: 144px;
height: 53px;
padding: 16px 36px;
justify-content: center;
align-items: center;
gap: 10px;
flex-shrink: 0;
border-radius: 6px;
opacity: 0.78;
background: var(--E9-Gradient, linear-gradient(296deg, #F79C00 23.18%, #FED401 94.52%));

}


.resonforcancellation-cancel{
    border: 1px solid var(--Light-50, #E3E5E8);
    background: var(--Light-30, #F5F5F5);
    width: 333px;
    height: 80px;
    padding: 3px 18px;
    margin: 8px 0px;   
}

.canceljob-text{
    color: var(--Light-10, #FEFEFE);
font-family: Assistant;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;

}

.accept-job-text{
    color: var(--Light-90, #959596);
font-family: Assistant;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.error-text-ongoing-star{
    color: red;
    font-family: Assistant;
font-size: 18px;
font-style: normal;
/* font-weight: 500; */
/* line-height: normal;
letter-spacing: 0.18px;
margin-bottom: 22px;
margin-left: 4px;
margin-top: 8px; */
/* text-align: center; */
}


 
















/* App_theme: "#F79C00",
  LOADING: "#0c9",
  WHITE: "rgba(255, 255, 255, 1)",
  LIGHT_WHITE: "#dfdfdf",
  FAINT_WHITE: "#efefef",
  BORDER_GRAY: "#a7a7a7",
  BLACK: "#000000",
  FAINT_BLACK: "rgba(0,0,0,0.5)",
  MODAL_BLACK: "#000000aa",
  DARKGRAY: "#999",
  FAINT_ORANGE: "#FAA300",
  HEADER_ORANGE: "#fba301",
  PRIMARY_COLOR: "#FAA300",
  FAINT_PRIMARY_COLOR: "#fec500",
  SECONDARY_COLOR: "#fba301",
  FAINT_SECONDARY_COLOR: "#f7ad00",
  DARK_GREEN: "#216c2a",
  GREEN: "#18c207",
  LIGHT_GREEN: "#1ce019",
  FAINT_GREEN: "#00cb4c",
  LIGHT_BLUE: "#6EA8DA",
  BLUE: "#1e3264",
  DARK_BLUE: "#0F3274",
  RED: "red",
  REQUIRED_RED: "#D60000",
  REQUIRED_BORDER_RED: "#D60000CC",
  LIGHT_RED: "#ff0000",
  FAINT_RED: "#eb1c1c",
  HEADER_RED: "#bc0000",
  VERY_DARK_GRAY: "#999",
  DARK_GRAY: "#606070",
  MEDIUM_GRAY: "#808080",
  FAINT_GRAY: "#cdcdcd",
  LIGHT_GRAY: "#8d8d8d",
  VERY_LIGHT_GRAY: "#c3c3c3",
  TRANSPARENT_GRAY: "rgba(52, 52, 52, 0.3)",
  TRANSPARENT_LIGHT_GRAY: "rgba(52, 52, 52, 0.2)",
  TRANSPARENT_WHITE_GRAY: "rgba(255, 255, 255, 0.6)",
  SHADOW_GRAY: "#c1c1c1",
  light_background: "#2C2C2C05",
  light_borderColor: "#2C2C2C14",
  border_light: "#2C2C2C1A",
  light_background_main: "#FCFCFC",
  text_color_dark: "#2C2C2C",
  EARLY_DAWN: "#FEF8EC",
  EARLY_DAWN_LIGHT: "#FEFBF5",
  OFF_FOCUS_INPUT_COLOR: "#2C2C2C14",
  SUCCESS_GREEN: "#319F00",
  INPUT_PLACE_HOLDER: "#2C2C2C20",
  default_blue: "#1E3264",
  bg_user_data: "#F79C001A",
  BADGE_EXPIRED: "#929292",
  RATING_BADGE_BG: "#FED401",
  STICK_BADGE_BG: "#0068F7",
  FAINT_ORANGE: "#FFF5E080",
  BRIGHT_BLUE: "#004BB2",
  // =========================
  SHADOW_COLOR: Platform.OS == "ios" ? "#2C2C2C30" : "#2C2C2C",
  BORDER_COLOR: "#2C2C2C20",

  // ============ STEP COLOR =============
  STEP_PROGRESS_COLOR: "#F79C00",
  STEP_PROGRESS_LABEL_COLOR: "#707070",
  TEXT_GRAY: "#BFBFBF",
  TEXT_BLUE: "#001F7B",
  MODAL_CROSS_COLOR: "#2C2C2C40",
  TEXT_LIGHT_PLACEHOLDER: "#C6C7C8",

  DARK_GRAY: "#C6C7C8",
  SEMI_TRANSP_GREEN: "#319F0080",
  SEMI_TRANSP_RED: "#9F000080", */
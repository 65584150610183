.user-equipment__card-container{
    width: 100%;
    height: 200px;
    display: flex;
    overflow: hidden;
    border-radius: 10px ;
    background: #FFF;
    align-items: center;
    margin-bottom: 1em;
    box-shadow: 0px 0px 4px 0px #00000040;

}

.user-equipment__card-container-image{
    max-height: 300px;
    max-width: 630px;
    border-radius: 4px;
}

.user-equipment__card-container__info{
    display: flex;
    padding: 30px 60px;
    width: 100%;
    height: 100%;
}

.user-equipment__card-container__info-container{
    display: flex;
    flex-direction: column;
    gap:0.5em;
    width: 100%;
  }

  .user-equipment__card-container__info-container-text{
    color: #002961;
    font-weight: 700;
    font-size: 28px;
  }
  .user-equipment__card-container__info-action{
    display: flex;
    flex-direction: row;
  }
  
  .user-equipment__card-container__info-action-btn{
    margin: 5px;
    padding: 0.25rem 0.75rem;
    border-radius: 20%;
    height: 45px;
    width: 45px;
  }

  .delete{
   background-color: #FBF5F5;
  }

  .edit{
    background: #F7FAF5;
  }
  .user-equipment__card-container__info-action-btn img{
    width: 100%;
    height: 100%;
  }

  .user-equipment__footer-btn{
    padding:10px;
    border-radius:4px;
    border:1px solid #002961;
    background-color:white;
    font-weight: 600;
  }

  .user-equipment__footer-btn:hover{
    background-color: #002961;
    color: white;
  }
  

.my-material-type__heading {
  color: #141619;
  font-size: 24px;
  font-weight: 700;
}

.my-material-type__sub-heading-layout {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.my-material-type__sub-heading{
  color: #767676;
  font-size: 18px;
}

.my-material-type__add-button{
  padding: 5px 20px;
  height: max-content;
  font-size: 24px;
  font-weight: 700;
  color:#002961 ;
  border-radius: 4px;
  border: 1px solid #002961;
}

.my-material-type__card-container{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  max-height: 360px;
  overflow-y: scroll;
  gap: 2em;
  padding: 10px 40px;
}

.my-material-type__card-container--empty-msg{
  color: #76767690;
  font-size: 25px;
  font-weight: 700;
}


.current-location-container {
    max-width: 450px;
    width: 100%;
}

.current-location {
    display: flex;
    gap: 1em;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.2px;
    color: #002961;
    margin-top: 0.5em;
}

.additional-details-element-container {
    max-width: 450px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1em;
}
.additional-details-element-photos-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1em;
}
.media-photos-error{
    height: 140px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.region-details-element-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1em;
}
.additional-details-photos-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.additional-details-header-text {
    color: #1E3264;
    font-family: Assistant;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.24px;
    text-transform: uppercase;
}

.additional-details-button {
    display: flex;
    width: 94px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    border: 1.5px solid #1E3264;
}

.additional-details-btn-img {
    max-width: 20px;
    max-height: 20px;
}


.addtional-details-region-container {
    display: flex;
    width: 264.281px;
    height: 100px;
    max-height: 100px;
    padding: 13.711px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 9.14px;
    border-radius: 4.57px;
    border: 1.143px solid #FFF;
    background: var(--Light-10, #FEFEFE);
    box-shadow: 0px 2.285px 4.57px -1.143px rgba(0, 0, 0, 0.06), 0px 4.57px 6.855px -1.143px rgba(0, 0, 0, 0.10);
}

.no-data-message {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.media-container {
    height: 200px;
    width: 200px;
    border: 2px solid #e2e8f0;
    display: flex;
    flex-direction: column;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    background: lightgray -8.343px -10.762px / 109.608% 109.328% no-repeat;
    flex-shrink: 0;
}
.region-footer-container{
    display: flex;
    height: 20%;
    width: 100%;
    background-color: #FFF;
    border-bottom-right-radius: 0.4em;
    border-bottom-left-radius: 0.4em;
    gap: 0.25em;
    justify-content: center;
}
.media-heading {
    max-height: 28px;
    width: 100%;
    height: 100%;
    text-align: center;
    border-radius: 12px 12px 0px 0px;
    background: rgba(74, 74, 74, 0.41);

}

.media-body {
    max-height: 160px;
    height: 150px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FEFEFE;
}

.region-body {
    border-radius: 4.57px;
    background: #F5F5F5;
    box-shadow: 0px 1.143px 2.285px 0px rgba(0, 0, 0, 0.06), 0px 1.143px 3.428px 0px rgba(0, 0, 0, 0.10);
    padding: 2.285px 9.14px;
    gap: 9.14px;
    max-width: fit-content;
}
.region-body-container{
    display: flex;
    flex-wrap: wrap;
    gap: 0.50em;
    padding: 1em 1em;
    overflow-y: scroll    ;
}
.rigions-display-container{
    display: flex;
    align-items: center;
    gap: 1em;
    width: 1110px;
}
.media-display-container{
    width: 1110px;
    overflow-y: scroll;
}
.media-heading-text {

    font-size: 20px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FEFEFE;
}
.dealership-no-data{
    color: #888;
    font-size: 26px;
    font-weight: 400;
    letter-spacing: 0.26px;
}
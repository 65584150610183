.dealership-container-wrapper{
    padding-right: 52px;
    padding-left: 52px;
    margin-top: 80px;
}
.dealershipCard-container-element{
    max-height: 550px;
    max-width: 420px;
    border: 1px solid #2c2c2c1a;
    border-radius: 1em;
}
.dealershipCard-container{
    /* height: 100%; */
    width: 100%;
    display: flex;
    flex-direction: column;
}
.dealershipCard-image{
    object-fit: cover;
    max-height: 330px;
    width: 100%;
    position: relative;
}
.dealershipCard-header{
    
    position: absolute;
    display: flex;
    width: 400px;
    justify-content: space-between;
    object-fit: contain;
    padding: 0.25em;
}
.dealership-header-verifiction{

}
.dealership-header-type{

}
.dealershipCard-header-body{
    padding: 0.5em 1em;
}
.no-data-found{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* background-color: red; */
}

.no-data-found-text{
    font-family: Assistant;
    font-size:22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;  
    width: auto;
    height: 14px;
    margin:-2px -73px;
}


.no-material-added-yet-upcoming {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
    width: 100%;
    margin-top: 3%;
    color: #BFBFBF;
    font-family: Assistant;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.158px;
    margin-left: 1px;
}


/* .color: red;
 */

 .badge-layout-ongoing{
    width: 33%;
    border-radius: 34px;
border: 1px solid #F79C00;
background: rgba(247, 156, 0, 0.04);
/* position: absolute; */
 }

 .userResposeTypeValue-ongoing{
    color: #F79C00;
    font-family: Assistant;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    text-align: center; 
 }
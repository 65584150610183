.all-marketplaces-headline {
    color: #333;
    font-size: 28px;
    font-weight: 700;
  }
  
  .all-marketplaces-subheading {
    color: #333;
    font-size: 24px;
    font-weight: 400;
  }
.card{
  display: flex;
  flex-wrap: wrap;
}


.card-information{
  display: flex;
  /* flex-direction: row; */
  border-radius: 10px;
  border: 0.2px solid var(--light-50, #E3E5E8);
  background: #FFF;
  width: 335px;
  height: 188px;
  gap: 23px;
  margin: 38px;
  box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10);
}
.added-date-and-verified{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 310px;
  margin: 5px 12px;
}


.get-delete-doc{
    /* background-color: yellow; */
    cursor: pointer;
    margin-top: 11px;
    margin-left: 153px;
}

.Doc-support-block{
  margin-left: -20%;
  margin-top: 10px;
  
}

.deleteHeading{
display: flex;
justify-content: center;
}

.custom-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 1000;
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Center the modal in the viewport */
}


/* Add this CSS to your GetVerifyDetails.css file or equivalent */

.modal-backdrop{
  position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* This will make the background dark with 70% opacity */
    z-index: 998;
}
.confirmation-dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: var(--light-10, #FEFEFE);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    padding: 20px;
    border-radius: 5px;
    z-index: 999;
    text-align: center;
  }
  
  .confirmation-dialog p {
    margin: 0 0 20px;
  }
  
  .confirmation-dialog button {
    margin: 0 10px;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    background: var(--E9-Gradient, linear-gradient(296deg, #F79C00 23.18%, #FED401 94.52%));
    color: #fff;
    border-radius: 5px;
    outline: none;
  }
  
  .confirmation-dialog button:hover {
    background: var(--E9-Gradient, linear-gradient(296deg, #F79C00 23.18%, #FED401 94.52%));
    transform: translate(2px);
  }
  
  .confirmation-dialog button:last-child {
    background-color: #ccc;
  }
  
  .confirmation-dialog button:last-child:hover {
    background-color: #999;
  }

  /* Add this CSS in your GetVerifyDetails.css file or a separate CSS file */

.document-popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .document-popup {
    background-color: #fff;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    max-width: 80%;
    max-height: 80%;
    overflow: auto;
    position: relative;
  }
  
  .document-popup img {
    max-width: 100%; /* Adjust this to control the maximum width of the image */
    max-height: 100%; /* Adjust this to control the maximum height of the image */
    display: block;
    margin: 0 auto;
  }
  
  .close-button {
    /* background-color: #f44336; */
    color: #000000;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 10px 0;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
  }


  .get-document-viewDetail{
    display: flex;
    flex-direction: row;
    gap: 31px;
    margin-top: -62px;
    margin-left: 55px;
  }
  .view-document-icon{
    margin-top: 12px;
  }
  .view-document-detai-verification{
    color: var(--secondary-70, #004BB2);
    font-family: Assistant;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 7px;
    margin-top: 12px;
    cursor: pointer;
  }

  .conformation-text{
    margin-top: 12px;
    text-align: left;
    font: normal normal 18px/20px Assistant;
    letter-spacing: -0.96px;
    color: #2C2C2C;
    opacity: 1
  }

  .hero-document{
    color: var(--dark-70, #1A1E21);
    font-family: Assistant;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .value-document-name{
    color: var(--dark-70, #1A1E21);
    /* H4/Bold */
    font-family: Assistant;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.16px;
  }
.added-on{
  color: var(--dark-10, #767676);
/* Body 3/Semibold */
font-family: Assistant;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.6px;
}

.added-on-date{
  color: var(--light-90, #959596);
  /* Body 3/Regular */
  font-family: Assistant;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.6px;
}
.added-card-information{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 12px 11px;
}
  
.upload-and-deletedoc{
  display: flex;
  flex-direction: row;
  /* background-color: red; */
  justify-content: space-between;
  margin: 20px 11px;
}

.delete-icon{
  display: flex;
  align-items: center;
  justify-content: center;
  margin:11px 11px;
  cursor: pointer;
}

.yes-button {
  background: #C6C7C8;
  transition: 0.3s background;
}

.yes-button.hovered {
  background: linear-gradient(90deg, var(--primary), var(--tertiary));
}

.no-button {
  background: linear-gradient(90deg, var(--primary), var(--tertiary));
}

.no-documents-message{
  margin: 45px 32%;
  color: #999;
  font-family: Assistant;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}



  
  
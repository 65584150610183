
.settings-right-pane{
  display: flex;
    align-items: center;
    width: 80%;
    gap: 20px;
    
}

.settingsContainer{
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 2% 0%;
  position: relative;
  /* align-items: center; */
  left: 50px;
  
}

  .settings-details{
    display: flex;
    flex-direction: row;
    /* gap: 25px;  */
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;

    .settings-details-icon{
    
    margin-left: 0%;
    position: relative;
    left: 1%;
    height: 24px;
    width: 24px;
    opacity: 1 
    }
    .your-settings-details{
      
      height: 34px;
      font: normal normal bold 26px/34px Assistant;
      letter-spacing: 0px;
      color: #2C2C2C;
      margin-left: 2%;
      opacity: 1;
        
    }
    .seprator{

    height: 100%;
    border-top: 3px solid #bbb;
    width: 72%;
    margin-left: 23px;
}

  }



.collapsed-div {

    display: flex;
    flex-direction: column;
    /* border: 1px solid #929292; */
    border-radius: 4px;
    opacity: 1;
    /* background: #FBFBFB 0% 0% no-repeat padding-box; */
    box-shadow: 0px 1px 2px 0px #0000000F;
    margin: 2%;
    padding: 2%;
    gap: 10px;

    /* .css-1mimm63 {
      width: 100%;
      position: relative;
      max-width: none;
  } */
}

.privacy-setting-container {

    display: flex;
    flex-direction: column;
    border-radius: 4px;
    opacity: 1;
    /* margin: 2%; */
    padding: 2%;
    gap: 10px;
}

/* .collapsed-div button {
  align-self: center;
} */

.collapsed-div-field {
 

  padding: 18px;
  width: 100%;
  text-align: left;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: "#002961";
  font: normal normal bold 26px/34px Assistant;
  letter-spacing: 0px;
  opacity: 1;
  box-shadow: 0px 1px 2px 0px #0000000F;
  border-radius: 4px;

  
}


/* .settings-right-pane.active + .collapsed-div {
  display: block;
  transition: top 0.3s; 
} */

.collapsed-div button[type="submit"] {
 
  margin: 1%;
  width: 30%;
  height: 38px;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  background: transparent linear-gradient(274deg, #F79C00 0%, #FED401 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 20px #F79C004D;
  border-radius: 4px;
  opacity: 1;
  /* align-self: center; */

}
.collapsed-div-header{

  /* line-height: 21px;
  align-self: self-start;
  margin-top: 2%; */
  margin-left: 2%;
  color: #2c2c2c;
  text-align: left;
  font: normal normal bold 26px/34px Assistant;
   letter-spacing: 0px;
   color: #2C2C2C;
   opacity: 1;
}

.collapsed-div-privacy{
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 953px;
  margin-left: 2%;
  border: 1px solid #929292;
  border-radius: 4px;
  opacity: 1;
  margin-top: 2%;
}


.privacy-setting{

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 953px;
  margin-left: 2%;
  border: 1px solid #929292;
  border-radius: 4px;
  opacity: 1;

}

.checkbox-manage{
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin: 8px 32px
}


.container-settings{
  display: flex;
  flex-wrap: wrap;
  padding: 42px 43px;
  gap: 33px;
  /* margin-top: 12px */

}

.tile {
  height: 153px;
  flex: 0 0 calc(25% - 5px); /* Subtract some amount for margin */
  box-sizing: border-box;
  border: 1px solid #ccc; /* Example border color */
  padding: 20px;
  margin: 5px; /* Half of the subtracted amount to account for spacing on both sides */
  text-align: center;
  border-radius: 6px;
/* border: 1px solid #37436E; */
background: #FFF;
cursor: pointer;
background: var(--Light-10, #FEFEFE);
box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1);

}

/* border: 1px solid var(--Light-50, #F79C00); */

.tile:hover {
  height: 153px;
  flex: 0 0 calc(25% - 5px); /* Subtract some amount for margin */
  box-sizing: border-box;
  border: 1px solid #ccc; /* Example border color */
  padding: 20px;
  margin: 5px; /* Half of the subtracted amount to account for spacing on both sides */
  text-align: center;
  border-radius: 6px;
  border: 1.5px solid var(--Light-50, #F79C00);
background: #FFF;
cursor: pointer;
background: var(--Light-10, #FEFEFE);
box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1);

}




.icon-display{
  display: flex;
    flex-direction: row;
    justify-content: center;
}

.setting-layout{
  display: flex;
  flex-direction: column;  
}


.setting-text{
  color: #37436E;
font-family: Assistant;
font-size: 29.04px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-top: 21px;
}








.routeSection{
    margin: 23px 23px;
}

.subcription{

width: 113px;
height: 18px;
/* UI Properties */
color: var(--unnamed-color-2c2c2c);
text-align: left;
font: normal normal medium 14px/38px Assistant;
letter-spacing: -0.14px;
color: #2C2C2C;
opacity: 1;
}


.profile{
    top: 116px;
left: 280px;
width: 37px;
height: 18px;
/* UI Properties */
color: var(--unnamed-color-2c2c2c);
text-align: left;
font: normal normal normal 14px/38px Assistant;
letter-spacing: -0.14px;
color: #2C2C2C;
opacity: 0.5;
}

.hero-heading{
    margin: 23px 23px;
}

.hero-subscription{
    top: 154px;
left: 280px;
width: 506px;
height: 34px;
/* UI Properties */
color: var(--unnamed-color-2c2c2c);
text-align: left;
font: normal normal bold 26px/34px Assistant;
letter-spacing: 0px;
color: #2C2C2C;
opacity: 1;
}

.hero-detail-subscription{
    top: 196px;
left: 280px;
width: 374px;
height: 23px;
/* UI Properties */
color: var(--unnamed-color-2c2c2ccc);
text-align: left;
font: normal normal normal 18px/28px Assistant;
letter-spacing: 0px;
color: #2C2C2CCC;
opacity: 1;
}

.subcrption-table-sub{
    top: 259px;
    left: 280px;
    width: 85%;
    /* height: 77px; */
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 30px var(--unnamed-color-2c2c2c14);
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 30px #2C2C2C14;
    border: 1px solid #2C2C2C1A;
    border-radius: 4px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    margin: 25px 45px;

}

.service-heading{
    background-color: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    width: 100%;
    /* border: 1px solid #2C2C2C14; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.E9-text-subcription{
color: var(--unnamed-color-1e3264);
text-align: center;
font: normal normal bold 18px/24px Assistant;
letter-spacing: -0.18px;
color: #1E3264;
text-transform: capitalize;
opacity: 1;
width: 100%;
background-color: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
border: 1px solid #2C2C2C14;
margin-top: 25px;
height: 75px;
margin-top: 0px;
/* padding-top:  12px;
padding-left: 10%; */
padding-top: 26px;
}


.table-heading{
    background-color: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;;
    width: 100%;
    height: 100%;
    border: 1px solid #2C2C2C14;
    display: flex;
    justify-content: center;
    align-items: center;
}
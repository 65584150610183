.headingName {
  position: absolute;
  font-size: 1.8rem;
  font-weight: 700;
  display: flex;
  justify-content: center;
  top: 3%;
  left: 5%;
  right: 5%;
  color: white; /* Adjust color as needed */
  text-align: center;
  z-index: 1;
}

.functionPatner {
  position: absolute;
  font-size: 1.2rem;
  font-weight: 500;
  display: flex;
  justify-content: center;
  top:4%;
  left: 5%;
  right: 5%;
  color: white; /* Adjust color as needed */
  text-align: center;
  z-index: 1;
}
.revolution {
  position: absolute;
  font-size: 1.2rem;
  font-weight: 500;
  top: 95vh;
  left: 5%;
  right: 5%;
  color: var(--light-10, #FEFEFE);
  text-align:left;
  z-index: 1;
}

.DownlodNow{
  position: absolute;
  font-size: 1.8rem;
  font-weight: 700;
  top: 97.5vh;
  left: 5%;
  right: 5%;
  color: #FECA00;
  text-align:left;
  z-index: 1;
}
.heavyEquipment {
  /* background-image: url(`); */

  /* margin-left: 12%; */
  background: linear-gradient(304deg, #454545 -35.12%, #323232 40.31%, #000 95.8%); 
}
.EquipmentHeadingdivision {
  /* display: flex; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 23px;
}

.TheHeavyEquipmentHeadingdivision {
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
  padding: 2% 6%;
}

.heroheading {
  font-size: 1.8rem;
  color: #ffffff;
  font-weight: 600;
  line-height: 1.2;
}

.heroSubheading {
  font-size: 1.2rem;
  color: #ffffff;
  font-weight: 500;
}

.heroImage {
  height: auto;
  width: 100%;
  object-fit: cover;
  display: block;
  margin: 10px auto;
}
.serachYourOperator {
  height: 100%;
  width: 100%;
  background: linear-gradient(90deg, #28292b 0%, #484b4d 50%, #212529 100%);
}
.heroSerachSubheader {
  color: #FECA00;
  font-size: 1.2rem;
  font-weight: 500;
}

.blockHeader {
  height: 150px;
  width: 200px;
  display: flex;
  justify-content: center;
  border: 2px solid var(--primary-30, #ffb433);
  background: var(--dark-50, #2c2c2c);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;

}

.searchOperatoriconHeading {
  font-size: 1rem;
  color: #ffffff;
  font-weight: 400;
}

.buttonConnect{
  height: 40px;
  margin: 2% 14%;
  background: var(--E9-Gradient, linear-gradient(296deg, #F79C00 23.18%, #FED401 94.52%));
  margin-bottom: 6%;

}
.connectNow{
  text-align: center;
  font-size: 1.1rem;
  color: #ffffff;
  font-weight: 500;
  padding-top: 5px;
  border-radius: 6.727px;
}
.BrokrageHeading{
  font-size: 1.8rem;
  color: #FECA00;
  font-weight: 600;
  line-height: 1.2;
  text-align: center;
}

.BrokrageSubHeading{
  font-size: 1.1rem;
  color: #ffffff;
  font-weight: 600;
  line-height: 1.1;
  display: flex;
  justify-content: center;
  align-items: center;
   width: 280px;
   margin-top: 5%;
}

.connectWithHeading{
  font-size: 1.05rem;
  color: #ffffff;
  font-weight: 500;
}

.hamberger{
  position: absolute;
  background: red;
  color: #ffffff;
}

.backGround{
  height: 100px;
  width: 100%;
  background: var(--dark-90, #141619);
 
}

.image{
  margin: 13px 13px;
}

.whataboutBackground{
  height: 100%;
  width: 100%;
  background: rgb(33, 37, 41)
}

.whatwedo{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px; 
  margin-top: 22px;
  
}
.equip9delivery{
  text-align: justify;
  font-weight: 500;
  color: #ffffff;
  font-size:0.8rem;
  line-height: 1.5;

}

.resposiveVideoFrame{
  margin: 6% 10%;
  border: 2px solid linear-gradient(296deg, #F79C00 23.18%, #FED401 94.52%);
  /* height: 300px; */
  
}

.Aim{
  text-align: justify;
  font-weight: 500;
  color: #ffffff;
  font-size: 0.8rem;
  line-height: 1.5;
  margin-top:-33px;
  margin-bottom: 12px;
}

.productAim{
  text-align: justify;
  font-weight: 500;
  color: #ffffff;
  font-size: 0.8rem;
  line-height: 1.5;
  margin-bottom: 12px;
}

.patternBrokrage{
  font-size: 1.3rem;
  color: var(--primary);
  font-weight: 600;
  line-height: 1.2;
  text-align: center;
  width: 230px;

}

.patternWithHeading{
  font-size: 1rem;
  color: #ffffff;
text-align: justify;
}

.patterHolder{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 23px;
  margin-bottom: 23px;

}

.patternLogo{
  
 width: 86%;
 height: 80%;
 
}


.carousel-dots {
  text-align: center;
  padding: 10px 0;
}

.dot {
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: gray;
  margin: 0 5px;
  cursor: pointer;
}

.dot.active {
  background-color: black;
}

.product-image{
  height: 43vh;
  width: 107vw;
  object-fit: fit;
}

.mobile-product-container{
    background: linear-gradient(90deg,#28292b,#484b4d 50%,#212529);
    border-bottom: 0.5px solid #f79c00;
    border-top:0.5px solid #f79c00; ;
    color: #fbfbfc;
    display: flex;
    justify-content:center;
    align-items: center;
    width: 100%;
}

.continent{
  width: 100vw;
    height: 24vh;
    object-fit: cover;
    /* margin: 0.5 13%; */
    margin-bottom: 12px;

}

.Mechcontinent{
  width: 100vw; 
  height: 24vh;
  /* object-fit: cover; */
  /* margin-bottom: 12px; */
  margin-top: -12%;
  margin-bottom:1px

}

.mobile-product-marketPlace{
  background: linear-gradient(120deg,#28292b,#66696d 50%,#28292b);
  border-bottom: 0.5px solid #f79c00;
  border-top:0.5px solid #f79c00; ;
  color: #fbfbfc;
  display: flex;
  justify-content:center;
  align-items: center;
  width: 100%;
}


.contact-backGround{
  width: 100%;
  height: 398px;
  background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0.00) 130.69%);
}
.contact-us-today{
  color: var(--light-10, #FEFEFE);
  font-family: Assistant;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  /* text-transform: uppercase; */
  margin: 2% 8%;
  text-align: center
}

.Contactus-heroheading {
  font-size: 1.6rem;
  color: #ffffff;
  font-weight: 600;
  line-height: 1.2;
  text-align: center;
}

.contactUs{
  width: 100%;
  height: 100%;
  background-color: rgb(26, 30, 33)
}
.contactUsHeroHeading{
  font-size: 1.8rem;
  color: #ffffff;
  font-weight: 600;
  line-height: 1.2;
  text-align: center;
}


.forminput{
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
}

.inputForm{
  width: 100%;
  height: 100%;
  background-color: rgb(26, 30, 33); 
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center ;
}

.inputLable{
  color: #F79C00;
}

.formDivisionrow{
  display: flex;
  flex-direction: row;
  justify-content: center; 
  align-items: center ;
  padding-left: 20%;
  gap: 12px;
}
.SubmitButton{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  color: #ffffff;
  margin-left: 23%;
  margin-top: 10%;
  background:linear-gradient(to right, rgb(246, 124, 0), rgb(254, 212, 1));
  margin-bottom: 3%;
  border-radius: 5px;
  height: 45px;
}

.Carosel-shift{
  display: flex;
  justify-content: center;
  align-items: center;
  margin:  12px 12px;
  /* border: 2px solid #f79c00; */
}

.imageContact{
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center;
  margin: 3%;
}





.dealership-container {
  max-width: 340px;
  max-height: 500px;
  display: flex;
  overflow: auto;
  flex-direction: column;
  /* background-color: beige; */
  border-radius: 1.025em;
  box-shadow: rgb(149, 149, 150) 0px 2px 4px 1px;

}

.dealership-blog-post-card {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.dealership-blog-post-card:hover {
  /* transform: scale(1); */
}

.dealership-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #2c2c2c08;
  /* background: linear-gradient(90deg, #28292B 0%, #484B4D 50%, #212529 100%); */
  color: white;
  padding: 0.50em;
}

.verification-badge {
  border-radius: 1em;
  background: rgba(214, 0, 0, 0.376);
  text-align: center;
  padding: 0.15em 0.5em;
  min-width: 100px;
  max-height: 28px;
  min-height: 28px;
}

.dealership-blog-post-card1 {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.dealership-blog-post-card1:hover {
  /* transform: scale(1.02); */
}

.dealership-image-container {
  height: 256px;
  max-height: 256px;
  max-width: 340px;
  width: 340px;
  position: relative;
}

.dealership-image-status-tag {
  position: absolute;
  display: flex;
  height: 33.067px;
  padding: 5.972px 11.944px;
  justify-content: center;
  align-items: center;
  gap: 14.93px;
  border-radius: 10px;
  background: #FFF;
  color: #002961;
  font-size: 20.902px;
  font-weight: 700;
  letter-spacing: 0.209px;
  margin: 1em 1em;
}

.dealership-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.dealership-container1 {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  gap: 8px;
  padding: 0.5em 1em;
}

.dealership-container2 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.1em solid;
}
.dealership-new-added{
    height: 26px;
    width: 100px;
    border-radius: 10px;
    background: rgb(0, 104, 247);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.25em 0em;
    color: #FFF;
}

.dealership-text-container {
  display: flex;
  align-items: center;
  gap: 0.75em;
}

.dealership-text-label {
  color: #000;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.24px;
  gap: 0.75em;
}

.dealership-text {
  color: #000;
  font-family: Assistant;
  font-size: 16px;
  /* font-style: normal; */
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.24px;
}

.dealership-text2 {
  /* color: #F79C00; */
  font-family: Assistant;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.24px;
}

.dealership-text3 {
  color: #000;
  font-family: Assistant;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.24px;
}
.get-verify-car{
  display: flex;
  flex-direction: column;
  height: 13%;
  width: 25%;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 30px var(--unnamed-color-2c2c2c14);
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 30px #2C2C2C14;
  border-radius: 7px;
  opacity: 1;
  margin-top: 36px;
  margin: 23px 28px;
  border: 0.2px solid var(--light-50, #E3E5E8);
.get-document-name{
    color: var(--unnamed-color-2c2c2c);
    text-align: left;
    font: normal normal bold 18px/22px Assistant;
    letter-spacing: -0.96px;
    color: #2C2C2C;
    opacity: 1;
}
.get-document-number{
    color: var(--unnamed-color-2c2c2c);
    text-align: left;
    font: normal normal bold 14px/22px Assistant;
    letter-spacing: -0.96px;
    color: #2C2C2C;
    opacity: 0.6;
    padding-top: 12px
    
}
}

.get-delete-do{
    /* background-color: yellow; */
    cursor: pointer;
    margin-top: 9px;
    margin-left: -18px;
}

.Doc-support-blockk{
  margin: 6%;
  height: 17%;
}

.custom-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 1000;
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Center the modal in the viewport */
}


/* Add this CSS to your GetVerifyDetails.css file or equivalent */

.modal-backdrop{
  position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* This will make the background dark with 70% opacity */
    z-index: 998;
}
.confirmation-dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: var(--light-10, #FEFEFE);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    padding: 20px;
    border-radius: 5px;
    z-index: 999;
    text-align: center;
  }
  
  .confirmation-dialog p {
    margin: 0 0 20px;
  }
  
  .confirmation-dialog button {
    margin: 0 10px;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    background: var(--E9-Gradient, linear-gradient(296deg, #F79C00 23.18%, #FED401 94.52%));
    color: #fff;
    border-radius: 5px;
    outline: none;
  }
  
  .confirmation-dialog button:hover {
    background: var(--E9-Gradient, linear-gradient(296deg, #F79C00 23.18%, #FED401 94.52%));
    transform: translate(2px);
  }
  
  .confirmation-dialog button:last-child {
    background-color: #ccc;
  }
  
  .confirmation-dialog button:last-child:hover {
    background-color: #999;
  }

  /* Add this CSS in your GetVerifyDetails.css file or a separate CSS file */

.document-popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .document-popup {
    background-color: #fff;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    max-width: 80%;
    max-height: 80%;
    overflow: auto;
    position: relative;
  }
  
  .document-popup img {
    max-width: 100%; /* Adjust this to control the maximum width of the image */
    max-height: 100%; /* Adjust this to control the maximum height of the image */
    display: block;
    margin: 0 auto;
  }
  
  .close-button {
    /* background-color: #f44336; */
    color: #000000;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 10px 0;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
  }


  .get-document-viewDetail{
    display: flex;
    gap: 3px;
  }
  .view-document-icon{
    margin-top: 12px;
  }
  .view-document-detail{
    text-decoration: underline;
  cursor: pointer; 
  text-align: center;
  /* letter-spacing: -0.96px; */
  /* font: normal normal 600 17px/32px Assistant; */
  color: #1E3264;
  }

  .conformation-text{
    text-align: left;
    font: normal normal 18px/20px Assistant;
    letter-spacing: -0.96px;
    color: #2C2C2C;
    opacity: 1
  }

  .Edit-and-delete{
    display: flex;
    flex-direction: row;
    gap: 23px;
    /* margin-left: -109px;
    margin-right: -24px;
    justify-content: flex-start; */
  }
  .editButton{
/* background-color: red; */
margin-top: -9px;
  }
  .deleteButton{
/* background-color: yellow; */
margin-right: 180px;
  }

  .get-document-numberr{
    color: var(--dark-70, #1A1E21);
    /* H4/Bold */
    font-family: Assistant;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.16px;
  }

  .are-you-certified{
    color:red;

      }
.EquipmentType{
  color: var(--dark-70, #1A1E21);
  /* Body 3/Regular */
  font-family: Assistant;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* letter-spacing: 0.6px; */
      }

      .view-document-detail-Equipment{
        color: var(--secondary-70, #004BB2);
        font-family: Assistant;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        /* text-decoration: underline; */
        /* line-height: normal; */
        /* margin-top: 7px; */
    
      }


   .edit-button{
    margin-top: 5px;
    margin-left: 5px;
    cursor: pointer;
   }

   .verfied{
    margin-top: 5px;
    margin-left: 165px;
   }

   .certified-detail{
    /* margin: 26px 0px; */
   }

   .view-more-detail{
    margin-top:7px;
   }

   .no-equipment-added{
     margin: 45px 40%;
     color: #999;
     font-family: Assistant;
     font-size: 22px;
     font-style: normal;
     font-weight: 600;
     line-height: normal;
   }


  
  
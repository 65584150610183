.my-leads-card-container {
    min-width: 190px;
    width: 190px;
    min-height: 248.733px;
    box-shadow: 7px 6px 9px 0px #2c2c2c25;
    display: flex;
    flex-direction: column;
    padding: 0.75em 0.5em;
    justify-content: center;
    border: 1px solid #959596;
    border-radius: 4px;
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--Light-50, #E3E5E8);
    background: var(--Light-10, #FEFEFE);
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.06),
      0px 4px 6px -1px rgba(0, 0, 0, 0.1);
    gap: 1.5em;
  }
  .my-leads-card-container:hover {
    border-radius: 8px;
    border: 1px solid var(--Light-50, #F79C00);
    background: var(--Light-10, #FEFEFE);
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.06),
      0px 4px 6px -1px rgba(0, 0, 0, 0.1);
  }

  .my-leads-card-img {
    max-height: 71.105px;
    max-width: 71.105px;
    width: 100%;
    height: 100%;
  }
  .my-leads-card-description {
    color: #333;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.24px;
    text-align: center;
  }




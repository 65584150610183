.navbar-wrapper{
    background-color: var(--dark-40);
    margin: 0 auto;
    padding: 0.5em 1em;
}
.navbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 95%;
    margin-top: 1em;
    margin-bottom: 1em;
    
}
.logo-container{
    flex: 2
}
.cta-container{
    display: flex;
    gap: 1em;
    align-items: center;
}
.navlinks{
    display:flex;
    gap: 1rem;
    align-items: center;
}

.link{
    list-style: none;
    font-size: 1.125em;
    font-weight: 600;
    text-decoration: none;
    color: var(--light-10)
}

.link:hover{
    color: var(--primary)
}
.ProfileCompany{
    margin: 30px 40px
}

.ProfileText{
top: 116px;
left: 280px;
width: 37px;
height: 18px;
color: var(--unnamed-color-2c2c2c);
text-align: left;
font: normal normal normal 14px/38px Assistant;
letter-spacing: -0.14px;
color: #2C2C2C;
opacity: 0.5;
}

.compnatMemdetails{
top: 116px;
left: 439px;
width: 146px;
height: 18px;
color: var(--unnamed-color-2c2c2c);
text-align: left;
font: normal normal medium 13px/38px Assistant;
letter-spacing: -0.14px;
color: #2C2C2C;
opacity: 1;
}

.arrowColor{
 color: #F79C00;
 margin-top: 6px;
}

.companyMemberDetails{
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 margin: 12px 42px;
}

.Company-member-hero{
    top: 154px;
left: 280px;

/* UI Properties */
color: var(--unnamed-color-2c2c2c);
text-align: left;
font: normal normal bold 26px/34px Assistant;
letter-spacing: 0px;
color: #2C2C2C;
opacity: 1;
}


.Company-member-subheading{
    top: 196px;
left: 280px;
width: 374px;
height: 23px;
color: var(--unnamed-color-2c2c2ccc);
text-align: left;
font: normal normal normal 18px/28px Assistant;
letter-spacing: 0px;
color: #2C2C2CCC;
opacity: 1;
}


.button-invite-team{
    width: 186px;
    height: 48px;
box-shadow: 0px 10px 30px var(--unnamed-color-2c2c2c14);
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 10px 30px #2C2C2C14;
border: 1px solid #1E326480;
border-radius: 4px;
opacity: 1;
margin-right: 26px;
border: 2px solid #2C2C2C14;
margin-top: 12px;

}

.Invite-Team{
    color: #1E3264;
text-align: center;
font: normal normal 600  15px/32px Assistant;
letter-spacing: -0.96px;
color: #1E3264;
opacity: 1;
}


.subcrption-table{
    top: 259px;
    left: 280px;
    width: 85%;
    height: 77px;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 30px var(--unnamed-color-2c2c2c14);
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 30px #2C2C2C14;
    border: 1px solid #2C2C2C1A;
    border-radius: 4px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    margin: 25px 45px;
    }
    
    .service-heading{
        background-color: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;;
        width: 100%;
        /* border: 1px solid #2C2C2C14; */
        display: flex;
        justify-content: center;
        align-items: center;
        
    }
    
    .E9-text-member{
        color: var(--unnamed-color-1e3264);
        text-align: center;
        font: normal normal bold 18px/24px Assistant;
        letter-spacing: -0.18px;
        color: #1E3264;
        text-transform: capitalize;
        opacity: 1;
        width: 100%;
        /* background-color: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box; */
        border: 1px solid #2C2C2C14;
        margin-top: 25px;
        height: 75px;
        margin-top: 0px;
        padding-top: 26px;
        border: 1px solid #2C2C2C1A;
    }

    .table-heading{
        background-color: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    width: 85%;
    height: 100%;
    border: 1px solid #2C2C2C14;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 10px 30px #2C2C2C14;
    
    /* margin: 23px 44px */
    }



    .E9-text-table-member{
        color: var(--unnamed-color-1e3264);
        text-align: center;
        font: normal normal bold 18px/24px Assistant;
        letter-spacing: -0.18px;
        color: #1E3264;
        text-transform: capitalize;
        opacity: 1;
        width: 100%;
        border: 1px solid #2C2C2C14;
        margin-top: 25px;
        height: 75px;
        margin-top: 4px;
        padding-top: 26px;
        border: 1px solid #2C2C2C1A;
    }


    .E9-text-companyDetail{
        color: var(--unnamed-color-1e3264);
        text-align: center;
        font: normal normal bold 18px/24px Assistant;
        letter-spacing: -0.18px;
        color: #1E3264;
        text-transform: capitalize;
        opacity: 1;
        width: 100%;
        background-color: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
        /* border: 1px solid #2C2C2C14; */
        margin-top: 25px;
        height: 75px;
        margin-top: 0px;
        /* padding-top:  12px;
        padding-left: 10%; */
        padding-top: 26px;
        }
        
        
        .subcrption-table-company{
            top: 259px;
            left: 280px;
            width: 85%;
            /* height: 77px; */
            background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
            box-shadow: 0px 10px 30px var(--unnamed-color-2c2c2c14);
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 0px 10px 30px #2C2C2C14;
            /* border: 1px solid #2C2C2C1A; */
            border-radius: 4px;
            opacity: 1;
            display: flex;
            flex-direction: row;
            margin: 25px 45px;
        
        }





    
    
    
  
.sidebar-container {
  gap: 1em;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.scrollable-sidebar {
  flex-grow: 1;
  overflow-y: auto;
  padding: 1em 0.75em;
}

.version {
  text-align: center;
  padding: 1em 0.75em;
  color: #2c2c2c95;
  font-weight: 600;
  background-color: #f7f7f7;
  border-top: 1px solid #e0e0e0;
}

.listparent {
  display: flex;
  flex-direction: row;
  height: 46px;
  overflow-y: auto;
  gap: 12px;
  align-items: center;
  height: 60px;


  .list-icon,
  .list-icon-hover {
    margin-top: 5px;
    margin-left: 3px;
    color: #fffbf5;
  }

  .icon-name {
    color: #000000;
    font-family: "Assistant";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    width: 180px;
  }

  .list-icon {
    display: none;
  }
}

.listparent:hover {
  background-color: #fffbf5;
}

.listparent:hover .icon-name {
  color: #f79c00;
  font-weight: 600;
}

.listparent:hover .list-icon-hover {
  display: none;
}

.listparent:hover .list-icon {
  display: block;
}


.parent {
    /* ... existing styles ... */
    cursor: pointer;
  }
  
  /* .parent.expanded {  */
    /* background-color: #f0f0f0; */
  /* } */
  
  .additional-options {
    padding-left: 50px;
    color: #000000;
    font-family: "Assistant";
    font-style: normal;
    font-weight: 400;
    font-display: swap;


  }

  .options{
    height: 45px;
    margin-top: 8px;
  }
  .additional-options div:hover {
    background-color:#fffbf5 ;
    color: #f79c00;
  }

  .expand-icon-property{
    /* margin-left: 48px; */
  }

  .Delete-icon{
    display: flex;
    justify-content: center;
  }

  .logout-section{
    display: flex;
     flex-direction: row;
     margin: 23px 23px;
     justify-content: space-evenly
  }


  .logoutbutton{
    background-color: rgb(198, 199, 200);
    width: 95px;
    height: 45px;
    border-radius: 5px;
  }

  .logoutbutton:hover{
    background: linear-gradient(90deg, var(--primary), var(--tertiary));
    color: #ffffff;
  }
.radio-button{
    height: 52px;
    width: 178px;
    background: #2C2C2C05;
    display: flex;
    justify-content: center;
    align-self: center;
    border: "1px solid #2C2C2C14";
    transition: box-shadow 0.3s, border-color 0.3s;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer
}
.active{
    background: #FFFFFF;
    
}
/* // onMouseEnter={(e) => {
                      //   e.currentTarget.style.boxShadow =
                      //     "0px 2px 6px rgba(44, 44, 44, 0.08";
                      //   e.currentTarget.style.borderColor = "#F79C00";
                      // }}
                      // onMouseLeave={(e) => {
                      //   e.currentTarget.style.boxShadow =
                      //     "0px 2px 4px rgba(0, 0, 0, 0.1)";
                      //   e.currentTarget.style.borderColor = "#FFFFFF";
                      // }} */
.welcome-material-layout{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 23px;
    margin: 8px 14px;
    margin-bottom: 18px;
}
.step-layout{
    display: flex;
    flex-direction: row;   
    justify-content: space-between;
    margin: 8px 22px;
}

.icon-text{
    display: flex;
    flex-direction: row;  
    gap: 12px;    
}

.add-text-material-layout{

}

.add-text-material-subheading{
width: 250px;
color: #333;
font-family: Assistant;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.2px;
}

.step1-heading{
color: #333;
font-family: Assistant;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
}


.Next-button-welcome {
    display: flex;
    width: 128px;
    height: 44.841px;
    padding: 16px 36px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 6px;
    background: rgb(198, 199, 200);
    /* background: var(--E9-Gradient, linear-gradient(296deg, #f79c00 23.18%, #fed401 94.52%) );} */}

    .Next-button-welcome:hover{
        display: flex;
        width: 128px;
        height: 44.841px;
        padding: 16px 36px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        border-radius: 6px;
        /* background: rgb(198, 199, 200); */
       background: var(--E9-Gradient, linear-gradient(296deg, #f79c00 23.18%, #fed401 94.52%) );
    }
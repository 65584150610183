.show-details{
   background-color: #f2f2f2;
   height: 35px;
   width: 70px; 
   border: 0.5px solid  #000000;

}

.show-details-text{
    justify-content: center;
    text-align: center;
    padding-top: 6px;
}

.get-country-state-data{
    
    /* background-color: red; */
    display: flex;
    flex-direction: row;
    width: 85%;
    height: 45px;
    justify-content: space-around;
    padding: 13px;
    margin-top: -15px;

}

.get-verify-license{
    /* display: flex;  */
    flex-basis: calc(33.33% - 20px);
    /* flex-direction: column; */
    height: 195px;
    width: 335px;
    border: 1px solid var(--light-50, #E3E5E8);
    border-radius: 4px;
    opacity: 1;
    margin-top: 36px;
    padding-top: 36px;
    padding-left: 36px;
    margin: 23px 28px;
    border-radius: 10px;
    box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10);
}

.get-expirence-data{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    /* background-color: red; */
    width: 85%;
    gap:7px;
    
}

.hero-document-additional{
    color: var(--dark-70, #1A1E21);
    /* Body 3/Regular */
    font-family: Assistant;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.6px;
  }

  
  .value-document-name-additional{
    color: var(--dark-70, #1A1E21);
    /* H4/Bold */
    font-family: Assistant;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.16px;
  }

  .hero-additioonal-details{
    color: var(--Light-Blue, #4A5A82);
    font-family: Assistant;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.24px;
    width: 373px
  }
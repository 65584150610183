.dashboard-fallback-card {
  border: 1px dashed #002961;
  display: flex;
  justify-content: center;
  max-width: calc(100vw - 240px);
  width: 95%;
  border-radius: 8px;

}

.dashboard-fallback-card__description {
  text-transform: uppercase;
  font-size: 32px;
  color: #002961;
  font-weight: 600;
}

.blog-blog{
    height: 2.5px;
    width: 100%;
    background-color: #F79C00;
}

.banner-hero-section-blog{
    width: 100vw ;
    height: 70vh
}

.blog-container{
    color: #FBFBFC;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 7px;
    padding: 1em 1em;
    height: 50vh;
    width: 100vw;
    /* border-bottom: 3px solid #F79C00; */
    background: linear-gradient(90deg, #28292B 0%, #484B4D 50%, #212529 100%);
}
.blog-item > :nth-child(even) {
    flex-direction: row-reverse;
    background: linear-gradient(120deg, #28292B 0%, #7A7C7F 50%, #28292B 100%);
}

.blog-body-heding{
    padding: 1em 1em ;
    display: flex ;
    flex-direction :column ;
    gap:2em ;
    height: 100%;
}
.blog-body-heding-text h1 {
 
        font-size: 40px ;
        font-weight: 700 ;
        text-transform : uppercase;
        color:var(--primary)
}
.blog-body-heding-text h2{

        font-size: 36px ;
        line-height: 1em;
}

.blog-body-heding-description h3{
    font-size: 22px;
}


.blog-container img{
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.blog-body-heding{
    flex : 0 70% ;
}
.blog-body-img{
 flex: 0 30%;
 height: 100% ;
 width: 100% ;
 display: flex ;
 flex-direction: column;
 align-items: center ;
justify-content: center;
}

@media screen and (max-width: 768px) {
    .banner-hero-section-blog {
        height: 50vh; /* Adjust height for smaller screens */
    }

    .blog-container {
        height: auto; /* Adjust height for smaller screens */
        flex-direction: column-reverse;
        gap: 10px;
        padding: 10px;
    }

    .blog-body-heding {
        padding: 1.5em;
    }

    .blog-body-heding-text h1 {
        font-size: 32px;
    }

    .blog-body-heding-text h2 {
        font-size: 28px;
    }

    .blog-body-heding-description h3 {
        font-size: 18px;
    }

    .blog-body-heding,
    .blog-body-img {
        flex: 1;
        width: 100%;
        gap: 1em;
    }
}
.hero-heading{
    margin:23px 23px
}





.hero-company-heading{
top: 153px;
left: 280px;
width: 305px;
height: 42px;
color: var(--unnamed-color-2c2c2c);
text-align: left;
font: normal normal bold 32px/38px Assistant;
letter-spacing: 0px;
color: #2C2C2C;
opacity: 1;

}

.hero-company-subheading{
    top: 203px;
    left: 280px;
    width: 374px;
    height: 23px;
    /* UI Properties */
    color: var(--unnamed-color-2c2c2ccc);
    text-align: left;
    font: normal normal normal 18px/28px Assistant;
    letter-spacing: 0px;
    color: #2C2C2CCC;
    opacity: 1;
}


.item-block{
    top: 297px;
left: 280px;
/* width: 1119px; */
/* height: 66px; */
background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
box-shadow: 0px 10px 30px var(--unnamed-color-2c2c2c14);
border: 1px solid var(--unnamed-color-f79c00);
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 10px 30px #2C2C2C14;
/* border: 1px solid #F79C00; */
border-radius: 4px;
opacity: 1;
margin: 23px 23px;
display: flex;
flex-direction: row;
transition: border 0.3s ease
}

.item-block:hover,
.item-block:active,
.item-block:focus {
    border: 1px solid #F79C00;
    /* transition:  #F79C00 0.1s ease */
}

.hero-item-heading{
    top: 317px;
left: 322px;
width: 222px;
height: 26px;
color: var(--unnamed-color-2c2c2c);
text-align: left;
font: normal normal 600 20px/26px Assistant;
letter-spacing: -0.6px;
color: #2C2C2C;
opacity: 1;
margin: 23px 23px;
}

.icon-hero{
    margin-left: 74%;
    margin-top: 29px;
}

.material-marketplace-card{
    width: 100%;
    height: 100%;
    /* border: 1px solid; */
    border-radius: 1em;
}

.material-marketplace-card__section{
    height: 60%;
    border-radius: 8px;
    position: relative;
}

.material-marketplace-card__image-overlay{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 8px;
}

.material-card__visibility-badge{
    border-radius: 8px;
    background-color: rgba(0,0,0,0.5);
    text-align: center;
    padding: 5px 15px 7px 13px;
    margin-top: 1em;
    margin-left: 0.5em;
}

.material-card__visibility-badge__text{
    color: #fff;
    font-size: 14px;
    border-top-right-radius: 8px;
}

.material-card__verification-badge{
    border-radius: 1em;
    background-color: rgba(0,0,0,0.5);
    text-align: center;
    padding: 0.15em 0.5em;
    min-width: 100px;
    max-width: 135px;
    max-height: 28px;
    min-height: 28px;
    margin: 0.5em 0.5em;
}

.material-card__verification-badge__text{
    color: #fff;
     font-size: 14px;
}

.material-card__details-section{
    border-radius: 8px;
    background: radial-gradient(#FFF ,#EAEAEA);
    padding: 5px 15px 7px 13px;
    width: 90%;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.3);
    margin: 0 auto;
    font-weight: 500;
    text-align: center;
}

.material-card__details-section__text{
    color: #002961;
    font-size: 12px;
}

.material-card__section--image{
    height: 95%;
     border-radius: 8px; 
}

.material-card__section--image__image{
    height: 100%;
     border-radius: 8px;
     width: 100%;
}

.material-card__image--blur{
    filter: blur(0.2em);
}
.material-card__original{
    border: 2px solid rgb(241, 144, 32);
    text-align: center;
    height: 30px;
    width: 93px;
    border-radius: 10px;
    position: absolute;
    background: rgb(0, 0, 0);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1em;
}
.material-card__contact-button {
    padding: 0 8px;
    border-radius: 4px;
    font-weight: 700;
    border: 1px solid #319f00;
    color: #319f00;
    white-space: nowrap;
}
.material-card__contact-button.contact-seller {
    border-color: #f67c00;
    color: #f67c00;
}
.material-card__contact-button.my__asset {
    border-color: #002961;
    color: #002961;
}

.material-card__contact-button.rejected {
    border: none;
    color:  #d60000;
    cursor: auto;
}

.material-card__contact-button.accepted {
    border: none;
    color:  #319f00;
    cursor: auto;
}
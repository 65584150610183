.terms {
  padding: 0 10%;
  font-family: "Assistant";
  font-style: normal;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.01em; 
  background-color:var(--dark-40);
}

.terms-heading {
  color: #f79c00;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  padding: 23px;
}

.terms-content {
  margin-top: 3rem;
  font-family: "Assistant";
  font-style: normal;
  color: #ffffff;
  text-align: justify;
}

.terms-content h5 {
  font-family: "Assistant";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.01em;
  font: "Roboto";
}

.terms-link {
  margin-top: 1.5rem;

}

.terms-link a {
  text-decoration: none;
  color: var(--primary);
}

.rating-h5 {
  margin-top: 2rem;
}

.rating-h3 {
  font-family: "Assistant";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 42px;
  letter-spacing: 0.01em;
  margin-bottom: 1rem;
}

.collapsible {
  background-color: #4d5154;
  color: white;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  outline: none;
  font-size: 15px;
  border: 1px solid #fefefe;

  font-family: "Assistant";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #fefefe;
}

.content {
  padding: 15px 25px;
  display: none;
  overflow: hidden;
  background-color: #7a7c7f;

  font-family: "Assistant";
  font-style: normal;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #fefefe;
}

.collapse-container {
  border: 1px solid #fefefe;
  border-radius: 4px;
}

@media (max-width: 768px) {
  .terms-heading {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .terms-heading {
    padding-top: 2rem;
    font-size: 40px;
    line-height: 40px;
  }
}

.termsRepossive{
  padding: 0 10%;
  font-family: "Assistant";
  font-style: normal;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.01em;
  text-align: justify;
  word-wrap: normal;
  word-spacing: -1px; /* Adjust this value as needed */
}


.Terms-heroheading {
  font-size: 1.4rem;
  color: #FECA00;
  font-weight: 600;
  line-height: 1.2;
  width: 90%;
  justify-content: flex-start;
  margin-left: 9%;
}


.EquipmentHeadingdivision {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 13px;
}

.termOpratroHeading{
  font-size: 1rem;
  color: #FECA00;
  font-weight: 500;
  line-height: 1.2;
  justify-content: justify;

  word-wrap: normal;
  word-spacing: -1px; /* Adjust this value as needed */
}

.Mobilecollapsible {
  background-color: #4d5154;
  color: white;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  outline: none;
  font-size: 15px;
  border: 1px solid #fefefe;

  font-family: "Assistant";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #FECA00;
}

.resposive-terms {
  padding: 0 10%;
  font-family: "Assistant";
  font-style: normal;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.01em; 
   background: linear-gradient(90deg, #28292b 0%, #484b4d 50%, #212529 100%);
}
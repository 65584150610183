.my-business-main-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 56px;
  padding-right: 56px;
  padding-top: 21px;
}

.my-business-content-container {
  width: 50%;
}

.my-business-text-container {
  margin-bottom: 20px;
}

.my-business-text-heading {
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 2em;
}

.my-business-button-container {
  display: flex;
  align-items: flex-end;
  gap: 1.5em;
}

.my-business-card-container {
  display: flex;
  border: 1px solid #ccc;
  padding: 1em 1em;
  margin: 10px;
  max-width: 560px;
  max-height: 256px;
  height: 100%;
  width: 100%;
  gap: 1em;
  box-shadow: rgb(149, 149, 150) 0px 2px 4px 1px;
  border-radius: 8px;
}


.my-business-left-container {
  flex: 0 0 45%;
  max-height: 165px;
  max-width: 240px;
  position: relative;
}
.my-business-status{
  position: absolute;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1em 1em;
}

.my-business-card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.my-business-right-container {
  flex: 0 0 52%;
  padding-left: 10px;
  box-sizing: border-box;
}

.my-business-details-container {
  margin-bottom: 10px;
}

.my-business-detail {
  margin-bottom: 10px;
}

.my-business-heading {
  font-weight: bold;
}

.my-business-sub-heading {
  color: #888;
  font-family: Assistant;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.2px;
}

.my-business-buttons-container{
  display: flex;
  align-items: center;
  gap: 1.5em;
}
.my-business-button {
  color: #F19020;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.2px;
}
.my-business-action-btn{
  border-radius: 6px;
  border: 0.817px solid #F19020;
  display: flex;
  max-width: 175px;
  height: 35px;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.status-verification-badge{
  border-radius: 1em;
  background: rgba(214, 0, 0, 0.376);
  text-align: center;
  min-width: 100px;
  max-width: 135px;
  max-height: 28px;
  min-height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.status-new-btn{
  color: #1E3264;
  font-weight: 700;
  display: flex;
  width: 53px;
  height: 25px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: #FFF;
}






.job-marketplace-card{
    height: 100%;
    width: 100%;
    border-radius: 4px;
    padding: 0.35em 0.25em 0.35em 0.55em;
    cursor: pointer;
}

.job-marketplace-card__verification-badge-container{
    display: flex;
    justify-content: space-between;
    
}

.job-marketplace__container{
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.job-marketplace__text--dark{
    font-size: 16px;
    font-weight: 600;
    color: #767676;
}

.job-marketplace__text--light{
    font-size: 18px;
    font-weight: 700;
    color: #2c2c2c;


}

.job-marketplace-card__text--price{
    color: #1E3264;
    font-weight: 700;
    font-size: 18px;
}
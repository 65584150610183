.card-support{
    width: 100%; /* This ensures that the container takes full width */
    display: flex;
    flex-wrap: wrap;
    gap: 22px;
    margin: 22px;
  }


  .card-information-support{
    display: flex;
    flex: 1 1 48%; /* Adjust this to ensure two cards fit in a row */
    margin: 1%;
    border-radius: 10px;
   
    border: 0.2px solid var(--light-50, #E3E5E8);
    background: #FFF;
     /* width: 335px;
    height: 188px; */
    gap: 23px;
    flex: 0 0 calc(50% - 16px);
    /* margin: 38px; */
    box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10);
  }
  .added-date-and-verified-support{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 310px;
    margin: 5px 12px;
  }

  .support-description{
    width: 300px;
    /* height: 200px */
  }

  .support-description{
    margin: 12px 23px;
  }


  .support-heading-text{
    color: var(--dark-70, #1A1E21);
    font-family: Assistant;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.16px;
    margin-top: 12px;
    /* margin-left: 12px; */
  }


   .super-heading-name{
    color: var(--dark-70, #1A1E21);
    /* H4/Bold */
    font-family: Assistant;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.16px;
    margin-top: 12px;
    margin-left: 6px;
  }

  .expand-card{
    color: blue;
    font-family: Assistant;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.16px;
    margin-top: 12px;
    text-decoration-line:underline
  }


  .status-value{
    
  }
  
  
 
  
  
  

  
      
    
  
  
    
.teststepper {
  display: inline-flex;
  height: 55px;
  width: 95%;
  align-items: flex-start;
  gap: 1.865px;
  flex-shrink: 0;
  background: #fff;
  box-shadow: 0px 0px 5.3px 0px rgba(0, 0, 0, 0.25);
  margin: 3px 33px;
  margin-right: 23px;
}

.step1-layout {
  display: flex;
  width: 500px;
  height: 55px;
  padding: 0px 78.312px;
  justify-content: center;
  align-items: center;
  gap: 18.646px;
  color: #f79c00;
  font-family: Assistant;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.spare-part-detils-heading {
  color: var(--Dark-90, #141619);
  font-family: Assistant;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.28px;
}

.spare-part-detils-subheading {
  color: var(--Dark-10, #767676);
  font-family: Assistant;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.24px;
}

.formsection {
  margin: 8px 33px;
  height: 600%;
  width: 95%;
  /* background-color: red; */
  margin-right: 33px;
}

.bottom-bar-header {
  position:fixed;
  display: flex;
  bottom: 0%;
  width: 100%;
  height: 68px;
  justify-content: center;
  align-items: center;
  border-radius: 0px 0px 12px 12px;
background: var(--Light-10, #FEFEFE);
box-shadow: 0px -10px 15px 0px rgba(44, 44, 44, 0.16);
}

.button-layout-steps{
 display: flex;
 flex-direction: row;
 gap: 12px;
 margin-left: -365  px;

}

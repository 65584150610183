.NameofProductField {
  display: flex;
  flex-direction: column;
}

.form-container {
  display: flex;
  /* flex-direction: row;
    height: 600px; */
}

.fieldHeading {
  color: var(--Dark-90, #141619);
  font-family: Assistant;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.22px;
  /* margin-top: 12px; */
  margin-bottom: 12px;
  padding: 5px 12px;
  
}
.must{
  color: var(--Danger-50, #D60000);
font-family: Assistant;
font-size: 16px;
font-style: normal;
font-weight: 800;
line-height: normal;
letter-spacing: 0.22px;
margin-left: -10px;
}

.filed-input {
  display: flex;
  width: 486.778px;
  height: 59.193px;
  padding: 17.102px 14.659px;
  align-items: center;
  gap: 12.216px;
  border-radius: 4.886px;
  border: 1.222px solid var(--Light-70, #c6c7c8);
  background: var(--Light-30, #f5f5f5);
  margin-top: 2px;
}

.price-range-filed {
  display: flex;
  flex-direction: row;
  width: 483.778px;
  justify-content: space-between;
  gap: 12px;
  margin-top: 12px;
}

.filed-input-price-range {
  display: flex;
  width: 203.778px;
  height: 60.93px;
  padding: 17.102px 14.659px;
  gap: 12.216px;
  border-radius: 4.886px;
  border: 1.222px solid var(--Light-70, #c6c7c8);
  background: var(--Light-30, #f5f5f5);
  margin-top: 2px;
}

.equipment-type-layout {
  display: flex;
  flex-direction: row; /* Arrange children in a row */
  flex-wrap: wrap; /* Allow wrapping if the container gets too wide */
  min-width: 500px; /* Minimum width of the container */
  max-width: 550px; /* Maximum width of the container */
  width: auto; /* Auto width based on content */
  padding: 22.727px 18.182px;
  gap: 18.182px;
  border-radius: 9.091px;
  border: 1.136px solid var(--Light-50, #e3e5e8);
  margin-top: 32px;
  align-items: stretch;
}

.equipment-type-layout > div {
  flex-grow: 1; /* Allow flex items to grow */
  flex-shrink: 1; /* Allow flex items to shrink */
  flex-basis: calc(50% - 18.182px); /* Initial basis, accounting for the gap */
  /* Additional styles for child divs if needed */
}





.equipment-type-button {
  display: flex;
  width: 483px;
  height: 62.5px;
  padding: 11.364px 15.909px;
  justify-content: center;
  align-items: center;
  gap: 43.182px;
  align-self: stretch;
  border-radius: 4.545px;
  border: 1.136px solid var(--Light-50, #e3e5e8);
  background: #fff;
  flex-direction: row;

}

.For-any-Equipment-text {
  color: var(--Light-90, #959596);
  font-family: Assistant;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.159px;
  width: 100%;
  display: flex;
flex-direction: row;
margin-left: 32px;
}

.For-any-Equipment-text-any{
  color: #000000;
  font-family: Assistant;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.159px;
  /* width: 100%; */
  display: flex;
flex-direction: row;
margin-left: 16px;
}


.For-any-Equipment-text-any{
  color: #000000;
  font-family: Assistant;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.159px;
  /* width: 100%; */
  display: flex;
flex-direction: row;
}


.For-any-Equipment-text-make{
  color: #000000;
  font-family: Assistant;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.159px;
  /* width: 100%; */
  display: flex;
flex-direction: row;
margin-left: 53px;
}

.For-any-Equipment-anymake {
  color: var(--Light-90, #959596);
  font-family: Assistant;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.159px;
  width: 100%;
  display: flex;
flex-direction: row;
margin-left: 63px;
}


.For-any-Equipment-text-specific{
  color: #000000;
  font-family: Assistant;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.159px;
  /* width: 100%; */
  display: flex;
flex-direction: row;
}


.For-any-Equipment{
  color: #000000;
  font-family: Assistant;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.159px;
  width: 100%;
  display: flex;
flex-direction: row;
}


.For-any-Equipment-original {
  color: var(--Light-90, #959596);
  font-family: Assistant;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.159px;
  width: 100%;
  display: flex;
flex-direction: row;

}

.For-any-Equipment-option {
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 169px;
  /* gap: 12px; */
  /* Add additional styling */
}

.For-any-Equipment-option.selected {
  /* Styles for selected option, e.g., a background color, border, etc. */
}

/* Optional: Style for the cross icon */
.cross-icon {
  margin-left: 8px; /* Adjust as needed */
}

.yes-no-button {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
width: 400px;
height: 62.5px;
padding: 11.364px 34.091px;
gap: 43.182px;
border-radius: 4.545px;
border: 1.136px solid var(--Light-50, #E3E5E8);
background: #FFF;
}

.form-grid {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(300px, 1fr)
  ); /* Adapts number of columns based on the width */
  gap: 20px;
}

.grid-item {
  display: flex;
  flex-direction: column;
}

.Step-part-details-heading {
  margin: 12px 23px;
}

.step-2-spare-part-details-heading {
  color: var(--Dark-90, #141619);
  font-family: Assistant;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.28px;
}

.step-2-spare-part-details-subheading {
  color: var(--Dark-10, #767676);
  font-family: Assistant;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.24px;
}

.photo-galary {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 42px;
  /* background-color: red; */
}

.Product-photo-heroheading {
  color: #002961;
  font-family: Assistant;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.307px;
  text-transform: capitalize;
  margin-top: 11px;
}

.Product-photo-herobutton {
  display: flex;
  width: 94px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid #002961;
}

.Product-photo-buttontext {
  color: #002961;
  font-family: Assistant;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.22px;
}

.upload-type-photo {
  border: 1px solid var(--Light-50, #e3e5e8);
  background: var(--Light-30, #f5f5f5);
  width: 301px;
  height: 43px;
  padding: 3px 18px;
  margin: 12px 23px;
}

.upload-type-photo-image {
  border: 1px solid var(--Light-50, #e3e5e8);
  background: var(--Light-30, #f5f5f5);
  width: 301px;
  height: 43px;
  padding: 3px 18px;
  margin: 12px 25px;
}

.Material-pop-header-layout-Image {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: -32px;
}

.store-preview-image {
  height: 168px;
  width: 200px;
  /* background-color: red; */
}

.store-preview-videos {
  height: 168px;
  width: 200px;
  /* background-color: red; */
}

.image-tag {
  /* position: absolute; */
  width: 200px;
  height: 28px;
  text-align: center;
  display: flex;
  border-radius: 3px 3px 0px 0px;
  background: rgba(74, 74, 74, 0.41);
  backdrop-filter: blur(5px);
}

.image-preview {
  height: 168px;
  width: 200px;
}

.image-tag-delete {
  /* position: absolute; */
  width: 200px;
  height: 28px;
  text-align: center;
  display: flex;
  border-radius: 0px 0px 3px 3px;
  background: var(--Light-10, #fefefe);
  box-shadow: 0px -10px 15px 0px rgba(44, 44, 44, 0.16);
  border-radius: 3px;
  border: 2px solid #eee;
}

.Delete-text {
  text-align: center;
  margin-left: 40%;
}

.front-text {
  text-align: center;
  color: var(--Light-10, #fefefe);
  font-family: Assistant;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 23%;
}

.weight-section {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
    /* background-color: red; */
   margin-top: 23px;
  width: 490px;;
}

.Additional-details-price {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 486px;
  /* background-color: red; */
  margin-top: 43px;
  margin-bottom: 12px;
}

.Additional-details-text {
  color: var(--Dark-90, #141619);
  font-family: Assistant;
  font-size: 24.923px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.249px;
}

.additional-details-textarea {
  width: 490px;
  height: 140px;
  border-radius: 5.538px;
  padding: 19.385px 16.615px;
  border: 1.385px solid var(--Light-70, #c6c7c8);
  background: var(--Light-30, #f5f5f5);
}


.no-of-char{
  color: var(--Secondary-90, #002961);
font-family: Assistant;
font-size: 16.615px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.831px;
margin-top: 5px;
}

.dimension-input-field{
  width: 332.308px;
padding: 8.308px 12px;
align-items: center;
gap: 83.077px;
align-self: stretch;
border-radius: 5.538px;
border: 1.385px solid var(--Light-50, #E3E5E8);
background: var(--Light-30, #F5F5F5);
}

.dimension-input-field-2{
  display: flex;
width:84px;
padding: 8.308px 0px;
margin-left: 12px;
gap: 200px;
border-radius: 5.538px;
border: 1.385px solid var(--Light-50, #E3E5E8);
background: var(--Light-30, #F5F5F5);
}

.button-submit-step2{
border-radius: 0px 0px 12px 12px;
background: var(--Light-10, #FEFEFE);
box-shadow: 0px -10px 15px 0px rgba(44, 44, 44, 0.16);
display: flex;
width: 100%;
height: 89px;
padding: 8px 0px;
justify-content: center;
align-items: center;
gap: 50px;
flex-shrink: 0;
position: fixed;
bottom: 0%;
margin-left: -21px;
/* left: 0%; */
}

.no-thanks-step2{
  display: flex;
width: 144px;
height: 53px;
padding: 16px 36px;
justify-content: center;
align-items: center;
gap: 10px;
flex-shrink: 0;
border-radius: 6px;
background: #FFF;
box-shadow: 0px 1px 5.3px 0px rgba(0, 0, 0, 0.25);
color: var(--Light-90, #959596);
font-family: Assistant;
font-size: 15px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.24px;
/* margin-right: 12px; */
}

.submit-step2{
  display: flex;
  width: 144px;
  height: 53px;
  padding: 16px 36px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 6px;
opacity: 0.78;
background: var(--E9-Gradient, linear-gradient(296deg, #F79C00 23.18%, #FED401 94.52%));
color: var(--Light-10, #FEFEFE);
font-family: Assistant;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.28px;
}

.image-tag-delete{
  padding-left: 12px;
}


.front-text-videos {
  text-align: center;
  color: var(--Light-10, #fefefe);
  font-family: Assistant;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 32%;
}

.material-error-boundry{
  /* background-color: red; */
  height: auto;
  display: flex;
  flex-direction: row;
  width: 38%;
  justify-content: space-between;
}

.material-error-text-boundry{
  color: red;
  font-family: Assistant;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.24px;
  margin-top: 7px;
  /* text-transform: uppercase; */
}

.material-error-boundry-dimension {
  /* background-color: red; */
  height: auto;
  display: flex;
  flex-direction: row;
  width: 94%;
  justify-content: space-between;}


  .name-of-product-number{
    display: flex;
    flex-direction: row;
    gap:42px;
    /* justify-content: space-between;
    margin: 0px 53px; */
  }


  .bottom-bar-header-step1 {
    right: -14.7%;
    position: fixed;
    display: flex;
    bottom: 0%;
    width: 99%;
    height: 68px;
    justify-content: center;
    align-items: center;
    border-radius: 0px 0px 12px 12px;
    background: var(--Light-10, #FEFEFE);
    box-shadow: 0px -10px 15px 0px rgba(44, 44, 44, 0.16);
}


.button-layout-step1 {
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin-left: -294px;}


  .add-video-placeholder-tag{
    margin-top: 64px;
    display: flex;
    justify-content: center;
  }


.material-part-detail{
color: var(--Dark-90, #141619);
font-family: Assistant;
font-size: 28px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 0.28px;
  }

  .material-part-detail-subhero{
    color: var(--Dark-10, #767676);
    font-family: Assistant;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.24px; 
    margin-bottom: 23px;
  }


/* 
  App_theme: "#F79C00",
  LOADING: "#0c9",
  WHITE: "rgba(255, 255, 255, 1)",
  LIGHT_WHITE: "#dfdfdf",
  FAINT_WHITE: "#efefef",
  BORDER_GRAY: "#a7a7a7",
  BLACK: "#000000",
  FAINT_BLACK: "rgba(0,0,0,0.5)",
  MODAL_BLACK: "#000000aa",
  DARKGRAY: "#999",
  FAINT_ORANGE: "#FAA300",
  HEADER_ORANGE: "#fba301",
  PRIMARY_COLOR: "#FAA300",
  FAINT_PRIMARY_COLOR: "#fec500",
  SECONDARY_COLOR: "#fba301",
  FAINT_SECONDARY_COLOR: "#f7ad00",
  DARK_GREEN: "#216c2a",
  GREEN: "#18c207",
  LIGHT_GREEN: "#1ce019",
  FAINT_GREEN: "#00cb4c",
  LIGHT_BLUE: "#6EA8DA",
  BLUE: "#1e3264",
  DARK_BLUE: "#0F3274",
  RED: "red",
  REQUIRED_RED: "#D60000",
  REQUIRED_BORDER_RED: "#D60000CC",
  LIGHT_RED: "#ff0000",
  FAINT_RED: "#eb1c1c",
  HEADER_RED: "#bc0000",
  VERY_DARK_GRAY: "#999",
  DARK_GRAY: "#606070",
  MEDIUM_GRAY: "#808080",
  FAINT_GRAY: "#cdcdcd",
  LIGHT_GRAY: "#8d8d8d",
  VERY_LIGHT_GRAY: "#c3c3c3",
  TRANSPARENT_GRAY: "rgba(52, 52, 52, 0.3)",
  TRANSPARENT_LIGHT_GRAY: "rgba(52, 52, 52, 0.2)",
  TRANSPARENT_WHITE_GRAY: "rgba(255, 255, 255, 0.6)",
  SHADOW_GRAY: "#c1c1c1",
  light_background: "#2C2C2C05",
  light_borderColor: "#2C2C2C14",
  border_light: "#2C2C2C1A",
  light_background_main: "#FCFCFC",
  text_color_dark: "#2C2C2C",
  EARLY_DAWN: "#FEF8EC",
  EARLY_DAWN_LIGHT: "#FEFBF5",
  OFF_FOCUS_INPUT_COLOR: "#2C2C2C14",
  SUCCESS_GREEN: "#319F00",
  INPUT_PLACE_HOLDER: "#2C2C2C20",
  default_blue: "#1E3264",
  bg_user_data: "#F79C001A",
  BADGE_EXPIRED: "#929292",
  RATING_BADGE_BG: "#FED401",
  STICK_BADGE_BG: "#0068F7",
  FAINT_ORANGE: "#FFF5E080",
  BRIGHT_BLUE: "#004BB2",
  // =========================
  SHADOW_COLOR: Platform.OS == "ios" ? "#2C2C2C30" : "#2C2C2C",
  BORDER_COLOR: "#2C2C2C20",

  // ============ STEP COLOR =============
  STEP_PROGRESS_COLOR: "#F79C00",
  STEP_PROGRESS_LABEL_COLOR: "#707070",
  TEXT_GRAY: "#BFBFBF",
  TEXT_BLUE: "#001F7B",
  MODAL_CROSS_COLOR: "#2C2C2C40",
  TEXT_LIGHT_PLACEHOLDER: "#C6C7C8",

  DARK_GRAY: "#C6C7C8",
  SEMI_TRANSP_GREEN: "#319F0080",
  SEMI_TRANSP_RED: "#9F000080",
}; */



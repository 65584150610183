.material-header-card-container {
  width: 100%;
  display: flex;
  overflow: hidden;
  box-shadow: 3px 7px 23px 1px #2c2c2c25;
  border-radius: 10px ;
  background: #FFF;
  align-items: center;
  }
.material-header-card-img{
    max-height: 300px;
    max-width: 630px;
    border-radius: 4px;
}
.material-body-card-container{
    display: flex;
    padding: 10.56px 60px;
    width: 100%;
    height: 100%;
}
.material-body-card-container-left{
  display: flex;
  flex-direction: column;
  gap:0.5em;
  justify-content: center;
  width: 100%;
}

.material-body-text1{
  font-size: 28px;
  font-weight: 700;
  color: #333333;
}
.material-body-text2{
  font-size: 24px;
  font-weight: 700;
  color: #002961;

}
.material-body-text3{
  font-size: 24px;
  font-weight: 700;
  color: #333333;


}

.material-body-card-container-right{
  display: flex;
  flex-direction: row;
  align-items: start;
}

  .material-text {
    color: #000;
    font-family: Assistant;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.24px;
  }
  .material-text1 {
    color: #000;
    font-family: Assistant;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.24px;
  }
  .material-text2 {

    color: #F79C00;
    font-family: Assistant;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.24px;
  }
  .material-text3 {
    color: #000;
    font-family: Assistant;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.24px;
  }

  .material-btn{
    
    margin: 5px;
    padding: 0.25rem 0.75rem;
    border-radius: 20%;
    height: 45px;
    width: 45px;
  }

  .backgroung-delete{
    background-color: #FBF5F5;
  }
  .backgroung-edit{
    background-color: #F7FAF5;
  }
  
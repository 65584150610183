.Not-Verfied-tag {
  justify-content: center;
  align-self: center;
  display: flex;
  height: 45px;
  width: 100%;
  margin-left: 1px;
  background-color: #D60000;

  .Verifi-tex {
    color: white;
    font-family: "Assistant";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    margin-top: 10px;
  }
  .Verfied-tag.not-verified {
    background-color: #D60000;
  }
  
  .Verfied-tag.verified {
    background-color: green
  }
  
}

.Verfied-tag {
  justify-content: center;
  align-self: center;
  display: flex;
  height: 45px;
  width: 100%;
  margin-left: 1px;
  background-color: green;

  .Verifi-tex {
    color: white;
    font-family: "Assistant";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    margin-top: 10px;
  }
  .Verfied-tag.not-verified {
    background-color: #D60000;
  }
  
  .Verfied-tag.verified {
    background-color: green
  }
  
}   


  .verification-details{
    display: flex;
    flex-direction: row ;
    gap: 13px;

    .your-verification-details{
        font: normal normal bold 24px/32px Assistant;
        letter-spacing: -0.72px;
        color: #1E3264;
        text-transform: uppercase;
        opacity: 0.7;
        margin-left: 23px;
        margin-top: 13px;
       
    }
    .seprator-hr{
        height: 60%;
    margin-top: 27px;
    /* background-color: red; */
    border-top: 3px solid #bbb;
    width: 65%;
    /* margin-left: 23px; */
}

  }

  .add-verificat{
    width: 120px;
    height: 34px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 30px var(--unnamed-color-2c2c2c14);
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 30px #2C2C2C14;
    border: 1px solid #1E326480;
    border-radius: 4px;
    opacity: 1;
    /* margin-left:-3px; */
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;


    .add-verification-detail{
color: var(--unnamed-color-1e3264);
/* text-align: center; */
font: normal normal 600 12px/12px Assistant;
font-weight: 700;
/* letter-spacing: -0.96px; */
color: #1E3264;
/* opacity: 1; */
    }
}
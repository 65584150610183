
.popup-card-over {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 101;
    
  }
  
  
  .popup-carddd {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    gap: 10px;
    max-width: 400px;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
   
   
  }
  
  
  .popup-carddd-close {
   text-align: right;
    cursor: pointer;
  }
  
  .form-group-main{
    margin-top: 30px;
  }
  
  .popup-carddd h1 {
    font-size: 24px;
    margin-top: 0;
    text-align: center;
    font: normal normal bold 26px/34px Assistant;
    letter-spacing: 0px;
    color: #1E3264;
    opacity: 1;
    margin: 1%;
  }
  
  
  .popup-carddd .form-group {
    margin-bottom: 20px;
  }
  
  
  .popup-carddd label {
    display: block;
    
    font: normal normal 600 18px/23px Assistant;
    letter-spacing: -0.54px;
    color: #2C2C2C;
    text-transform: capitalize;
    opacity: 0.8;
  }
  
  
  .popup-carddd .required {
    color: red;
    margin-left: 5px;
  }
  
  
  
  
  
  .file-input-container-education {
    position: relative;
    width: 100%;
    height: auto;
    text-align: center;
    margin-top: 5px;
  }
  
  
  .custom-file-button {
   
    
    padding: 10px 20px;
    cursor: pointer;
    display: inline-block;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 30px #2c2c2c14;  
    border: 1px solid #1E326480;
    border-radius: 4px; 
    text-align: center;
    font: normal normal 600 24px/32px Assistant;
    letter-spacing: -0.96px;
    color: #1E3264;
  }
  
  
  .custom-file-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 44px;
    opacity: 0;
    cursor: pointer;
    border-radius: 5px;
  }
  
  
  .popup-carddd input[type="text"],
  .popup-carddd select {
  width: 100%;
  height: 52px;
  background: #2C2C2C05 0% 0% no-repeat padding-box;
  border: 1px solid #2C2C2C14;
  border-radius: 4px;
  opacity: 1;
  }
  
  
  .custom-file-input-container {
    position: relative;
  }
  
  
  .custom-file-input {
    display: inline-block;
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    cursor: pointer;
    text-align: center;
  }
  
  
  .custom-file-input span {
    display: inline-block;
    line-height: 1.5;
  }
  
  
  .custom-file-input input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
  
  
  
  .popup-carddd button[type="submit"] {
    /* background-color: #007bff; */
    width: 100%;
    height: 45px;
    color: #fff;
    /* border: none; */
    /* border-radius: 5px; */
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  
    background: transparent linear-gradient(274deg, #F79C00 0%, #FED401 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 20px #F79C004D;
    border-radius: 4px;
    opacity: 1;
  }
  
  .popup-carddd button[type="submit"]:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
.error-message {
      color: red;
      font-size: 12px;
      margin-top: 5px;
    }


    .certificate-icon{
      display: flex;
      justify-content: center;
    }
    

.card-layout{
    display: flex;
    flex-wrap: wrap;
      /* justify-content: space-between; */
    .get-verify-card{
      display: flex;
      /* flex-basis: calc(33.33% - 20px); */
      flex-direction: row;
      /* height: 245px; */
      width: 335px; 
      border: 0.5px solid var(--light-50, #E3E5E8);
      border-radius: 4px;
      opacity: 1;
      /* margin-top: 36px; */
      /* padding-top: 36px;
      padding-left: 36px; */
      margin: 23px 28px;
      border-radius: 10px;
  /* border: 1px solid var(--light-50, #E3E5E8); */
  box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10);
  .get-document-name{
      color: var(--unnamed-color-2c2c2c);
      /* text-align: left; */
      font: normal normal bold 13px/22px Assistant;
      letter-spacing: -0.96px;
      color: #2C2C2C;
      opacity: 1;
      /* display: flex;
      flex-direction: row;
      justify-content:flex-start */
  }
  .get-document-number{
      /* color: var(--unnamed-color-2c2c2c); */
      text-align: left;
      font: normal normal bold 13px/22px Assistant;
      letter-spacing: -0.96px;
      color: #2C2C2C;
      opacity: 0.6;
      padding-top: 12px
      
  }

  }

  .get-verify-card.expanded{

    height: auto;

  }
  }

  .get-icon{
    margin-left: 7px;
    margin-top: 7px;

  }

  /* ..expand-card{
    
  } */
  
  
  .get-delete-doc{
      /* background-color: yellow; */
      cursor: pointer;
      margin-top: 11px;
      margin-left: 153px;
  }
  
  .Doc-support-block{
      width: 95%; 
      margin-left:10%;
  }
  
  .custom-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    z-index: 1000;
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Center the modal in the viewport */
  }
  
  
  /* Add this CSS to your GetVerifyDetails.css file or equivalent */
  
  .modal-backdrop{
    position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.7); /* This will make the background dark with 70% opacity */
      z-index: 998;
  }
  .confirmation-dialog {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: var(--light-10, #FEFEFE);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      padding: 20px;
      border-radius: 5px;
      z-index: 999;
      text-align: center;
    }
    
    .confirmation-dialog p {
      margin: 0 0 20px;
    }
    
    .confirmation-dialog button {
      margin: 0 10px;
      padding: 10px 20px;
      border: none;
      cursor: pointer;
      font-size: 16px;
      background: var(--E9-Gradient, linear-gradient(296deg, #F79C00 23.18%, #FED401 94.52%));
      color: #fff;
      border-radius: 5px;
      outline: none;
    }
    
    .confirmation-dialog button:hover {
      background: var(--E9-Gradient, linear-gradient(296deg, #F79C00 23.18%, #FED401 94.52%));
      transform: translate(2px);
    }
    
    .confirmation-dialog button:last-child {
      background-color: #ccc;
    }
    
    .confirmation-dialog button:last-child:hover {
      background-color: #999;
    }
  
    /* Add this CSS in your GetVerifyDetails.css file or a separate CSS file */
  
  .document-popup-container {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.7);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;
    }
    
    .document-popup {
      background-color: #fff;
      padding: 20px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
      max-width: 80%;
      max-height: 80%;
      overflow: auto;
      position: relative;
    }
    
    .document-popup img {
      max-width: 100%; /* Adjust this to control the maximum width of the image */
      max-height: 100%; /* Adjust this to control the maximum height of the image */
      display: block;
      margin: 0 auto;
    }


    
  .Edit-and-delete{
    display: flex;
    flex-direction: row;
    gap: 23px;
    /* margin-left: -109px;
    margin-right: -24px;
    justify-content: flex-start; */
  }

    
    .close-button {
      /* background-color: #f44336; */
      color: #000000;
      border: none;
      padding: 10px 20px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
      margin: 10px 0;
      cursor: pointer;
      position: absolute;
      top: 10px;
      right: 10px;
    }
  
  
    .get-document-viewDetail{
      display: flex;
      flex-direction: row;
      gap: 31px;
      margin-top: -62px;
      margin-left: 55px;
    }
    .view-document-icon{
      margin-top: 12px;
    }
    .view-document-detai{
      color: var(--secondary-70, #004BB2);
      font-family: Assistant;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 7px;
  
    }
  
    .conformation-text{
      text-align: left;
      font: normal normal 18px/20px Assistant;
      letter-spacing: -0.96px;
      color: #2C2C2C;
      opacity: 1
    }
  
    .hero-document{
      color: var(--dark-70, #1A1E21);
      /* Body 3/Regular */
      font-family: Assistant;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.6px;
      width: 230px;
    }
  
    .value-document-name{
      color: var(--dark-70, #1A1E21);
      /* H4/Bold */
      font-family: Assistant;
      font-size: 12px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      letter-spacing: 0.16px;
      width: 89%;
      height: 22px;
      /* margin-right: 23px; */
    }

    .added-delete-verified{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 311px;
      margin: 7px 1px;

    }


    .doc-support{
      flex:content
    }

    .card-layout .expand-card-work {
      max-height: 256px;
      overflow-y: auto; /* This will allow the content inside the div to be scrollable if it doesn't fit */
      transition: max-height 0.3s ease-in-out; /* Optional: This will animate the transition */
  }
  
  .card-layout .expand-card.expanded {
      max-height: 100%; /* Or you could set a specific value here if you know the expanded height */
  }

  .check-current-compnay{
    color: #000000;
  }

  .space-given{
    margin-top: 8px;
  }

  .work-detail-error{
 display: flex;
 justify-content: center;
 margin-top: 64px;
 margin: 45px 40%;
 color: #999;
 font-family: Assistant;
 font-size: 22px;
 font-style: normal;
 font-weight: 600;
 line-height: normal;

  }


  

  
  
    
    
/* MobileCarousel.css */
.carousel-container {
  height: 34vh;
  width: 57vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 23vw;
  object-fit: fit;
  }
  
  .carousel-slide {
    display: flex;
    transition: transform 0.5s ease-in-out;
  }
  
  .carousel-image {
    width: 100%; 
    flex-shrink: 0; 
    object-fit: fill; 
  }
  
  .carousel-dots {
    text-align: center;
    padding: 10px 0;
  }
  
  .dot {
    display: inline-block;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background-color: gray;
    margin: 0 5px;
    cursor: pointer;
  }
  
  .dot.active {
    /* height: 7px;
    width: 7px; */
    border-radius: 50%;
    background:  var(--E9-Gradient, linear-gradient(296deg, #F79C00 23.18%, #FED401 94.52%));;
  }
  
*{
  padding:0;
  margin:0;
  font-family: 'Assistant', sans-serif;
  box-sizing: border-box;
}

body{
  font-size: 16px;
  background-color: var(--light-10);
  min-height: 100vh;
  color: var(--light-10);
  overflow-x: hidden;
  background-color: var(--light-10);
}

.float{
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  
}

.section{
  background-color: var(--dark-20);
  text-align: center;
  padding-top: 1.5em;
  width:100vw;
}
.container{
  padding: 37px 121px;
  display: flex;
  gap: 2em;
  align-items: center;
  justify-content: space-evenly;
  
}

.flex{
  display: flex;
  gap: 2em;
}

::-webkit-scrollbar{
  width: 10px;
  color: black;
}

.silver-gradient{
  background: linear-gradient(90deg, #28292B 0%, #484B4D 50%, #212529 100%)
}

.serachYourOperator{
  background-color: var(--dark-20);
}
.subscription-type-card{
    display: flex;
     flex-direction: column;
     text-align: left;
     margin-bottom: 1em;
}


.subscription-type-card__heading{
    font-size: 24px;
     font-weight: 700;
     color: #2c2c2c;
 }
.subscription-type-card__heading--selected{
    color: #F97C00;
}

.subscription-type-card__text{
     color: #2c2c2c95;
     font-size: 18px;
     font-weight: 600;
}

.subscription-type-card__text--uppercase{
    text-transform: uppercase;
}

.listing-layout {
  margin: 23px 33px;
  display: flex;
  flex-direction: row;
  gap: 12px;
}
.free-listing-layout {
  display: flex;
  flex-direction: column;
  display: flex;
  padding: 7.405px;
  flex-direction: column;
  align-items: flex-start;
  gap: 7.405px;
  border-radius: 7.405px;
  background: var(--Light-10, #fefefe);
  box-shadow: 0px 3.702px 7.405px -1.851px rgba(0, 0, 0, 0.06),
    0px 7.405px 11.107px -1.851px rgba(0, 0, 0, 0.1);
}

.listing-progress-bar {
  height: 9.524px;
  width: 100%;
  background: #319f00;
  flex-shrink: 0;
  border-radius: 11.107px;
}
.listing-progress-bar-number {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.listing-progress-bar-subscription {
  height: 9.524px;
  width: 100%;
  border-radius: 11.107px;
  background: var(
    --E9-Gradient,
    linear-gradient(296deg, #f79c00 23.18%, #fed401 94.52%)
  );
  flex-shrink: 0;
}
.request-recieve-block {
  display: flex;
  width: 150.5px;
  padding: 6.732px;
  flex-direction: column;
  align-items: center;
  gap: 16.83px;
  flex-shrink: 0;
  border-radius: 13.464px;
  border: 1.683px solid var(--Light-50, #e3e5e8);
  background: var(--Light-10, #fefefe);
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.06),
    0px 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.up-down-request{
   display: flex;
   flex-direction: row;
}

.list-quantity-text{
color: #333;
text-align: center;
font-family: Assistant;
font-size: 28px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.18px;
margin-top: -8px;
margin-left: 7px;
}

.request-text{
 overflow: hidden;
color: var(--Dark-30, #484848);
text-align: center;
text-overflow: ellipsis;
font-family: Assistant;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.5px;
}

.Subscription-Remaining{
    color: var(--Dark-30, #484848);
    text-align: center;
    text-overflow: ellipsis;
    font-family: Assistant;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.5px;
}
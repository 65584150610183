.marketplace-header__position-wrapper {
  position: sticky;
  top: 69.5px;
  padding-right: 52px;
  padding-left: 52px;
  padding-top: 1em;
  padding-bottom: 1em;
  z-index: 1;
  background: #fff;
}

.marketplace-header__container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.marketplace-header__heading {
  font-size: 26px;
  font-weight: 700;
}

.marketplace-header__filter-button {
  border-radius: 4px;
  color: #1e3264;
  font-weight: 600;
  font-size: 18px;
  padding: 0.25em 0.5em;
  background-color: #fff;
}

.marketplace-header__filter-button--selected {
  background-color: #ffebcb;
  border: 1px solid #f79c00;
}

.marketplace-header__selected-img{
  transform: scale(1.1);
}
.marketplace-header__filter-img-btn{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 150px;
}
.marketplace-header__filter-img-btn img{
  width: 140px;
  height: 100px;
}

.marketplace-header__filter-img-btn-container{
  display:flex;
    gap: 1em;
    overflow-x: scroll;
    align-items:flex-start;
}
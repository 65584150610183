.materialStorecard-container{
    border-radius: 5px;
    box-shadow:0px 0px 7px 0px #00000040;
    max-width:360px;
    padding:10px 10px;
    display:flex;
    flex-direction:column;
}

.materialStorecard-container__additional-info{
    display:flex;
    color:#002961;
    font-weight:600;
    font-size:18px
}
.materialStorecard-container__overlay{
    position: relative;
}
.materialStorecard-container__overlay-container{
    position: absolute;
    display: flex;
    flex-direction: column;
    gap:0.5em;
    text-align: center;
}
.materialStorecard-container__overlay-container-badge{
    border-radius: 1em;
    color: #fff;
    padding: 2px 5px;
    z-index: 2;
}

.original-badge{
    border-radius: 8px;
    background: rgb(0, 0, 0);
    border: 1px solid orange;
}

.materialStorecard-container__img-container{
    width: 100%;
    height:150px;
    border: 1px solid #EEEEEE;
}

.materialStorecard-container__basic-info{
    /* border-radius:7px; */
    padding-bottom: 5px;
    border-bottom: 1px solid #E3E5E8;
    width:100%;
    display:flex;
    flex-direction:column;justify-content:space-between;
    cursor:pointer;
}

.materialStorecard-container__action-section{
    display:flex;
    justify-content:space-between;
    padding: 8px 0 0 0;
}
.materialStorecard-container__action-section__delete-button{
    margin:5px;
    padding: 3px;
    border-radius: 20%;
    background-color: #F5F5F5;
}
.materialStorecard-container__action-section__delete-button:disabled {
    cursor: auto; 
    opacity: 0.5;
}

.materialStorecard-container__action-section__edit-button{
    margin:5px;
    padding: 3px;
    border-radius: 20%;
    background-color: #F5F5F5;
}
.materialStorecard-container__action-section__edit-button:disabled {
    cursor: auto; 
    opacity: 0.5;
}

.materialStorecard-container__action-section__add-to-marketplace-button{
    color: #F19020;
    border: 1px solid #F19020;
    border-radius: 7px;
    padding: 5px 20px;
    font-weight: 700;
    
}





.hero-text-section{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 6px 16px;
    /* align-items: center; */
    gap: 8px;
    justify-content: center;
    .hero-text-heading{
        color: var(--dark-90, #141619);
        /* Display 4/Semibold */
        font-family: Assistant;
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.4px
    }
    .hero-text-subheading{
        color: var(--light-90, #959596);
        /* H2/Semibold */
        font-family: Assistant;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.24px;
    }
  }
.error-container {
    display: flex;
    height: 100vh;
    background : linear-gradient(
      140deg, 
      rgba(252, 235, 207, 1) 0%,   
      rgba(252, 254, 256, 2) 20%,
      rgba(252, 254, 256, 2) 20%,  
      rgba(255, 254, 254, 1) 90%,  
      rgba(252, 254, 256, 2) 70%,  
      rgba(252, 235, 207, 1) 100%  
    );

  }
  
  .error-message {
    padding: 4%; 
    display: flex;
    flex-direction: column;
    align-items: flex-start; 
    justify-content: center;
    gap: 2em;
  }
  
  .error-message-heading {
    color: black;
    font-size: 44px;
    font-weight: 600;
  }
  
  .error-message-message {
    color: black;
    font-size: 18px;
    font-weight: 600;
  }
  
  .error-message-btn {

    background-image: linear-gradient(to right, #f67c00, #FED401);
    color: #fff;
    border-radius: 0.375rem;
    font-weight: 600;
    transition: box-shadow 0.2s;
    height: 2.5rem;
    padding: 8px;
  }
  .error-message-btn-contact {

    color: #f67c00;
    border:solid 1px #f67c00; 
    border-radius: 0.375rem;
    font-weight: 600;
    transition: box-shadow 0.2s;
    height: 2.5rem;
    padding: 8px;
  }
  
  .error-message-img {
    flex: 2;
  }
  
  
.show-card-store-layout {
  display: flex;
  width: 342px;
  height: 163px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--Light-50, #e3e5e8);
  background: var(--Light-10, #fefefe);
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.06),
    0px 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.show-card-store-layout:hover {
    display: flex;
    width: 342px;
    height: 163px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--Light-50, #F79C00);
    background: var(--Light-10, #fefefe);
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.06),
      0px 4px 6px -1px rgba(0, 0, 0, 0.1);
  }

.show-card-store-option-layout {
  display: flex;
  flex-wrap: wrap;
  gap: 42px;
  cursor: pointer;
  margin: 22px 32px;
  flex-direction: row;
}

.show-image-card {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.show-information-layout {
  /* background: yellow; */
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.show-img-config {
    width: 67.463px;
    height: 83px;
    object-fit: contain;
}

.Spare-Parts-Store {
  color: var(--Secondary-90, #002961);
  font-family: Assistant;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.18px;
}

.quantity-added {
  color: var(--Light-90, #959596);
  font-family: Assistant;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.16px;
  margin-top: 4px;
}

.added-on-market-place {
  color: var(--Light-90, #959596);
  font-family: Assistant;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.6px;
  margin-top: 4px;
}

.added-on-seprator {
  height: 2px;
  width: 138px;
  background: #e3e5e8;
  margin-top: 4px;
}

.view-store-button{
    display: flex;
    width: 138px;
    padding: 6px 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 4px;
    margin-top: 14px;
    border: 1px solid var(--Primary-50, #F79C00);
}

.store-view-text{
color: var(--Primary-50, #F79C00);
font-family: Assistant;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.14px;
}


.no-store-created-yet{
  color: var(--Dark-10, #76767690);
    font-family: Assistant;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 14%;
}

.upload-text-black {
    color: var(--Dark-90, #141619);
    font-family: Assistant;
    font-size: 20.455px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.205px;
  }

  .docbutton-ongoing-change{
    display: flex;
    width: 106px;
    height: 34px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 6px;
    border: 1px solid #000000;
    background: #FFF;
  }

  .Material-pop-header-subheading-materail {
    color: var(--Dark-50, #2c2c2c);
    font-family: Assistant;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.18px;
    margin-top: 12px;

  }
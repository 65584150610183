 .E9-text-companyDetail{
        color: var(--unnamed-color-1e3264);
        text-align: center;
        font: normal normal bold 18px/24px Assistant;
        letter-spacing: -0.18px;
        color: #1E3264;
        text-transform: capitalize;
        opacity: 1;
        width: 100%;
        background-color: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
        /* border: 1px solid #2C2C2C14; */
        margin-top: 25px;
        height: 75px;
        margin-top: 0px;
        /* padding-top:  12px;
        padding-left: 10%; */
        padding-top: 26px;
        }
        
        
        .subcrption-table-company{
            top: 259px;
            left: 280px;
            width: 85%;
            /* height: 77px; */
            background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
            box-shadow: 0px 10px 30px var(--unnamed-color-2c2c2c14);
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 0px 10px 30px #2C2C2C14;
            /* border: 1px solid #2C2C2C1A; */
            border-radius: 4px;
            opacity: 1;
            display: flex;
            flex-direction: row;
            margin: 25px 45px;
        
        }

        .checbox-style{
            gap: 241px;
            display: flex;
            margin-left: -137px;
        }
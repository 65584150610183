.nearme-marketplace-card__verification-badge{
    border-radius: 1em;
    background-color: rgba(0,0,0,0.5);
    text-align: center;
    padding: 0.15em 0.5em;
    min-width: 100px;
    max-width: 135px;
    max-height: 28px;
    min-height: 28px;
}

.nearme-marketplace-card__verification-badge__text{
    color: #fff;
    font-size: 14px;
}
.sticky {
  display: flex;
  align-items: center;
  gap: 2em;
  padding: 1em;
  height: 228px;
  width: 100%;
  position: relative;
  border-radius: 8px;
  background-color: #ffebcb;
  overflow: hidden; /* Ensure children are contained */
}

.sticky::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: inherit;
  background-size: cover;
  background-repeat: no-repeat;
  filter: brightness(50%);
  z-index: 1;
}

.sticky__container {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  width: 75%;
  z-index: 2; /* Ensure it's above the overlay */
}

.sticky__heading, .sticky__sub-heading {
  font-size: 18px;
  font-weight: 600;
  color: #FFFFFF;
}

.sticky__highlighted-text {
  font-size: 24px;
  font-weight: 700;
  color: #F79C00;
}

.sticky__cta {
  font-size: 16px;
  color: #FFF;
  background-color: #F79C00;
  max-width: 185px;
  width: 100%;
  border-radius: 20px;
  padding: 0.35em 0.2em;
  font-weight: 600;
  z-index: 2; /* Ensure it's above the overlay */
}
.NavbarContainer{
  position: fixed;
  width: 85%;
  z-index: 100;
  padding-bottom: 1%;
 
}
.Navbar {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 10px #2c2c2c0a;
  opacity: 1;
  width: 100%;
  height: 96px;

  .Navbar-section {
    display: flex;
    flex-direction: "column";
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    display: flex;
    padding: 3.5%;
    /* padding: 39px; */
    margin-top: -2%;

    .name {
      padding-left: 50px;
      color: #000000;
      text-align: left;
      font: normal normal bold 17px/38px Assistant;
      letter-spacing: -0.17px;
      color: #2c2c2c;
      text-transform: uppercase;
      opacity: 1;
      margin-left: -79px;
    }
    .nav-notification {
      display: flex;
      flex-direction: row;
      gap: 28px;
    }
    .userimage {
      height: 40px;
      width: 40px;
      border-radius: 50%  ;
      cursor: pointer;
    }
  }

  .list-icon {
    height: 24px;
    width: 24px;
    cursor: pointer;
  }
}

.center-content {
  display: flex;
  align-items: center; 
  justify-content: center; 
}

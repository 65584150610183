.steps-containers {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1em;
    margin: 2em;
}

.filter-steps {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1em;
}

.welcome-dealershiip-step {
    color: #333;
    font-size: 20px;
    font-weight: 700;
}

.welcome-dealershiip-step-details {
    color: #333;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0.22px;
    width: 100%;
}

.filter-steps-containner {
    display: flex;
    gap: 3em;
    width: 100%;
    height: 60px;
    align-items: center;
}

.steps-button {
    background-color: #F79C00;
    display: flex;
    width: 156.16px;
    height: 54.706px;
    padding: 19.52px 43.92px;
    justify-content: center;
    align-items: center;
    gap: 12.2px;
    flex-shrink: 0;
}


.input-section {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 97%;
    padding: 1%;
    margin: 1%;
    cursor: pointer;
}


.input-section {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 30px #2c2c2c14;
    border: 1px solid #2c2c2c14;
    border-radius: 4px 4px 0px 0px;
    opacity: 1;
    height: 60px;
    display: flex;
    width: 300px;
}



.input-image-box {
    width: 50px;
    height: 50px;
    background: #fafafa 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 10px #00000024;
    border: 1px solid #2c2c2c14;
    border-radius: 4px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    margin-top: -18px;
    margin-left: 23px;
}

.input-image {
    width: 25px;
    height: 25px;
    margin: 25%;
    color: #000;
}

.input-icon {
    margin-left: 171px;
    margin-top: 26px;
}


.input-name {
    margin-left: 23px;
    margin-top: 12px;
}
.add-dealership-oem-container{

}

.add-dealership-image-container {
    max-width: 40px;
    max-height: 50px;
    position: absolute;
    bottom: 1.5em;
    left: 1em;
    border: 2px solid #959596;
    height: 100%;
    width: 100%;
    background-color: aliceblue;
}

.add-dealership-label {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #959596;
    height: 40px;
    width: 255px;
    border-radius: 4px;
    cursor: pointer;
}

.add-dealership-oem-container:active,
.add-dealership-label:active {
    border-color: #F79C00;
}

.add-dealership-oem-container:focus,
.add-dealership-label:focus {
    border-color: #F79C00;
}
.add-dealership-oem-container:hover{
    border-color: #F79C00;
}


/* Post Dealership Pop Up */
.add-photo-pop-up-container {
    display: flex;
    max-height: 198px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    justify-content: center;
    height: 198px;
    max-width: 400px;
}
.blog-container-wrapper{
    display: flex;
    flex-direction: column;
    gap: 2.5em;
    color: #FBFBFC;
    border-bottom: 3px solid #F79C00;
    background: linear-gradient(90deg, #28292B 0%, #484B4D 50%, #212529 100%);
    overflow: hidden;
    max-height: 0;
    transition:  0.5s ease-out;
}

.blog-container-wrapper.expanded {
    max-height: 100%;
  }

.blog-header{
    font-size: 2.5em;
    color: #FBFBFC;
}

.blog-list{
    font-size: 1em;
    color: #F79C00;
}

.blog-list-content{
    color: #FBFBFC;
}
.blog-section{
    padding-left: 2em;
    padding-bottom: 1em;
    padding-top: 0.5em;
}
.blog-list-container{
    padding: 1em;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(calc(50% - 20px), 1fr));
    gap: 1em;
    list-style-type: decimal;
}

@media screen and (max-width: 768px) {

    .blog-header {
        font-size: 1.5em; 
    }

    .blog-list-container {
        grid-template-columns: 1fr; 
    }

    .blog-section {
        padding: 0.5em;
    }

    .blog-list-content {
        padding-bottom: 0.25em;
    }
}
.know-more-rating-layout{
color: #0068F7;
font-family: Assistant;
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.24px;
margin-left: 23px;
margin-bottom: 12px;
cursor: pointer;
}



/* App_theme: "#F79C00",
LOADING: "#0c9",
WHITE: "rgba(255, 255, 255, 1)",
LIGHT_WHITE: "#dfdfdf",
FAINT_WHITE: "#efefef",
BORDER_GRAY: "#a7a7a7",
BLACK: "#000000",
FAINT_BLACK: "rgba(0,0,0,0.5)",
MODAL_BLACK: "#000000aa",
DARKGRAY: "#999",
FAINT_ORANGE: "#FAA300",
HEADER_ORANGE: "#fba301",
PRIMARY_COLOR: "#FAA300",
FAINT_PRIMARY_COLOR: "#fec500",
SECONDARY_COLOR: "#fba301",
FAINT_SECONDARY_COLOR: "#f7ad00",
DARK_GREEN: "#216c2a",
GREEN: "#18c207",
LIGHT_GREEN: "#1ce019",
FAINT_GREEN: "#00cb4c",
LIGHT_BLUE: "#6EA8DA",
BLUE: "#1e3264",
DARK_BLUE: "#0F3274",
RED: "red",
REQUIRED_RED: "#D60000",
REQUIRED_BORDER_RED: "#D60000CC",
LIGHT_RED: "#ff0000",
FAINT_RED: "#eb1c1c",
HEADER_RED: "#bc0000",
VERY_DARK_GRAY: "#999",
DARK_GRAY: "#606070",
MEDIUM_GRAY: "#808080",
FAINT_GRAY: "#cdcdcd",
LIGHT_GRAY: "#8d8d8d",
VERY_LIGHT_GRAY: "#c3c3c3",
TRANSPARENT_GRAY: "rgba(52, 52, 52, 0.3)",
TRANSPARENT_LIGHT_GRAY: "rgba(52, 52, 52, 0.2)",
TRANSPARENT_WHITE_GRAY: "rgba(255, 255, 255, 0.6)",
SHADOW_GRAY: "#c1c1c1",
light_background: "#2C2C2C05",
light_borderColor: "#2C2C2C14",
border_light: "#2C2C2C1A",
light_background_main: "#FCFCFC",
text_color_dark: "#2C2C2C",
EARLY_DAWN: "#FEF8EC",
EARLY_DAWN_LIGHT: "#FEFBF5",
OFF_FOCUS_INPUT_COLOR: "#2C2C2C14",
SUCCESS_GREEN: "#319F00",
INPUT_PLACE_HOLDER: "#2C2C2C20",
default_blue: "#1E3264",
bg_user_data: "#F79C001A",
BADGE_EXPIRED: "#929292",
RATING_BADGE_BG: "#FED401",
STICK_BADGE_BG: "#0068F7",
FAINT_ORANGE: "#FFF5E080",
BRIGHT_BLUE: "#004BB2",
// =========================
SHADOW_COLOR: Platform.OS == "ios" ? "#2C2C2C30" : "#2C2C2C",
BORDER_COLOR: "#2C2C2C20",

// ============ STEP COLOR =============
STEP_PROGRESS_COLOR: "#F79C00",
STEP_PROGRESS_LABEL_COLOR: "#707070",
TEXT_GRAY: "#BFBFBF",
TEXT_BLUE: "#001F7B",
MODAL_CROSS_COLOR: "#2C2C2C40",
TEXT_LIGHT_PLACEHOLDER: "#C6C7C8",

DARK_GRAY: "#C6C7C8",
SEMI_TRANSP_GREEN: "#319F0080",
SEMI_TRANSP_RED: "#9F000080", */
.accept-reject-container{
    display: flex;
    flex-direction: column;
}
.accept-reject-card__section{
    height: 120px;
    border-radius: 8px;
    position: relative;
}
.accept-reject-card__image-overlay{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 8px;
}
.accept-reject__details-section {
    border-radius: 8px;
    background: radial-gradient(#FFF ,#EAEAEA);
    padding: 5px 15px 7px 13px;
    width: 90%;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.3);
    margin: 0 auto;
    font-weight: 500;
    text-align: center;
}
.accept-reject__details-section__text {
    color: #002961;
    font-size: 12px;
}
.accept-reject__section--image {
    height: 95%;
    border-radius: 8px;
}
.accept-reject__section--image__image {
    height: 100%;
    border-radius: 8px;
    width: 100%;
}
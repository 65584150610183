.swiper{
    width: 601px;
    height: 235px;
    /* background: blue; */
}
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }


.verification-badge-head{
    border-radius: 1em;
    background: rgba(214, 0, 0, 0.376);
    text-align: center;
    padding: 0.15em 0.5em;
    min-width: 100px;
    max-width: 135px;
    max-height: 28px;
    min-height: 28px;
    position: absolute;
    z-index: 10;
    top: 13px; 
    left: 465px;

}


.verify-text-delar{
    color: #FFF;
/* font-family: Inter; */
font-size: 12.094px;
font-style: normal;
font-weight: 700;
text-align: center;
margin-top: 4px;
}
  
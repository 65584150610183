.show-store-marketplace-view-layout {
  display: flex;
  flex-wrap: wrap;
  gap: 44px;
  cursor: pointer;
  margin: 44.15px 32px;
  flex-direction: row;
}

.show-store-marketplace-card-layout {
  display: flex;
  width: 592px;
  /* height: 243px; */
  padding: 0px 11.644px 2.822px 11.644px;
  align-items: center;
  gap: 15.8px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 8.621px 0px rgba(0, 0, 0, 0.25);
  flex-direction: row;
  padding-top: 39px;
    padding-bottom: 49px;
}

/* .show-store-marketplace-card-layout :hover{
    border: 2px solid #F19020 ;
  } */

.informationhero-marketplace {
  width: 60%;
  height: 81%;
  /* background-color: red; */
}

.image-marketplace-hero {
  width: 264px;
  height: 177px;
  object-fit: contain;
  flex-shrink: 0;
  /* border-top-left-radius: 25px; */
  /* border-top-left-radius: 5px 12px; */
  /* background-color: yellow; */
}

.informationhero-marketplace-description {
    margin: -9px -4px;
    display: flex;
    flex-direction: column;
    margin: -22px -4px
   
}

.equipment-hero-info-heading {
  color: #333;
  font-family: Assistant;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
}

.equipment-hero-info-subheading {
  color: #333;
  font-family: Assistant;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

.equipment-hero-price-info-subheading {
    color: #333;
    font-family: Assistant;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    
  }
.equipment-hero-info-PNO {
  color: #003196;
  font-family: Assistant;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  width: 320px;
  height:25px
}

.equipment-hero-info-address {
  color: #888;
  font-family: Assistant;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
}

.equipment-hero-info-divider {
    display: flex;
    flex-direction: row;
    gap: 29px;
    margin-top: 1vh;
}

.add-to-marketplace-button {
  display: flex;
  width: 60%;
  height: 45px;
  padding: 8.172px;
  justify-content: center;
  align-items: center;
  gap: 8.172px;
  flex-shrink: 0;
  border-radius: 6px;
  border: 0.817px solid #f19020;
}

.add-to-marketplace-button-text {
  color: #f19020;
  font-family: Assistant;
  font-size: 15.8px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.158px;
}

.garbage-option{
    margin-top: 7px;
}
.edit-option{
    margin-top: -1px;
}

.tag-verfied {
  background: rgba(214, 0, 0, 0.376);
  color: white;
  position: absolute;
  /* margin-top: -171px; */
  top: 18%;
  right: 83%;
  height: 22px;
  width: 83px;
  border-radius: 10px;
  z-index: 10;
}

.verified-text-marketplace{
    color:#fff;
    font-family: Assistant;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    text-align: center;
}

.no-material-added-yet{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
    width: 100%;
    margin-top: 8%;
    color: #BFBFBF;
    font-family: Assistant;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.158px;
}


.swiper {
  width: 213px;
  height: 150px;
  /* background: blue; */
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: fill;
}


.my-custom-bullet {
  background: var(--E9-Gradient, linear-gradient(296deg, #f79c00 23.18%, #fed401 94.52%) );
  width: 10px; /* Adjust the size */
  height: 10px; /* Adjust the size */
  border-radius: 50%;
  margin: 3px;
  opacity: 0.5; /* Adjust for non-active bullets */
}

.swiper-pagination-bullet-active.my-custom-bullet {
  opacity: 1; /* Adjust for the active bullet */
}


.remove-from-marketplace-button {
  display: flex;
  width: 60%;
  height: 45px;
  padding: 8.172px;
  justify-content: center;
  align-items: center;
  gap: 8.172px;
  flex-shrink: 0;
  border-radius: 6px;
  border: 0.817px solid #003196;
}

.remove-from-marketplace-button-text {
  color: #003196;
  font-family: Assistant;
  font-size: 15.8px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.158px;
}
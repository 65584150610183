.backGround{
    height: 100px;
    width: 100%;
    background: var(--dark-90, #141619);
    /* border: 2px solid #FECA00; */
   
  }

  .drawer-icon{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

 .image{
    margin: 13px 13px;
  } 

  .hermburger{
    margin-top:  43px;
    margin-right: 26px;
  } 


  .drawerBackgrod{
    width: 100%;
    background: var(--dark-90, #141619);

  }

  .DrawerLogo{
  object-fit: cover;
 margin-top: 23px;
  }

  .DrawerOptions{
 /* width: 100%;
 height: 100%; */
  }

  .DrawerHeroHeading{
   color:  #FFFFFF;
   font-size: 1.3rem;
   font-weight: 500;
  }

  .sepratorDrawer{
    height:0.5px;
    width: 100%;
background: #FECA00;
margin-top: 100px;
  }

  .footerVersion{
    width: 100%;
    margin-top: 12px;
    border: 2px solid  #FECA00;
    height: 100%;  ;
    background:  white;
    height: 40px;
  }
  .footerTextDrawer{
    font-weight: 600;
    color: black;
    font-size: 1.4rem;
    text-align: center;
  }

  .social-media{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .drawerOption{

    display: flex;
    flex-direction: row;
    margin-top: 30px;
    gap: 13px;
  }

  .exitButton{
    position: absolute;
    left: 85%;
    top: 2%;
  }





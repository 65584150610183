.oldLicense {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.popup-card-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 101;
  overflow-y: auto;
  padding: 20px;
}

.popup-card-license {
  background-color: white;
  padding: 16px 51px;
  border-radius: 15px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  max-width: 700px;
  width: 100%;
  overflow-y: auto;
  height: 600px;
  overflow: scroll;
}

.popup-card-close {
  text-align: right;
  margin-bottom: 20px;
}

.popup-card-license h1 {
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: bold;
  color: #1e3264;
}

.popup-card .form-group,
.OptionField div {
  margin-bottom: 25px;
}

.popup-card-license label {
  display: block;
  font-weight: 500;
  margin-bottom: 8px;
  color: #2c2c2c;
  text-transform: capitalize;
}

.popup-card-license .required {
  color: red;
  margin-left: 5px;
}

.file-input-container {
  position: relative;
  width: 100%;
  height: auto;
  text-align: center;
  margin-top: 20px;
}

.custom-file-button {
  background: #f2f2f2;
  border: 1px solid #ddd;
  cursor: pointer;
  display: inline-block;
  padding: 10px 20px;
  height: 45px;
  border-radius: 5px;
}
.upload-file-text {
  font-weight: 600;
  color: #1e3264;
  font-size: 14px;
}

.mid-space{
    margin-top: 12px;
}

.custom-file-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.popup-card-license input[type="text"],
.popup-card-license select {
  width: 100%;
  padding: 10px 15px;
  background: #f2f2f2;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
}

.popup-card-license button[type="submit"] {
  width: 100%;
  padding: 10px 0;
  background: linear-gradient(274deg, #f79c00 0%, #fed401 100%);
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: 0.3s ease;
}

.popup-card-license button[type="submit"]:hover {
  background: linear-gradient(274deg, #fed401 0%, #f79c00 100%);
}

.popup-card-license button[type="submit"]:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.error-message {
  color: red;
  font-size: 12px;
}

.OptionField {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.OptionField div {
  flex: 1;
  padding: 0 10px;
}

.Verfied-tag {
    justify-content: center;
    background-color: #D60000;
    align-self: center;
    display: flex;
    height: 45px;
    width: 100%;
    margin-left: 1px;
  
    
  }

  .Verifi-text {
    color: #ffffff;
    font-family: "Assistant";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    margin-top: 10px;
  }


  .profile-image:hover .Profileimg {
    filter: blur(2.5px); 
    border: black 1px solid;
    color: #ffffff
}
  
  .Company-Indi-Profile {

    background-color: #FFFBF5;
    display: flex;
    flex-direction: row;
    /* gap: 23px; */
    /* height: 190px; */
    margin-bottom: 4px;
    margin-left: 3px;
    /* padding-bottom: 0; */
    /* padding-left: 1%;
    padding-top: 1%; */
  
    .Profileimg {
      height: 100px;
      width: 100px;
      border-radius: 50%;
      justify-content: center;
      align-self: center;
      display: flex;
      border: black 1px solid;
      transition: background-color 0.3s, backdrop-filter 0.3s;
      cursor: pointer;
    }
    .userDetail {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 0px;
    justify-content: flex-start;
  
      .CompanyName {

        text-align: left;
        font: normal normal bold 22px/34px Assistant;
        color: #2c2c2c;
        opacity: 1;
      }
    
      .busineerOwner {

        text-align: left;
        font: normal normal bold 13px/34px Assistant;
        color: #f79c00;
        text-transform: uppercase;
        opacity: 1;
      }
      .headofDepartement {

        text-align: left;
        font: normal normal bold 13px/34px Assistant;
        color: #f79c00;
        text-transform: uppercase;
        opacity: 1;
      }
      .e9role {

        text-align: left;
        font: normal normal bold 14px/34px Assistant;
        color: #2c2c2c;
        margin-top: -23px;
        
      }
    }
  }


  .role-text{
    color:#f79c00 ;
    font: normal normal bold 13px Assistant;
    color: #f79c00;
  }
  
  /* Style for each element */
  .flex-item {
    margin: -6px; /* Reset margin */
    padding: 0px; /* Add padding for spacing inside each element */   
  }
  .flex-item  :last-child {
    margin-bottom: 6px; /* Reset margin */
   
  }


  .useraccount-details{
    display: flex;
    align-items: center;
    /* flex-direction: row; */
    gap: 6px;
    justify-content: flex-start;
    margin-top: 5px;

       .phone-img{
          height: 12px;
          width: 12px;
          /* margin-top: -6px;   */

          }
     
      .phone-text{

          text-align: left;
          font: normal normal normal 18px/34px Assistant;
          color: #2c2c2c;
          opacity: 0.7;
      }
      
    
  }

.SeturFeature{
    font: normal normal bold 20px Assistant;
     color: #1E3264;
    text-align: center;
    margin-top: 23px;
}


.modalStyles {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1001;
  background: #ffffff; /* Modal Background */
  padding: 20px;
  width: 300px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);
  border-radius: 5px;
}

.overlayStyles {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7); /* Overlay Background */
  z-index: 1000;
}



  .preview-container-company{
    display: flex;
    flex-direction: row;
  justify-content: space-between;
  /* align-items: center;
  border-style:dashed;
  border-width:2px; */
   /* border-color:#A0AEC0modalStylesund:#ccc; */
  }


  .custom-file-input-compnay{
    padding: 6px 20px;
    cursor: pointer;
    display: inline-block;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 30px #2c2c2c14;
    border: 1px solid #1E326480;
    border-radius: 4px;
    text-align: center;
    font: normal normal 600 15px/24px Assistant;
    /* letter-spacing: -0.96px; */
    color: #1E3264;
    width: 95px;
    height: 38px;
    margin-top: 14px;
    display: none;
  }

  .custom-file-input-compnay-Profile{
    padding: 6px 20px;
    cursor: pointer;
    display: inline-block;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 30px #2c2c2c14;
    border: 1px solid #1E326480;
    border-radius: 4px;
    text-align: center;
    font: normal normal 600 15px/24px Assistant;
    /* letter-spacing: -0.96px; */
    color: #1E3264;
    width: 95px;
    height: 38px;
    margin-top: 14px;
  }


  .button-placement{
      display: flex;
      flex-direction: row;
    justify-content: space-between;
    margin:4px 23px;
  }


  .user-rating-profile-section{
    width: 52px;
    height: 21px;
    background: var(--unnamed-color-1e3264) 0% 0% no-repeat padding-box;
    background: #1E3264 0% 0% no-repeat padding-box;
    border-radius: 2px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    gap: 4px;
    margin-top: 5px
  }
  
  
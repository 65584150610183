@keyframes count-increment {
    0% {background-color: #FFFF;}
    50%{background-color: #319F0015;}
    100% {background-color: #FFFF;}
}

@keyframes count-decrement {
    0% {background-color: #FFFF; font-size: 16px; color: #2c2c2c;}
    50%{background-color: #D6000015; font-size: 14px; color: "#D60000"}
    100% {background-color: #FFFF; font-size: 16px; color: "#2c2c2c"}
}

@keyframes count-increment-text{
    0% {color: #2c2c2c; font-size: 16px;}
    50%{color: #319F00; font-size: 18px;}
    100% {color: #2c2c2c; font-size: 16px;}
}

@keyframes count-decrement-text{
    0% {color: #2c2c2c; font-size: 16px;}
    50%{color: #D60000; font-size: 14px;}
    100% {color: #2c2c2c; font-size: 16px;}
}

.counter__increment{
animation-name: count-increment;
animation-duration: 0.5s;
}

.counter__decrement{
    animation-name: count-decrement;
    animation-duration: 0.5s;
}

.counter__increment__text{
    animation: count-increment-text;
    animation-duration: 0.5s;
}
.counter__decrement__text{
    animation: count-decrement-text;
    animation-duration: 0.5s;
}
.SkillType{
display: flex;
flex-direction:row;
gap: 12px;
}

.skillName{
    font-size: 1.2rem;
    color: black;

}


.verfication-badge{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;    

}



.cards-container{
    display: flex;
flex-wrap: wrap;
gap: 42px; /* Adjust the space between cards */
justify-content: flex-start;
cursor: pointer;
margin-top: 32px;
}

.badge-layout{
    border-radius: 4em;
    /* position: absolute; */
    width: 150px;
    height: 28px;
    opacity: 0px;
    background: #319F008C;
    color: white;
    font-weight: 700;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    /* left: 18%; */
}
.userResposeTypeValue-upcoming{
    font-family: Assistant;
    font-weight: 700;
    font-size:12px;
    color: white;
}



.onGoingCard-layout-ongoing{
    display: flex;
    width: 540px;
    height: 200px;
    padding: 13px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex-shrink: 0;
    border-radius: 4.205px;
    background: #FFF;
    box-shadow: 0px 0px 4.205px 0px rgba(0, 0, 0, 0.25);
    }


.onGoingCard-layout{
display: flex;
width: 540px;
height: '200px';
padding: 13px;
flex-direction: column;
justify-content: center;
flex-shrink: 0;
border-radius: 4.205px;
background: #FFF;
box-shadow: 0px 0px 4.205px 0px rgba(0, 0, 0, 0.25);
}

.jobNumber{
    color: #2C2C2C;
    font-family: Assistant;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.2px;
}

.jobTitle-font{
    margin-top: 7px;
    color: #1A1E21;
    font-family: Assistant;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.2px; 
}

.Equipment-category{
    margin-top: 7px;
    color: #2C2C2C;
    font-family: Assistant;
    font-size:0.9rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.worklocation{
    margin-top: 7px;
    color: #767676;
    font-family: Assistant;
    font-size:16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.payment-layout{
    margin-top: 12px;
display: flex;
flex-direction: row;
justify-content: space-between;

}

.paymentfont{
    color: #002961;
    font-family: Assistant;
    font-weight: 700;
    font-size:20px;
    text-align: center;
}

.otpbox{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    width: 120px;
    /* border: 2px solid #FED401; */
    background: var(--E9-Gradient, linear-gradient(296deg, #F79C00 23.18%, #FED401 94.52%));
    border-radius: 9px;
 
}
.start-otp{
    font-family: Assistant;
    font-weight: 700;
    font-size:13px;
     color: #fff;
     text-align: center;
     margin-top: 2%;
}

.OtpInputBox{
    height: 53px;
    width: 63px;
    background-color: #cdcdcd90;
    border: 1px #c1c1c1 solid ;
    align-items: center;
    text-align: center;
    border-radius: 5px;
}

.otpinputLayout{
    /* height: auto; */
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-self:center;
    margin: 0px 23px;
    gap: 17px;
}

.otpinputerror-layout{
    display: flex;
    flex-direction: column; 
}

.OtpIcon{
    display: flex;
    align-self:center;
    justify-content: center;
}

.error-text{
    color: red;
    font-family: Assistant;
    font-size:0.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;  
    width: auto;
    height: 14px;
    margin-top: 8px;
}


.input-touch:focus{
     border-color: red;
     outline: none;
}


.otp-input:focus {
    border-color: #F79C00;
    outline: 2px solid #F79C00; /* Custom focus outline */
  }
.popup-card-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 101;
    overflow-y: auto;
    padding: 20px;
}

.popup {
    background-color: white;
    padding: 21px 23px;
    border-radius: 15px;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
    max-width: 700px;
    width: 100%;
    overflow-y: auto;
    height: 600px;
    overflow: scroll;
   
}

.popup-card-close {
    text-align: right;
    margin-bottom: 20px;
}

.popup h1 {
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: bold;
    color: #1E3264;
}

.popup .form-group, .OptionField div {
    margin-bottom: 25px;
}

.popup label {
    display: block;
    font-weight: 600;
    margin-bottom: 8px;
    color: #2C2C2C;
    text-transform: capitalize;
}

.popup .required {
    color: red;
    margin-left: 5px;
}

.file-input-container {
    position: relative;
    width: 100%;
    height: auto;
    text-align: center;
    margin-top: 20px;
}

.custom-file-button {
    padding: 10px 20px;
    cursor: pointer;
    display: inline-block;
    background: #f2f2f2;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-weight: 600;
    color: #1E3264;
}

.custom-file-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.popup input[type="text"],
.popup select {
    width: 100%;
    padding: 10px 15px;
    background: #f2f2f2;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 14px;
}

.popup button[type="submit"] {
    width: 100%;
    padding: 10px 0;
    background: linear-gradient(274deg, #F79C00 0%, #FED401 100%);
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: 0.3s ease;
}

.popup button[type="submit"]:hover {
    background: linear-gradient(274deg, #FED401 0%, #F79C00 100%);
}

.popup button[type="submit"]:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.error-message {
    color: red;
    font-size: 12px;
}

.OptionField {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.OptionField div {
    flex: 1;
    padding: 0 10px;
}

.seprator{
    height: 100%;
    /* width: 45px; */
    margin-top: 27px;
    /* background-color: red; */
    border-top: 3px solid #bbb;
    width: 72%;
    /* margin-left: 23px; */
    margin-top: 15px;
}

.hero-heading-text{
    color: var(--dark-90, #141619);
    font-family: Assistant;
    font-size: 31px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.4px;
}

.hero-sub-heading{
    color: var(--light-90, #959596);
    font-family: Assistant;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    /* line-height: normal; */
    letter-spacing: 0.24px;
}


.old-license-detail{
    display: flex;
    flex-direction: row;
}

.text-license{
    color: var(--Light-Blue, #4A5A82);
    font-family: Assistant;
    font-size: 23px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.24px;
    width: 373px;
}

.seprator-2{
    height: 100%;
    /* width: 45px; */
    margin-top: 27px;
    /* background-color: red; */
    border-top: 3px solid #bbb;
    width: 124%;
    /* margin-left: 23px; */
    margin-top: 15px; 
}

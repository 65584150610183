/* #f79c00 - gold */

.MyProfileSection {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-evenly; */
  align-items: center;
  width: 97%;
  /* border: 1px solid #000;  */
  padding: 1%; 
  margin: 1%;
  cursor: pointer;
}

.MyProfileSectionChild {
  /* width: calc(50% - 5px); */
  /* text-align: center; */
  padding: 1rem;

  transition: box-shadow 0.3s ease, transform 0.3s ease; 

}
.MyProfileSection :hover{
  /* margin-top: 5px;
  margin-left: 3px; */
  /* color: #fffbf5; */
    /* box-shadow: 0px 4px 10px #00000024; */
    /* background-color: red; */
  transform: scale(1.02);

 
/* UI Properties */
color: var(--unnamed-color-2c2c2c);
text-align: left;
font: normal normal 600 20px/26px Assistant;
letter-spacing: -0.6px;
color: #2C2C2C;
opacity: 1;

  .Profile{
    border: 1px solid #f79c00;
    /* color : #2c2c2c  */
  
  }

  .imageBox{
    border: 1px solid #f79c00;
    

  }
}

.Profile {
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 30px var(--unnamed-color-2c2c2c14);
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 30px #2c2c2c14;
  border: 1px solid #2c2c2c14;
  border-radius: 4px 4px 0px 0px;
  opacity: 1;
  width: 540px;
  height: 60px;
  margin-top: 3%;
  display: flex;
  flex-direction: row;

}



.imageBox {
    width: 50px;
    height: 50px;
    background: #fafafa 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 10px #00000024;
    border: 1px solid #2c2c2c14;
    border-radius: 4px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    margin-top: -18px;
    margin-left: 23px;
  }

  .image{
    width: 25px;
    height: 25px;
    margin:25%;
    color: #000;
  }
  .icon{
    margin-left: 171px;
    margin-top: 26px;
  }
  

  .Businessdevelopement{
    margin-left: 23px;
    margin-top: 12px;
    width: 228px;

    
  text-align: left;
  font: normal normal 600 20px/26px Assistant;
  letter-spacing: -0.6px;
  color: #2C2C2C;
  opacity: 1;
  }
.InviteTeam-hero {
  width: 374px;
  height: 34px;
  /* UI Properties */
  color: var(--unnamed-color-1e3264);
  text-align: left;
  font: normal normal bold 24px/34px Assistant;
  letter-spacing: 0px;
  color: #1e3264;
  opacity: 1;
  margin-left: 40%;
}

.name-filed {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 23px;
}

.input-filed {
  background: var(--unnamed-color-2c2c2c05) 0% 0% no-repeat padding-box;
  border: 1px solid var(--unnamed-color-2c2c2c14);
  background: #2c2c2c05 0% 0% no-repeat padding-box;
  width: 100%;
  height: 36px;
  border-radius: 5px;
  border: 1px solid #2c2c2c1a;
}

.mobile-number {
  display: flex;
  flex-direction: column;
}

.input-filed-mobile {
  background: var(--unnamed-color-2c2c2c05) 0% 0% no-repeat padding-box;
  border: 1px solid var(--unnamed-color-2c2c2c14);
  background: #2c2c2c05 0% 0% no-repeat padding-box;
  /* width: 374px; */
  height: 36px;
  border-radius: 5px;
  border: 1px solid #2c2c2c1a;
  width: 97%;
}
.space {
  margin-top: 12px;
}

.companyDesignation {
  display: flex;
  flex-direction: row;
  /* justify-content: start; */
}

.button-property {
  width: 180px;
  height: 52px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #2c2c2c1a;
  border-radius: 4px;
  opacity: 1;
  text-align: center;
  padding-top: 12px;
}

.design-invite {
  display: flex;
  justify-content: center;
  align-items: center;
}

.copy-link {
  width: 202px;
  height: 38px;
  background: transparent
    linear-gradient(277deg, var(--unnamed-color-f79c00) 0%, #fed401 100%) 0% 0%
    no-repeat padding-box;
  background: transparent linear-gradient(277deg, #f79c00 0%, #fed401 100%) 0%
    0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
}
.buttonspace {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
}

.copy-link-test {
  color: #ffffff;
}

.hello {
  background-color: aqua;
}

.hello.selected .button-property {
  border: 1px solid yellow;
  display: inline-block; /* This is to ensure the border encompasses the content */
}

.icon-shift {
  margin-top: -6px;
  margin-left: 31px;
}

.popup-cardd-close {
  text-align: right;
  cursor: pointer;
  margin-left: 535px;
}

.button-icon-style {
  margin-left: 142px;
  margin-top: -19px;
}

.accept-button{
  width: 89px;
  height: 34px;  
  border-radius: 5px;
  border:  1px solid #00C844; ;
  background-color :rgba(0, 200, 68, 0.25);
  color: #00C844;
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
  text-align: center;
}
.reject-button{
    width: 89px;
    height: 34px; 
  border-radius: 5px;
  border: 2px solid #F00;;
  background-color:rgba(255, 0, 0, 0.25);;
  color: #F00;
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
  text-align: center;
}
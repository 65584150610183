.user-rating-previous{
    width: 52px;
    height: 21px;
    background: var(--unnamed-color-1e3264) 0% 0% no-repeat padding-box;
    background: #1E3264 0% 0% no-repeat padding-box;
    border-radius: 2px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 12px;
    gap: 4px;
    margin-top: -14px;
}

.user-rating-text{
    top: 174px;
    left: 50px;
    
    /* UI Properties */
    color: var(--unnamed-color-ffffff);
    /* text-align: left; */
    font: normal normal 600 10px/34px Assistant;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-transform: uppercase;
    opacity: 1;
}

.user-rating-icon{
    margin-top: -1px;
}


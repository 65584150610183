.job-card {
  padding: 1em 1.5em;
  cursor: pointer;
  padding-top: 0;
  padding-right: 0;
}

.job-card__status {
  display: flex;
  justify-content: flex-end;
}
.job-card__status__highlight {
  font-size: 10px;
  /* border-bottom-left-radius: 16px;
  border-top-right-radius: 8px; */
  border-radius: 0px 5px 0px 100px;
  padding: 3px 10px 3px 15px;
  font-weight: 400;
  background-color: #319f00;
  color: #ffffff;
}

.job-card__status__highlight--failture {
  background-color: #d60000;
}

.job-card__section--info {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  padding-bottom: 1em;
  border-bottom: 1px solid #2c2c2c30;
}

.job-card--heading {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

.job-card--category {
  font-size: 12px;
  font-weight: 600;
  color: #767676;
}
.job-card--price {
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  color: #002961;
}
.job-card--location {
  font-size: 12px;
  font-weight: 600;
  color: #767676;
}
.job-card__footer {
  margin-top: 0.25em;
}
.job-card__footer__info {
  color: #D60000;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
}

/* NewDashboardNavbar.css */

.navbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding: 0em 2em;
}

.navbar__heading-greeting {
  padding-left: 50px;
  color: #000000;
  text-align: left;
  font: normal normal bold 17px/38px Assistant;
  letter-spacing: -0.17px;
  color: #2c2c2c;
  text-transform: uppercase;
  opacity: 1;
}

.navbar__notification {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 30px;
}

.navbar__user-image {
  /* height: 30px;
  width: 30px; */
}

.nav-user-Img{
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.nav-items{
  cursor: pointer;
}

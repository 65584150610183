.seperator-product{
    height: 2.5px;
    width: 100%;
    background-color: #F79C00;
}

.banner-hero-section-product{
    width: 100vw ;
    height: 70vh
}

.product-container{
    color: #FBFBFC;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 7px;
    padding: 5px;
    min-height: 50vh;
    width: 100vw;
    background: linear-gradient(90deg, #28292B 0%, #484B4D 50%, #212529 100%);
}
/* .product-item > :nth-child(even) {
    flex-direction: row-reverse;
    background: linear-gradient(120deg, #28292B 0%, #7A7C7F 50%, #28292B 100%);
    } */

.product-body-heding{
    padding: 2.25em 2em ;
    display: flex ;
    flex-direction :column ;
    gap:2em
}
.product-body-heding-text h1 {
 
        /* font-size: 40px ; */
        font-weight: 700 ;
        text-transform : uppercase;
        color:var(--primary)
}
.product-body-heding-text h2{
        font-size: 36px ;
        line-height: 1em;
}

.product-body-heding-description h3{
    font-size: 22px;
}


.product-container img{
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.product-body-heding{
    flex : 0 70% ;
}
.product-body-img{
 flex: 0 30%;
 height: 100% ;
 width: 100% ;
 display: flex ;
 align-items: center ;
 justify-content: center,
}
.choose-material-type-popup__selector-image {
  width: 90%;
  height: 90%;
}

.choose-material-type-popup__title-image {
  height: 100%;
  width: 100%;
  object-fit: fill;
}

.material-assest-container{
    margin-top: 6em;
    padding: 1em 3em;
}
.new-description-card-material{
    width: 30%;
    min-width: 250px;
    max-width: 550px;
    min-height: 120px;
    box-shadow: 7px 6px 9px 0px #2c2c2c25;
    display: flex;
    flex-direction: column;
    padding: 0.75em 0.5em;
    cursor: pointer;
    justify-content: space-around;
    gap: 1em;
    flex-wrap: wrap;
    /* border: 1px solid #959596; */
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--Light-50, #E3E5E8);
    background: var(--Light-10, #FEFEFE);
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.06),
      0px 4px 6px -1px rgba(0, 0, 0, 0.1),
    
  }
  .new-description-card-material:hover{
    border-radius: 8px;
    border: 1px solid var(--Light-50, #F79C00);
    background: #FEFEFE;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
  }
  
  .new-description-card__heading{
      font-size: 23px;
       font-weight: 700;
       color: #F79C00;
  
  }
  .new-description-card__description{
      font-size : 18px;
       color: #011F6b;
      
  }
  
  .new-cursor--pointer{
    cursor: pointer;
  }
  .new-cursor--pointer:hover{
    text-decoration: underline;
  }
  
  .new-description-card__description--not-available{
    color : #D60000;
    font-weight: 600;
  }
  
  .new-description-card__description--not-available:hover{
    text-decoration: none;
  }
  
  
.request__button--wrapper {
    width: 100%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 78px;
    box-shadow: 22px -3px 10px 0px #00000040;
    padding: 26px 73px 26px 73px;
}
.request__button {
  font-weight: 700;
  color: #f67c00;
  border: 1px solid #f67c00;
  padding: 5px 10px;
  border-radius: 5px;
}

.my__asset {
  color: rgb(30, 50, 100);
  border-color: rgb(30, 50, 100);
}
.request__rejected {
    color: #d60000;
    border: none;
    cursor: auto;
}

.request__accepted {
    color: #319f00;
    border: none;
    cursor: auto;
}

.request__sent {
    color: #319f00;
    border-color: #319f00;
}

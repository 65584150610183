
.Verfied-tag {
  justify-content: center;
  align-self: center;
  display: flex;
  height: 45px;
  width: 100%;
  margin-left: 1px;
  background-color: green;

  .Verifi-tex {
    color: white;
    font-family: "Assistant";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    margin-top: 10px;
  }
  .Verfied-tag.not-verified {
    background-color: #D60000;
  }
  
  .Verfied-tag.verified {
    background-color: green
  }
  
} 

.Not-Verfied-tag {
  justify-content: center;
  align-self: center;
  display: flex;
  height: 45px;
  width: 100%;
  margin-left: 1px;
  background-color: #D60000;

  .Verifi-tex {
    color: white;
    font-family: "Assistant";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    margin-top: 10px;
  }
  .Verfied-tag.not-verified {
    background-color: #D60000;
  }
  
  .Verfied-tag.verified {
    background-color: green
  }
  
}


.seprator-liceseDetail{
  height: 100%;
    /* width: 45px; */
    margin-top: 33px;
    /* background-color: red; */
    border-top: 3px solid #bbb;
    width: 50%;
   
}

.tab-text{
  font-family: Assistant;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.24px;
}



.popup-card-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 101;
    
  }
  
  
  .popup-card {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    gap: 10px;
    max-width: 400px;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
   
   
  }
  
  
  .popup-card-close {
   text-align: right;
    cursor: pointer;
  }
  
  .form-group-main{
    margin-top: 30px;
  }
  
  .popup-card h1 {
    font-size: 24px;
    margin-top: 0;
    text-align: center;
    font: normal normal bold 26px/34px Assistant;
    letter-spacing: 0px;
    color: #1E3264;
    opacity: 1;
    margin: 1%;
  }
  
  
  .popup-card .form-group {
    margin-bottom: 20px;
  }
  
  
  .popup-card label {
    display: block;
    
    font: normal normal 600 18px/23px Assistant;
    letter-spacing: -0.54px;
    color: #2C2C2C;
    text-transform: capitalize;
    opacity: 0.8;
  }
  
  
  .popup-card .required {
    color: red;
    margin-left: 5px;
  }
  
  
  
  
  
  .file-input-container {
    position: relative;
    width: 100%;
    height: auto;
    text-align: center;
  }
  
  
  .custom-file-button {
   
    
    padding: 10px 20px;
    cursor: pointer;
    display: inline-block;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 30px #2c2c2c14;  
    border: 1px solid #1E326480;
    border-radius: 4px; 
    text-align: center;
    font: normal normal 600 24px/32px Assistant;
    letter-spacing: -0.96px;
    color: #1E3264;
  }
  
  
  .custom-file-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 44px;
    opacity: 0;
    cursor: pointer;
    border-radius: 5px;
  }
  
  
  .popup-card input[type="text"],
  .popup-card select {
  width: 100%;
  height: 52px;
  background: #2C2C2C05 0% 0% no-repeat padding-box;
  border: 1px solid #2C2C2C14;
  border-radius: 4px;
  opacity: 1;
  }
  
  
  .custom-file-input-container {
    position: relative;
  }
  
  
  .custom-file-input {
    display: inline-block;
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    cursor: pointer;
    text-align: center;
  }
  
  
  .custom-file-input span {
    display: inline-block;
    line-height: 1.5;
  }
  
  
  .custom-file-input input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
  
  
  
  .popup-card button[type="submit"] {
    /* background-color: #007bff; */
    width: 100%;
    height: 45px;
    color: #fff;
    /* border: none; */
    /* border-radius: 5px; */
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  
    background: transparent linear-gradient(274deg, #F79C00 0%, #FED401 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 20px #F79C004D;
    border-radius: 4px;
    opacity: 1;
  }
  
  .popup-card button[type="submit"]:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
.error-message {
      color: red;
      font-size: 12px;
      margin-top: 5px;
    }


    .add-verification-detail-text{
      color: var(--secondary-90, #002961);
      /* Body 1/Semibold */
      font-family: Assistant;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.16px;
      cursor: pointer;
       
    }

    .add-detail{
      margin-top: 12px;
margin-left: 33px;
display: flex;
flex-direction: row;
gap: 23px;
    }

    .additional-detail{
      font-family: Assistant;
      font-size: 21px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.16px; 
      cursor: pointer;
      color: var(--secondary-90, #002961);
    }

    .additional-detail:hover{
      cursor: pointer;
      text-decoration: underline;
      color: var(--secondary-90, #002961);
    }

    .add-verification-detail-text:hover{
      cursor: pointer;
      text-decoration: underline;
      color: var(--secondary-90, #002961);
    }


    .additional-detail-button{
      width:166px;
    height: 4vh;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 30px var(--unnamed-color-2c2c2c14);
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 30px #2C2C2C14;
    border: 1px solid #1E326480;
    border-radius: 4px;
    opacity: 1;
    margin-left: 0px;
    margin-top: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    }


    .tab-switch{
      width: 80%;
    }




    

.Not-Verfied-tag {
  justify-content: center;
  align-self: center;
  display: flex;
  height: 45px;
  width: 100%;
  margin-left: 1px;
  background-color: #D60000;

  .Verifi-tex {
    color: white;
    font-family: "Assistant";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    margin-top: 10px;
  }
  .Verfied-tag.not-verified {
    background-color: #D60000;
  }
  
  .Verfied-tag.verified {
    background-color: green
  }
  
}

.Verfied-tag {
  justify-content: center;
  align-self: center;
  display: flex;
  height: 45px;
  width: 100%;
  margin-left: 1px;
  background-color: green;

  .Verifi-tex {
    color: white;;
    font-family: "Assistant";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    margin-top: 10px;
  }
  .Verfied-tag.not-verified {
    background-color: #D60000;
  }
  
  .Verfied-tag.verified {
    background-color: green
  }
  
}



.Company-Indi-Profile {

  background-color: #f79c001a;
  display: flex;
  flex-direction: row;
  gap: 23px;
  height: auto;
  margin-bottom: 4px;
  margin-left: 3px;
  padding-bottom: 0;
  padding-left: 1%;
  padding-top: 1%;

  .Profileimg {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    justify-content: center;
    align-self: center;
    display: flex;
  }
  .userDetail {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 7px;
  justify-content: flex-start;

    .CompanyName {

      text-align: left;
      font: normal normal bold 22px/34px Assistant;
      color: #2c2c2c;
      opacity: 1;
    }
    .useraccount-detail {

      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 15px;
      justify-content: flex-start;
      margin-top: 5px;

         .phone-img{
            height: 12px;
            width: 12px;
            /* margin-top: -6px;   */

            }
       
        .phone-text{

            text-align: left;
            font: normal normal normal 18px/34px Assistant;
            color: #2c2c2c;
            opacity: 0.7;
        }
        
      
    }
    .busineerOwner {

      text-align: left;
      font: normal normal bold 13px/34px Assistant;
      color: #f79c00;
      text-transform: uppercase;
      opacity: 1;
    }
    .headofDepartement {

      text-align: left;
      font: normal normal bold 13px/34px Assistant;
      color: #f79c00;
      text-transform: uppercase;
      opacity: 1;
    }
    .e9role {

      text-align: left;
      font: normal normal bold 14px/34px Assistant;
      color: #2c2c2c;
      
    }
  }
}

/* Style for each element */
.flex-item {
  margin: -6px; /* Reset margin */
  padding: 0px; /* Add padding for spacing inside each element */   
}
.flex-item  :last-child {
  margin-bottom: 6px; /* Reset margin */
 
}


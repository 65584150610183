
.Box[area="side-menu"] {
    position: sticky;
    top: 0;
    height: 100vh;
    overflow-y: auto;
   
  }
  
 
  .Box[area="side-menu"] {
    z-index: 1;
  }
  
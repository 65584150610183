.black-and-white-terms {
    padding: 5% 4%;
    font-family: "Assistant";
    font-style: normal;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.01em;
    color: #000; 
}

.black-and-white-heading {
    font-size: 64px;
    font-weight: 600;
    text-align: center;
    padding: 23px;
    color: black;
    
}

.black-and-white-content {
    margin-top: 3rem;
    font-family: "Assistant";
    font-style: normal;
    color: #000; 
}

.black-and-white-content h5 {
    font-family: "Assistant";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.01em;
    font: "Roboto";
    color: #000; 
}

.black-and-white-link {
    margin-top: 1.5rem;
}

.black-and-white-link a {
    text-decoration: none;
    color: black
}

.black-and-white-rating-h5 {
    margin-top: 2rem;
}

.black-and-white-rating-h3 {
    font-family: "Assistant";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 42px;
    letter-spacing: 0.01em;
    margin-bottom: 1rem;
    color: #000; 
}



.black-and-white-content {
    padding: 15px 25px;
    font-family: "Assistant";
    font-style: normal;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.01em;
    color: #000; 
}



.material-type-store__title {
  color: #141619;
  font-family: Assistant;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.material-type-store__sub-title {
  color: #767676;
  font-size: 18px;
}

.material-type-store__add-button {
  color: #002961;
  font-size: 24px;
  font-weight: 700;
  border-radius: 4px;
  border: 1px solid #002961;
  cursor: pointer;
  height: max-content;
  padding: 5px 20px;
}


/* Listing  */

.material-type-store__card-layout {
  width: 342px;
  height: 163px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #e3e5e8;
  background:  #fefefe;
  box-shadow: 0px 2px 4px -1px #0000000F;
}

.material-type-store__card-layout__image-container {
  width: 67.463px;
  height: 83px;
}

.material-type-store__card-layout__image{
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.material-type-store__card-layout__material-type-name{
  color:#002961;
  font-size: 18px;
  font-weight: 700;
}

.material-type-store__card-layout__quantity-added{
  color: #959596;
  font-size: 16px;
  font-weight: 700;
  margin-top: 4px;
}

.material-type-store__card-layout__added-on-market-place {
  color:#959596;
  font-size: 12px;
  font-weight: 600;
  margin-top: 4px;
}

.material-type-store__card-layout__added-on-seprator {
  height: 2px;
  width: 138px;
  background: #e3e5e8;
  margin-top: 4px;
}

.material-type-store__card-layout__view-store-button {
  display: flex;
  width: 138px;
  padding: 6px 8px;
  justify-content: center;
  border-radius: 4px;
  margin-top: 14px;
  border: 1px solid #f79c00;
  color: #f79c00;
  font-weight: 600;
}


.material-type-store__card-container--empty-msg {
  color: #76767690;
  font-size: 25px;
  font-weight: 700;
}

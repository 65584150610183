.description-card{
  max-width: 550px;
  min-height: 120px;
  /* box-shadow: 7px 6px 9px 0px #2c2c2c25; */
  box-shadow: 0px 0px 4px 0px #00000040;
  display: flex;
  flex-direction: column;
  padding: 0.75em 0.5em;
  justify-content: space-around;
  /* border: 1px solid #959596; */
  border-radius: 4px;
}

.description-card__heading{
    font-size: 23px;
     font-weight: 700;
     color: #F79C00;

}
.description-card__description{
    font-size : 18px;
     color: #011F6b;
    
}

.cursor--pointer{
  cursor: pointer;
}
.cursor--pointer:hover{
  text-decoration: underline;
}

.description-card__description--not-available{
  color : #D60000;
  font-weight: 600;
}

.description-card__description--not-available:hover{
  text-decoration: none;
}


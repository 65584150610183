.equipment-marketplace-card{
    display: flex;
    flex-direction: column;
    padding: 24px 15px 14px;
    gap: 10px;
}

.equipment-marketplace-card__header-section{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.equipment-marketplace-card__header-section__text{
    color: #4A5A82;
    font-size: 16px;
    font-weight: 600;
}

.equipment-marketplace-card__image-section{
    height: 200px;
    position: relative;
    width: 100%;
}
.equipment-marketplace-card__image-section__image{
    height: 100%;
    width: 100%;
    border-radius: 2px;
}

.equipment-marketplace-card__image-section-tags{
    position: absolute;
    padding: 5px 10px;
    background-color:#80808080;
    color:white;
    border-radius:15px
}

.equipment-marketplace-card__detail-section{
    width:100%;
    display: flex;
    flex-direction: column;
    padding: 0 0.5em;
    cursor : pointer;
    padding-bottom: 0.25em; 
    border-bottom: 1px solid #EAEAEA;  
}

.equipment-marketplace-card__detail-section_rate{
    color: #1E3264;
    font-size: 18px;
    font-weight: 700;   
}
.equipment-marketplace-card__detail-section_location{
    font-size: 16px;
}
.equipment-marketplace-card__detail-section_status-info{
    display: flex;
    color: #2c2c2c95;
    font-size: 14px ;
    font-weight: 600;
    gap: 8px;
}


.equipment-marketplace-card__footer-section{
    display: flex;
    justify-content: space-between;
}

.equipment-marketplace-card__footer-section__profile{
    display:flex;
    gap:5px;
    align-items:center;
}


.equipment-marketplace-card__footer-section__image--blur{
    filter: blur(0.2em);
}

.equipment-marketplace-card__footer-section__contact-btn{
    border: 1px solid #f67c00;
    color: #f67c00;
    padding: 0 8px;
    border-radius: 4px;   
}










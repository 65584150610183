.subscription-table__row {
  border-bottom: 1px solid #2c2c2c1a;
  font-size: 16px;
}

.subscription-table__row--selected {
  border-bottom: 1px solid #F79C00;
  background-color: #2c2c2c05;
}

.subscription-table__data {
  padding-top: 20px;
  padding-bottom: 20px;
}

.subscription-table__data--heading {
  padding-left: 20px;
  width: 20%;
  font-weight: 700;
}

.subscription-table__data--price-unit {
  color: #1e3264;
  font-weight: 600;
  width: 10%;
}

.subscription-table__data--vaildity-days {
  color: #2c2c2c50;
  width: 20%;
}

.subscription-table__data--cta {
  display: flex;
  gap: 1em;
  justify-content: center;
}

.subscription-table__price {
  background-color: #e0e0e0;
  display: flex;
  gap: 0.5em;
  border-radius: 4px;
  width: 125px;
  padding: 0.25em 0.35em;

 
}

.subscription-table__price--selected {
  background-color: #fed401;
}

.badge-layout-completed{
    border-radius: 34px;
    border: 1px solid #319F00;
    background: rgba(49, 159, 0, 0.13);
}

.userResposeTypeValue-completed{
    font-family: Assistant;
    padding: 2px 5px;
    font-weight: 400;
    font-size:13px;
    color: #319F00;
     text-align: center; 
}


.verfication-badge-completed{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}